.wrapper {
  width: 720px;
}

.empty {
  text-align: center;
}

.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: var(--main-black);

  display: flex;
  align-items: center;

  svg,
  path {
    margin-right: 12px;
    fill: var(--main-blue-secondary);
  }
}
.title {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: var(--main-black);

  display: flex;
  align-items: center;

  svg,
  path {
    margin-right: 12px;
    fill: var(--main-blue-secondary);
  }
}
.row {
  display: flex;
  justify-content: space-between;
}
.cart {
  display: flex;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #7F8DEF!important;
  cursor: pointer;

  svg,
  path {
    margin-right: 4px;
  }
}

.row {
  padding: 0!important;
}
