.invalidTitle {
	font-size: 26px;
	line-height: 36px;

	span {
		font-weight: 700;
	}
}

.invalidReasonForm {
	margin-top: 24px;
	padding: 24px 24px 20px;

	background: var(--main-blue-disabled);
	border-radius: 10px;
}

.invalidReason {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.invalidReasonLabel {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: var(--main-black);
	}
}

.selectContiner {
	width: 180px;
}

.reasonCommentContainer {
	margin-top: 16px;

	span {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: var(--main-black);
	}

	textarea {
		margin-top: 8px;
		border: none;
	}
}

.invalidSubmitContainer {
	margin-top: 20px;

	display: flex;
	justify-content: flex-end;
}
