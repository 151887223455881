@import "styles/vars.scss";

.dropzones {
	display: flex;
}

.dropzoneApartment,
.dropzonePlan {
	margin: 6px 3px;
	padding: 8px;

	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 147px;
	width: 254px;

	border: 1px dashed var(--main-blue);
	border-radius: 15px;
	cursor: pointer;
	svg {
		fill: var(--main-blue);
		transform: translateY(-1px);
	}
}

.label {
	@include flex-align-items-center;
	flex-direction: column;

	p {
		margin: 6px 0 0;

		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: var(--main-blue);
	}
}

.description {
	position: absolute;
	left: 50%;
	bottom: 8px;
	transform: translateX(-50%);

	font-family: $ubuntu-font;
	font-size: 12px;
	line-height: 17px;
	color: var(--main-blue-secondary);
	opacity: 0.5;

	white-space: nowrap;
}
