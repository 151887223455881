@import "styles/vars.scss";

.root {
	height: 92px;
	padding: 24px 0 24px;
	border-top: 1px solid #e5e5e5;
	display: flex;
	max-width: 500px;
	align-items: center;
	justify-content: center;
	gap: 12px;
	flex-wrap: wrap;
	margin-top: 24px;
	margin-bottom: 12px;
}

.column {
	display: flex;
	flex-direction: column;
	min-width: 136px;
}

.lightGrey {
	color: #CCC;
	font-family: "Ubuntu", sans-serif;
}
.darkGrey {
	font-size: 14px;
	line-height: 20px;
	color: #2D2C46;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.2px;
	font-family: "Ubuntu", sans-serif;
}

.blue {
	color: #336BEB;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.2px;
	font-family: "Ubuntu", sans-serif;
}
