@import "styles/vars.scss";

.infoRowWrapper {
	position: relative;

	min-height: 46px;

	margin-bottom: 5px;
	padding: 10px 0 5px 11px;

	display: flex;
	flex-direction: row;
	align-items: center;

	border-bottom: 1px solid var(--main-blue-inactive);

	&.singleBorder {
		border-bottom: none;
		align-items: flex-start;

		.label {
			padding-bottom: 5px;
			border-bottom: 1px solid var(--main-blue-inactive);
			margin-right: 14px;
			padding-right: 10px;
			margin-bottom: -10px;
		}
	}

	&.noBorder {
		border-bottom: none;
		align-items: flex-start;
	}

	&.column {
		border-bottom: none;
		flex-direction: column;
		align-items: flex-start;

		.label {
			margin-bottom: 10px;
		}
	}

	&.regular {
		.childrenContainer {
			font-weight: 400;
		}
	}

	&.noLabel {
		border-bottom: none;
		padding-bottom: 0;

		.childrenContainer {
			font-weight: 400;
			padding-bottom: 5px;
			border-bottom: 1px solid var(--main-blue-inactive);
		}
	}
}

.childrenContainer {
	width: 100%;
	color: var(--main-black);
	font-weight: 500;
	font-size: 14px;
	word-break: break-all;

	input {
		color: var(--main-black);
	}
}

.label {
	display: flex;
	justify-content: flex-start;
	align-items: center!important;
	gap: 8px!important;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-grey-sub);
}

.label.ubuntu {
	font-family: $ubuntu-font;
}

.label.cera {
	font-family: $default-font;
}
.rowLoading {
	margin-bottom: 5px;
	height: 38px;
	width: 100%;

	border-radius: 10px;
	background: var(--main-blue-inactive);

	@include skeleton();
}
