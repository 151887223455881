.root {
  padding: 25px 40px 40px 40px;
  font-family: Cera Pro;
  min-width: 100%;
  // margin-left: 40px;
}

.root h1 {
  color: var(--, #2D2C46);
  font-family: Cera Pro;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 36px */
  letter-spacing: 0.2px;
  margin-bottom: 30px;
}

.workspace {
  border-radius: 10px;
  padding: 30px 140px 20px;
  margin-bottom: 30px;
  background-size: cover;
  background-image: url("../../../../../assets/images/Banner.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
}

.workspaceCard {
  margin-left: 277px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.root .workspace button {
  background-color: white;
  color: black;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  margin-bottom: 14px;
}

.invite_block {
  padding: 20px;
  border-radius: 10px;
  background: #F2F5FB;
  margin-bottom: 30px;
}

.invite_form {
  border-radius: 8px;
  background: #FFF;
  padding: 20px;
  margin-bottom: 30px;
}

.create_company {
  border-radius: 8px;
  background: #FFF;
  padding: 20px;
  margin-bottom: 30px;
}

h2 {
  color: #2D2C46;
  font-family: Cera Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}

h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
  margin: 0px 0px 12px 0px;
  padding: 0;
}

.description {
  width: 392px;
  color: #FFF;
  text-align: center;
  font-family: Cera Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: 0.2px;
  margin-bottom: 26px;
}

.link a {
  color: #FFF;
  font-family: Cera Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.create_company {
  border-radius: 10px;
  background: #F2F5FB;
}