.root {

}

.title {
  color: #2D2C46;
  font-family: Ubuntu, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  letter-spacing: 0.2px;
}
.rowEnd {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;
}

.fieldsContainer {
  margin-bottom: 52px;
}

.container {
  margin-top: 20px;
  padding: 10px 100px;
}

.footer {
  margin-top: 36px;
  width: 660px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 12px 40px;
  margin-left: -32px;
  margin-bottom: -24px;
  box-shadow: 0px 0px 35px 0px #124BF659;

}
