.container {
	width: 100%;

	display: flex;
	flex-direction: row;
}

.contentContainer {
	position: relative;
	width: 100%;
}

.content {
	padding: 13px 42px;
	width: 100%;

	> :first-child {
		margin-top: 0px;
	}

	> :nth-child(2) {
		margin-top: 30px;
	}

	> :nth-child(3) {
		margin-top: 40px;
	}

	> div {
		margin-top: 60px;
	}
}
