.root {
  position: relative;
  padding: 16px 28px 0 28px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 66px);
}

.rowStart {
  display: flex;
  flex-flow: row;
  justify-content: start;
  align-items: center;
  gap: 4px;
}

.contactData {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
  margin-top: 24px;
}

.contactPhone {
  color: #2D2C46;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.isNew {
  min-height: calc(100vh - 66px);
}

.content {
  flex-grow: 1;
}

.footer {
  margin: 0px -28px 0px -28px;
}