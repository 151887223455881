@import "styles/vars.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2 {
  vertical-align: center;
  color: var(--BW-0, #1E1E1F);
  font-family: "Cera Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; 
  letter-spacing: 0.2px;
  margin: 0;
  padding: 0;
}
.header {
  margin-bottom: 20px;
}
.root {
  margin-bottom: 40px;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 20px;
}