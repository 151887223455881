@import "styles/vars.scss";

.root {
	font-family: $ubuntu-font;
	font-size: 0.75rem;
	line-height: 16px;
	font-weight: 500;
}

.label {
	color: var(--main-black);
}

.count {
	color: var(--main-grey-disabled);
}
