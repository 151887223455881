@import "styles/vars.scss";

.root {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	margin-bottom: 12px;
	padding: 12px;

	border-radius: 10px;
	background: var(--main-blue-disabled);

	cursor: pointer;
}

.wrapper {
	width: 100%;
}

.title {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);
}

.person {
	display: flex;
	align-items: center;

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);

	span {
		opacity: 0.7;
	}

	svg {
		margin-right: 8px;

		width: 16px;
		height: 16px;
	}

	path {
		fill: var(--main-grey-light);
	}
}

.highlightRoot {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.redHighlight {
	font-weight: 400;
	color: var(--accent-red);
}

.shareButtonWrapper {
	display: flex;
	align-items: center;
}
