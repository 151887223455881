@import "styles/vars.scss";

.root {
	max-width: 380px;

	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		margin-top: 25px;
		margin-bottom: 6px;
		font-size: 24px;
		text-align: center;
	}

	p {
		margin-bottom: 67px;
		color: rgba(0, 0, 0, 0.5);
		font-size: 16px;
		text-align: center;
		font-family: $ubuntu-font;
	}
}

.noParagraph {
	height: 67px;
}
