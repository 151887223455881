.container {
	position: absolute;
	top: 0;
	left: -45px;
	z-index: 99;

	width: 45px;
}

.buttonsContainer {
	display: flex;
	flex-direction: column;
}

.buttonItem {
	padding: 0 14px;
	margin: -14px 0;

	background: linear-gradient(270deg, #fcfcfc -13.33%, #ffffff 100%);
	box-shadow: 0 10px 30px rgba(0, 64, 229, 0.25);

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;

	font-family: Cera Pro;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: var(--main-blue-secondary);

	svg {
		fill: var(--main-blue-secondary);
	}
}

.buttonItemActive {
	z-index: 11;
	background: #fff;
	color: var(--main-black);

	svg {
		fill: var(--main-black);
	}
}

.buttonItemDisabled {
	background: #f1f1f1;
	color: #ddd;
	cursor: not-allowed;
}

.buttonText {
	transform: rotate(-90deg);
	white-space: nowrap;

	svg {
		transform: rotate(90deg) translate(8px, -3px);
	}
}
