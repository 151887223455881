@import "styles/vars.scss";

.gallery {
  margin-bottom: 24px;

  position: relative;
}

.galleryClosed__scroll {
  margin-bottom: 12px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: var(--content-width);
  cursor: grab;
  //background-color: var(--main-black);

  &::-webkit-scrollbar {
    height: 7px;
    border-top: 3px solid white;
    border-bottom: 3px solid white;
  }

  &::-webkit-scrollbar-track {
    background:  linear-gradient(
                    to top,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0) 30%,
                    #c9cbd5 50%,
                    rgba(0, 0, 0, 0) 70%,
                    rgba(0, 0, 0, 0) 100%
    );;
    border-radius: 0;
    margin-left: 14px;
    margin-right: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--main-grey-inactive);
    border-radius: 100px;
  }
}

.grabbing {
  cursor: grabbing;

  & > img {
    pointer-events: none;
  }
}

.galleryClosed__img {
  min-width: 255px;
  height: 200px;
  margin-bottom: 14px;
  object-fit: cover;
  border: 4px solid var(--main-black);

  &:first-child {
    padding-left: 0;
  }
}

.galleryOpened {
  background-color: var(--main-black);
}

.toggle {
  padding: 12px 20px;

  position: absolute;
  right: 0;
  bottom: 21px;
  @include button-no-style();

  display: flex;
  align-items: center;

  cursor: pointer;
  background-color: #fff !important;
  border-top-left-radius: 10px;
  font-size: 14px;
  color: var(--main-black);
  font-family: $default-font;

  svg {
    fill: var(--main-grey-inactive);
    margin-left: 11px;
  }
}

.toggleNoPhoto {
  bottom: 0!important;
}

.toggleReverse {
  bottom: 0;

  svg {
    transform: rotate(180deg);
  }
}

.noPhotosYet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 200px;

  color: #fff;

  p {
    margin: 24px 0 0 0;
  }

  path {
    fill: #fff;
  }
}

.newImages {
  padding-bottom: 80px;
}

.thumbsContainer {
  padding: 12px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.thumb {
  margin-bottom: 8px;
  padding: 4px;

  width: 100px;
  height: 100px;

  position: relative;
  display: inline-flex;

  border-radius: 2px;
  border: 1px solid #eaeaea;
}

.thumbInner {
  display: flex;
  overflow: hidden;

  min-width: 0;
}

.img {
  display: block;

  width: auto;
  height: 100%;
}

.buttonContainer {
  margin-bottom: 20px;

  display: flex;
  justify-content: center;
}
