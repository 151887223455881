@import "styles/vars.scss";

.root {
	width: 100%;
	& tr td {
		padding-top: 24px;
		padding-bottom: 12px;
		border-bottom: 1px solid var(--main-blue-inactive);
		span {
			font-family: $ubuntu-font !important;
			font-size: 14px !important;
			font-weight: 400 !important;
		}
		img {
			width: 92px;
			height: 32px;
		}
		&:first-child {
			padding-left: 10px;
		}
		&:last-child {
			padding-right: 10px;
		}
	}
}
