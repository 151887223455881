.cluster-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	color: #fff;
	font-weight: bold;
	font-size: 16px;
	line-height: 16px;
	background: #336BEB;
	border-radius: 50%;
	transform: translate(-50%, -50%);
}

.object-icon {
	position: relative;
	padding-bottom: 9px;
}

.object-icon__text {
	position: relative;
	display: inline-flex;
	align-items: center;
	height: 25px;
	padding: 0 1px;
	color: #fff;
	font-size: 14px;
	white-space: nowrap;
	background-color: #336BEB;
	background-repeat: no-repeat;
	background-size: 100% auto;
	border-radius: 4px;
	transform: translate(-100%, -100%);
	min-width: 10px;

	&::after,
	&::before {
		position: absolute;
		top: 0;
		z-index: -1;
		content: "";
	}

	&::after {
		left: -10px;
		width: 13px;
		height: 25px;
		background-image: url(../../../../assets/icons/map-icon-left-blue.svg);
	}

	&::before {
		right: -8px;
		width: 16px;
		height: 34px;
		background-image: url(../../../../assets/icons/map-icon-right-blue.svg);
	}
}

.object-icon__text-orange {
	background-color: var(--accent-orange);

	&::after {
		background-image: url(../../../../assets/icons/map-icon-left-orange.svg);
	}
	&::before {
		background-image: url(../../../../assets/icons/map-icon-right-orange.svg);
	}
}

.mark-icon {
	padding: 8px;

	width: 43px;
	height: 61px;
	background-repeat: no-repeat;
	transform: translate(-50%, -100%);

	&::after {
		position: absolute;
		content: "";
		top: 0;
		left: 0;

		width: 43px;
		height: 61px;

		background-image: url(../../../../assets/icons/map-mark.svg);
	}
}
