@import "styles/vars.scss";

.root {
  border-radius: 20px;
  transition: 0.2s;

  &:hover {
    box-shadow: $default-box-shadow;
    border-color: #fff;
  }
}

.contacts,
.card {
  margin-top: 12px;
}

.card {
  border: 1px solid rgba(var(--main-blue-secondary-rgb), 0.4);
  border-radius: 10px;
}

.cardRoot {
  border-radius: 20px;
  background: #F2F5FB;
  width: 720px;
  display: flex;
  flex-flow: column;
  padding: 8px;
  max-height: 800px;
}

.tableRoot {
  width: 680px;
}

.titleCollapse {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
}

.collapse {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.labelText {
  margin-right: 8px !important;
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  margin-top: 32px;
  gap: 86px;
}

.labelObject {
  color: #9595A3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}

.content {
  padding: 12px;
  max-height: 644px;
  overflow-y: auto;
}

