@import "styles/vars.scss";

.root {
	padding: 0 8px;
}

.header {
	color: var(--main-black);

	h2,
	p {
		margin: 0;
	}

	h2 {
		font-size: 26px;
	}

	p {
		margin-top: 16px;

		font-weight: 400;
		font-family: $ubuntu-font;
	}
}

.infoRow {
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 10px 8px;

	align-items: flex-end;
}

.status {
	margin-top: 28px;
}

.statusIcon {
	width: 28px;
}

.deadlineText {
	margin-left: 4px;
	padding: 5px 12px;

	font-size: 14px;
	border-radius: 100px;
	color: white;
}
