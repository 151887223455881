@import "styles/vars.scss";

.root {
	position: fixed;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 400px;
	height: 64px;
	background: white;
	padding-right: 28px;
}

.openTrigger {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	color: var(--main-blue);

	svg {
		margin-left: 4px;
		transform: translateY(1px);
		fill: var(--main-blue);
	}
}

.popup {
	width: auto !important;
}

.itemContainer {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
}

.item {
	display: flex;
	align-items: center;

	white-space: nowrap;

	cursor: pointer;

	p {
		flex: 10 1;
		margin: 0;

		font-family: $ubuntu-font;
		font-size: 14px;
		line-height: 20px;
		font-weight: normal;
		color: var(--main-blue);
	}

	svg {
		margin-right: 12px;
		flex: 1 1;

		fill: var(--main-blue);

		opacity: 0.7;
		transition: 0.2s;
	}

	path {
		fill: var(--main-blue);
	}

	&:hover {
		svg {
			opacity: 1;
		}
	}
}
