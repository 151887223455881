.root {
	display: flex;
	align-items: center;
	column-gap: 6px;

	span {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.arrow {
	&,
	path {
		fill: currentColor;
	}
}
