@import "styles/vars.scss";

.root {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	max-width: 440px;
	height: 80px;
	margin-bottom: 12px;
	padding: 0;
	border-radius: 10px;
	cursor: pointer;
	box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);
}

.root:hover {
	filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
	z-index: 1;
	background: #fff;
	cursor: pointer;
}

.archived {
	color: rgba(45, 44, 70, 0.50);
}

.linear {
    background: linear-gradient(#7F8DEF, #BC9CFF);
}

.withoutStage {
	border-right: 1px solid #BFBFC0;
}

.icon {
	path {
		fill: #336BEB;
	}
}

.title {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-black);
	letter-spacing: 0.2px;
	max-width: 270px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.address {
	font-family: $ubuntu-font;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: var(--main-grey);

	span {
		color: #878787;
	}
}

.addressLabel {
	max-width: 390px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.price {
	text-align: right;
	white-space: nowrap;
}

.wrapper {
	width: 100%;
}

.flexColumn {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding: 8px 10px 3px 14px;
}

.flexRow {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.flexBetween {
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.flexRowAlignStart {
	align-items: flex-start;
}

.titleAndPrice {
	display: flex;
	justify-content: space-between;
	width: 100%;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-black);
	letter-spacing: 0.2px;
}

.stage {
	position: relative;
	top: 0;
	bottom: 0;
	right: 0;
	width: 26px;
	height: 100%;
	border-radius: 0 var(--ck-border-radius) var(--ck-border-radius) 0;

	svg {
		position: absolute;
		top: 28px;
	}
}

.stageCircle {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	border-radius: 50%;
	width: 18px;
	height: 18px;
}

.stagePopup {
	top: unset !important;
	bottom: 50% !important;
	left: unset !important;
	right: 50% !important;
	height: 40px;
	padding-left: 12px !important;
	width: max-content;
	padding-right: 2rem !important;
	line-height: 1;
	transform: none !important;
	font-weight: 400;
	font-size: 13px !important;
	font-family: "Ubuntu", sans-serif;
}

.responsible {
	padding: 0 !important;
}

.contact {
	max-width: 342px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
