.root {
  position: relative;
  //img {
  //  width: 100%;
  //  height: 100%;
  //  object-fit: cover;
  //  border-radius: 10px;
  //}

  .loader,
    //svg {
    //  position: absolute;
    //  top: 50%;
    //  left: 50%;
    //  transform: translate(-50%, -50%);
    //
    //  opacity: 0;
    //  transition: 0.3s;
    //
    //  fill: #fff;
    //
    //  width: 20px;
    //  height: 20px;
    //}

  &:hover:not(.cantUpload),
  &.isLoading {
    cursor: pointer;

    .loader,
    svg {
      opacity: 1;
    }

    .shadow {
      opacity: 0.6;
    }
  }
}

.filesRoot {
  .root {
    position: relative;
    display: flex !important;

    .loader,
    &:hover:not(.cantUpload),
    &.isLoading {
      cursor: pointer;

      .loader,
      svg {
        opacity: 1;
      }

      .shadow {
        opacity: 0.6;
      }
    }
  }
}

.radioButtons {
  display: flex;
  flex-direction: column;
  gap: 24px
}

.block {
  display: flex;
}

.block p {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
  min-width: 150px
}

.block label {
  flex-direction: row-reverse;
}

.block label svg {
  margin-right: 12px;
}

.RadioButton {
  gap: 12px;
}

.tooltipDescription {
  margin: 0 0 0 -4px !important;
}

.tooltipCard {
  max-height: 260px;
  max-width: 360px !important;
  min-height: 40px;
  padding: 8px 8px 8px 36px !important;
  left: -70px !important;
  top: 20px !important;

  div {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 13px;
    font-family: "Ubuntu", sans-serif;
  }

  svg {
    left: 8px !important;
    top: 8px !important;
  }
}

.infoRow {
  .label {
    align-items: center !important;
    gap: 8px !important;
  }
}


.row {
  display: flex;
  align-items: center;
}

.rowPlug {
  display: flex;
  gap: 29px;
  flex-flow: row;
}

.column {
  display: flex;
  flex-flow: column;
  gap: 3px;
}

.add {
  color: #7F8DEF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.comment {
  color: #2D2C46;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  opacity: 0.5;
}

.docTitle {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
  margin-bottom: 12px;
}

.stripe {
  height: 1px;
  opacity: 0.3;
  background: #BFC4DF;
  width: 100%;
  margin-bottom: 32px;
}

.financialConditions {
  padding-bottom: 30px;
}

.disabled input {
  color: #AFAFAF !important;
}

.rootInput {
  display: flex;
}

.rootInput input {
  text-align: left;
}

.buttonBlock {
  position: sticky;
  bottom: 0;
  background: #F2F5FB;
  height: 64px;
  align-items: center;
  width: 720px;
  padding-right: 40px;
  box-shadow: 0 10px 40px 0 rgba(0, 64, 229, 0.30);
  display: flex;
  justify-content: end;
  gap: 12px;
  margin-left: -20px;
  margin-bottom: -24px;
  border-radius: 0 0 20px 20px;

  svg {
    opacity: 1;
  }
}

.financialConditions .readOnly input {
  color: #AFAFAF;
}

.error {
  padding-left: 44%;
  color: #FF575F;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
}

.fileContainer {
  width: 680px;
  min-height: 56px;
  max-height: 246px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 8px 24px;
  margin-bottom: 20px;
}

.fileRow {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  border-top: 0 !important;
}

.fileRowTitle {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
  margin-left: 8px;
}

.fileItem {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #F2F2F2;
  padding-left: 12px;
}

.filesList {
  :first-child {
    border-top: 1px solid #F2F2F2;
  }

  :last-child {
    border-bottom: 0 !important;
  }
}

.counter {
  position: absolute;
  top: 20px;
  left: -6px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  width: 20px;
  height: 20px;
  color: #FFFFFF;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.20000000298023224px;
}

.counterWrapper {
  position: relative;
}

.callContainer {

}

.callRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete {
  color: #FF575F;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
  cursor: pointer;
  gap: 4px;

  svg {
    fill: #FF575F;
  }
}

.add {
  color: #336BEB;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
  cursor: pointer;
  gap: 4px;

  path {
    fill: #336BEB;
  }
}

.plug {
  width: 680px;
  height: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  color: #BFBFC0;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  margin-bottom: 20px;
}

.selectedCall {
  padding: 0 20px 0;
  flex: 3 1;
  display: flex;
  width: 670px;
  justify-content: space-between;
  margin-bottom: 12px;
  flex-direction: row;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow 0.25s ease;
}