@import "styles/vars.scss";

.root {
	margin-top: 24px;

	@include flex-align-items-center;
	justify-content: space-between;

	padding: 32px 60px;

	border-radius: 16px;
	background: linear-gradient(96.21deg, #49d7bd 1.97%, #6f93ff 96.3%, #6f93ff 96.3%);
	font-weight: 700;
}

.title {
	max-width: 324px;

	font-size: 26px;
	line-height: 31px;
	color: white;
}

.prices {
	@include flex-justify-content-center;
	flex-direction: column;

	color: white;

	& > div {
		&:first-child {
			font-size: 30px;
		}

		&:last-child {
			@include flex-align-items-center;

			transform: translateX(-25px);

			font-family: $ubuntu-font;
			font-weight: normal;
			font-size: 16px;
			opacity: 0.7;
		}
	}
}

.arrow {
	@include flex-align-items-center;

	svg {
		width: 24px;
		height: 24px;
		path {
			opacity: 0.7;
			fill: white;
		}
	}
}

.root.up {
	background: linear-gradient(96.21deg, #ff575f 1.97%, #ffe254 96.3%),
		linear-gradient(96.21deg, #49d7bd 1.97%, #6f93ff 96.3%, #6f93ff 96.3%);

	.arrow {
		&.up {
			transform: rotate(180deg);
		}
	}
}
