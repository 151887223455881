@import "styles/vars.scss";

.source {
	display: flex;
	align-items: center;

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
	color: #686779;
	white-space: nowrap;

	a {
		padding-left: 4px;
	}

	&.coldCallSource {
		white-space: unset;
		display: block;

		a {
			padding-left: 0;
		}
	}
}

.withPlatform {
	svg {
		margin-left: 8px;
	}
}

.siteIcon {
	margin-right: 8px;
}

.block {
	padding: 10px 16px;

	background: var(--main-blue-disabled);
	border-radius: 10px;
}

.link {
	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-blue);

	cursor: pointer;
}
