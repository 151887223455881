
.root input::placeholder {
	padding-left: 50%;
}
.root input:focus::placeholder {
  opacity: 0;
}

.fields {
	margin-bottom: 25px;
}

.button {
	min-width: 222px;
}

.buttonNewCompany {
	margin-left: auto;
}

.buttonRealtorInCompany {
	margin-left: auto;
}

.buttonSingleRealtor {
	margin-top: 9px;
}

.tooltip {
	margin: 0 !important;
}

.addressSelect {
	padding: 10px 8px;
}
.addressSelect label {
	bottom: -20px!important;
	left: -12px;
}
.realtorInfo {
	margin-bottom: 14px;
}

.searchSelectWrapper {
	width: 100.5%;
	margin-top: 4px;
}
.addressSelect label{
	padding-left: 40px!important;
}
.companyName label {
	padding-left: 40px!important;
}

h2 {
	margin: 0;
}