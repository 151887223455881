@import "styles/vars.scss";

.title {
	font-size: 24px;
	line-height: 36px;
	font-weight: bold;
}

.time {
	font-size: 14px;
	line-height: 16px;
	color: var(--main-black);
	opacity: 0.5;
}
