@import "styles/vars.scss";

.root {
	padding: 25px 40px;
	min-height: 100vh;
	max-height: 100vh;

	overflow: hidden;
}

.skeleton {
	@include skeleton();
	margin-bottom: 28px;

	width: 100%;
	height: 40px;
}

.skeletonsContainer {
	display: flex;
	flex-direction: row;
}

.leftSkeleton {
	flex: 7 1;

	.skeleton {
		margin-right: 20px;
		width: calc(100% - 20px);
	}
}

.rightSkeleton {
	flex: 3 1;
}

.baseFields {
	height: 60px;
}

.address {
	height: 400px;
}

.ad {
	height: 56px;
}

.rooms {
	height: 164px;
}

.square {
	height: 312px;
}

.contact {
	height: 100px;
}

.responsible {
	height: 124px;
}
