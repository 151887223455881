@import "styles/vars.scss";

.root {
	position: relative;
	width: 100%;
	height: 80px;
	justify-content: space-between;

	padding: 7px 36px 7px 12px;

	display: flex;
	flex-direction: row;

	background: #fff;
	border-radius: 10px;

	transition: filter 0.2s;

	cursor: pointer;

	&:hover {
		background: #fff;
		box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.25);
		transition: 0.2s;
	}
}

.hide {
	display: none;
}

.img {
	margin: -7px 0px -7px -13px;

	position: relative;

	width: 64px;
	height: 69px;

	&.largerImg {
		width: 86px;
		height: 80px;
	}

	img {
		width: 100%;
		height: 100%;
		border-bottom-left-radius: 10px;
		border-top-left-radius: 10px;
		object-fit: cover;
	}
}

.noImg {
	display: flex;
	align-items: center;
	justify-content: start;

	border-right: 1px solid rgba(0, 0, 0, 0.1);

	svg {
		width: 86px;
		height: 80px;
	}
}

.content {
	width: 81%;
	margin-left: -30px;
	margin-right: -10px;
}



.smallerContent {
	max-width: calc(100% - 86px);
}

.title {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-black);

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.lineThrough {
	text-decoration: line-through solid var(--main-black-dark);
}

.address {
	font-family: $ubuntu-font;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: var(--main-grey);
	max-width: 265px;

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.price {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-black);

	white-space: nowrap;
}

.flexColumn {
	display: flex;
	flex-direction: column;
}

.flexRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 24px;
}

.flexRowAlignStart {
	align-items: flex-start;
}

.tooltipCard {
	max-height: 260px;
	max-width: 360px !important;
	min-height: 40px;
	padding: 8px 8px 8px 36px !important;
	background: #3F3F40!important;

	div {
		display: -webkit-box;
		-webkit-line-clamp: 15;
		-webkit-box-orient: vertical;
		overflow: hidden;
		word-wrap: break-word;
		font-size: 13px;
		font-family: "Ubuntu", sans-serif;
	}

	svg {
		left: 6px !important;
		top: 4px !important;
	}
}

.tooltipMini {
    left: -220px!important;
}

.tooltipDescription {
	margin: 0 0 0 4px !important;
}

.stageInSample {
	margin-right: -36px;
}

.icon {
	position: absolute!important;
	margin: 4px!important;
	display: block!important;
	top: 0
}

.clientComment {
	position: absolute!important;
	margin: 4px!important;
	top: 60%!important;
	display: block!important;
}

.tooltipComment {
	left: 0!important;
}
