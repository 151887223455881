@import "styles/vars.scss";

.root {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	height: 44px;
	width: 100%;
	background: var(--main-blue-disabled);
}
