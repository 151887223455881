@import "styles/keyframes";

.root {
	height: 100%;
	overflow: hidden;
}
.loadingWrapper {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.spinner {
	animation: rotation 2s linear infinite;
	width: 40px !important;
	height: 40px !important;
	margin-left: 15px;
	margin-bottom: -3px;
}

.content {
	display: flex;
	justify-content: space-between;
	padding: 35px 42px;
	margin-bottom: 42px;
	overflow-y: scroll;
	height: calc(100% - 50px);
}

.actions {
	position: sticky;
	top: 0;
	min-width: 113px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.cancelBtn {
		margin-top: 12px;
	}
}
