.root {
	padding: 60px 80px;

	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 100000;

	display: flex;
	align-items: center;
	justify-content: center;

	background: #3f3f56;
}

.photoContainer {
	position: relative;

	height: calc(100vh - 225px);
	width: 100%;
	max-width: 788px;
}

.photo {
	position: relative;

	width: 100%;
	min-width: 100%;
	height: calc(100% - 40px);
	min-height: calc(100% - 40px);

	border: 1px dashed var(--main-grey);

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.loader {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;

	background-color: rgba(77, 77, 104, 0.3) !important;
}

.toolbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
}

.numberOf {
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	color: #fff;
	opacity: 0.5;
}

.toolbarButtons {
	display: flex;
	justify-content: flex-end;
	column-gap: 12px;
}

.leftIcon,
.rightIcon {
	position: absolute;
	top: 50%;

	width: 60px;
	height: 90px;

	cursor: pointer;

	fill: var(--main-grey-disabled);
	opacity: 0.5;
	transition: 0.2s;

	&:hover {
		opacity: 1;
	}
}

.leftIcon {
	left: -60px;
	transform: translateY(-50%) rotate(90deg);
}

.rightIcon {
	right: -60px;
	transform: translateY(-50%) rotate(-90deg);
}

.button {
	position: absolute;
	right: -42px;
	top: 0;
	z-index: 1000000;
}
