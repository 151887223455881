@import "styles/vars.scss";

.root {
	padding: 20px 16px;
	height: 100%;
	overflow: auto;

	h3 {
		margin: 0;

		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		color: var(--main-black);

		span {
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			opacity: 0.5;
		}
	}
}

.noFreeTraffic {
	font-family: $ubuntu-font;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-grey-disabled);
}

.loader {
	position: relative;
	display: inline;
	left: 18px;

	svg {
		height: 25px !important;
	}
}
