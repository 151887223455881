@import "styles/vars.scss";

.tooltipContainer {
  background: #F2F5FB;
  ////border: 1px solid #CCCCCC;
  //border-radius: 10px;
  //outline: none;
  //border: none;
  //padding: 5px;
  padding: 12px 16px;

  p {
    margin: 0;
    font-family: $ubuntu-font;
  }
}

.tooltipHeader {
  p {
    opacity: 0.5;
    font-size: 12px;
  }
  margin-bottom: 8px;
}

.tooltipMarker {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: red;
  margin-right: 6px;
}

.tooltipBody {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > p {
    font-size: 14px;
  }

  > p {
    opacity: 0.5;
  }

  > p:last-child {
    margin-left: 28px;
    color: #2D2C46;
    opacity: 1;
  }
}

.chartWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.loadingContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms ease-in-out;
}

.showLoading {
  opacity: 1;
}