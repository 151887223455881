.root {

}

.footer {
  padding: 12px 40px;
  display: flex;
  justify-content: end;
  gap: 32px;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  background: white;
  height: 64px;
  width: 800px;
  box-shadow: 0 10px 30px rgba(0, 64, 229, 0.25);
  border-radius: 0 0 20px 20px;
  z-index: 2;
}

.row {
  display: flex;
  gap: 4px;
  align-items: center;
}

.content {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-flow: column;
  gap: 12px;
  height: 387px;
  overflow-y: auto;
  width: 730px;
  padding-right: 10px;
  margin-bottom: 64px;
}

.selected {
  border: 2px solid #336BEB;
}

.item {
  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);
  border-radius: 12px;
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 20px;
}

.select {
  margin-bottom: 16px;
}

.openCart {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  cursor: pointer;
  color: #336BEB;
}