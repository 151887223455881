.root {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	column-gap: 20px;
	height: 100%;
	width: 100%;
	cursor: pointer;
	padding: 0 12px;
	font-size: 14px;
	border-radius: 15px 15px 0 0;
}

.active {
	background: white;
	cursor: not-allowed;
}

.label {
	min-width: 184px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.responsible {
	display: flex;
	align-items: center;
	column-gap: 4px;
	overflow: hidden;
	text-overflow: ellipsis;

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	img,
	svg {
		width: 20px;
		height: 20px;
	}

	img {
		border-radius: 50%;
	}

	svg,
	path {
		fill: var(--main-blue);
	}
}
