.infoRow {
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 10px 8px;

	align-items: flex-end;
}

.content {
	display: flex;
	column-gap: 20px;
}
