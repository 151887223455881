.root {
	position: relative;
	top: -20px;
}

.dialog {
	padding: 15px 24px;

	color: var(--main-blue-disabled);
	width: fit-content;
}

.bordersWithLeftSide {
	margin: 0 auto 0 0;

	border-top-right-radius: 20px;
	border-top-left-radius: 20px;
	border-bottom-right-radius: 20px;

	background: var(--main-blue);
}

.bordersWithRightSide {
	margin: 0 0 0 auto;

	border-top-right-radius: 20px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;

	background: var(--main-grey-inactive);
}

.dialogSideContainer {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
.shapeContainer {
	position: relative;
	overflow: hidden;
	width: 50px;
	height: 41px;
	transform: translateZ(0);
}

.shape {
	height: 50px;
	width: 50px;
	overflow: hidden;
	position: relative;
}

.shape::before {
	top: 0;
	background: white;
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	border-radius: 50%;
}
.shapeContainerLeft {
	clip-path: polygon(50% -1%, 15% 20%, 15% 20%, 0 50%, 0 0);
}
.shapeContainerRight {
	clip-path: polygon(83% 18%, 101% 50%, 101% 0, 50% 0);
}

.shapeLeft {
	background: var(--main-blue);
}

.shapeRight {
	background: var(--main-grey-inactive);
}

.fromWho {
	position: absolute;

	font-size: 12px;
	line-height: 14px;
	color: var(--main-grey-sub);
}

.fromWhoLeftSide {
	bottom: 15px;
	left: 20px;
}

.fromWhoRightSide {
	bottom: 15px;
	right: 20px;
}

.storiesDialog {
	max-width: 500px;
}
