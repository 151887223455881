.iconBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 24px;
  max-width: 402px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 0.2px;

  .svgIcon {
    display: flex;
    margin-right: 5px;
  }

  .other {
    background-color: #EDEDED;
    width: 24px;
    height: 24px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: 8px;

    span {
      color: #9595A3;
      position: absolute;
    }
  }
}

.tooltipDescription {
  margin: 0 0 0 4px !important;
}

.tooltipCard {
  max-height: 260px;
  max-width: 360px !important;
  min-height: 40px;
  padding: 8px 8px 8px 36px !important;
  background: #3F3F40!important;

  div {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 13px;
    font-family: "Ubuntu", sans-serif;
  }

  svg {
    left: 8px !important;
    top: 6px !important;
  }
}

.tooltipActiveInfoLess {
  padding: 12px 8px 12px 36px !important;
  max-width: 360px;

  svg {
    left: 9px !important;
    top: 8px !important;
  }
}

.tooltipNone {
  display: none;

  &:hover {
    display: flex;
  }
}

.flexRow {
  display: flex;
  align-items: center;
}

.iconRow {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flexBetween {
  justify-content: space-between;
}

.owner {
  padding-right: 18px;
}