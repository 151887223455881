.root {
	display: grid;
	grid-template:
		"leftFilters mainFilters" auto
		"leftFilters tasksList" 1fr
		/ auto 1fr;

	flex: 1;
	padding: 20px 24px 70px 24px;

	height: calc(100vh - 50px);
	overflow-y: overlay;
}

.mainFilters {
	grid-area: mainFilters;
}

.leftFilters {
	grid-area: leftFilters;
}

.tasksList {
	grid-area: tasksList;
}
