@import "styles/vars.scss";

.root {
	display: flex;
	flex-direction: column;
}

.container {
	margin-top: 37px;

	&:first-child {
		margin-top: 0;
	}
}

.errorTitle {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.errorMessage {
	margin-top: 4px;

	color: var(--accent-red);
	font-size: 14px;
	font-family: $ubuntu-font;
	word-break: break-word;
	line-height: 20px;

	a {
		color: var(--main-blue);
		text-decoration: underline;
	}
}

.buttons {
	margin-top: 20px;

	@include flex-align-items-center;

	& > button {
		margin-right: 20px;

		font-family: $ubuntu-font !important;
		font-size: 14px !important;
		font-weight: 400 !important;

		&:last-child {
			padding: 0;
		}
	}
}

.editError {
	display: flex;
	align-items: center;

	svg {
		margin-right: 4px;

		width: 20px;
		height: 20px;
	}
}
