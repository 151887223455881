@import "styles/vars.scss";

.root {
	width: 40px;
	height: 40px;

	.noImg,
	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	img {
		object-fit: cover;
	}

	.noImg {
		display: flex;
		align-items: center;
		justify-content: center;

		font-family: $ubuntu-font;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		color: #ffffff;

		&.bg__primary {
			background: var(--main-blue);
		}
		&.bg__secondary {
			background: var(--main-grey-disabled);
		}
	}
}

.loading {
	.noImg {
		background: var(--main-blue-inactive);
	}
}

.noInitialsIcon path {
	fill: var(--main-blue-disabled);
}
