@import "styles/vars.scss";

.root {
	margin-bottom: 40px;

	h3 {
		display: flex;
		align-items: center;

		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		color: var(--main-black);
	}
}

.tooltipCard {
	max-width: 362px;
}

.adButtonContainer {
	display: flex;
}

.adButton {
	margin: 0 6px;
	padding: 4px 8px;

	cursor: pointer;

	font-family: $ubuntu-font;
	font-weight: bold;
	font-size: 12px;
	color: #aab3f0;
	line-height: 14px;

	background: var(--main-blue-disabled)!important;
	border-radius: 100px;

	&__avito {
		color: #fff;
		background: #97cf26!important;
	}
	&__yandex {
		color: #fff;
		background: var(--accent-red)!important;
	}
	&__cian {
		color: #fff;
		background: #0468ff!important;
	}

	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}
}
