@import "styles/vars.scss";

.title {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.numAndName {
	font-size: 14px;
	line-height: 20px;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	span {
		font-weight: 400;
		color: var(--main-blue);
		&:last-child {
			margin-left: 8px;
		}
	}
}

.openInNew {
	position: absolute;
	top: 39px;
}

.linear {
	background: linear-gradient(#7F8DEF, #BC9CFF);
}

.rootGray {
	background: #F2F5FB;
}

.tooltipCard {
	max-height: 260px;
	max-width: 360px !important;
	min-height: 40px!important;
	padding: 8px 8px 8px 36px !important;
	translate: -29% 21%;
	background: #3F3F40!important;

	div {
		display: -webkit-box;
		-webkit-line-clamp: 15;
		-webkit-box-orient: vertical;
		overflow: hidden;
		word-wrap: break-word;
		font-size: 13px;
		font-family: "Ubuntu", sans-serif;
	}

	svg {
		left: 8px !important;
		top: 6px !important;
	}
}

.tooltipActiveInfoLess {
	padding: 12px 8px 12px 36px !important;
	width: fit-content;
	white-space: nowrap;

	svg {
		left: 9px !important;
		top: 8px !important;
	}
}

.noContact {
	font-size: 14px;
	font-weight: 400;
	opacity: 0.5;
	line-height: 20px;
}

.popup {
	@include flex-align-items-center;
}

.date {
	font-size: 12px;
	opacity: 0.5;
	color: var(--main-black);
	span:last-child {
		margin-left: 4px;
	}
}

.icons {
	@include flex-align-items-center;
	justify-content: flex-end;

	& > div {
		margin-left: 4px;
	}
}

.icon {
	@include flex-align-items-center;
}

.signed {
	svg path {
		fill: var(--accent-green);
	}
}

.popoverRoot {
	display: flex !important;
}

.popoverOverElement {
	white-space: nowrap;
}

.yellowClock {
	svg {
		fill: var(--accent-yellow);
	}
}

.fire {
	svg {
		fill: var(--accent-red);
	}
}

.popupWrapper {
	width: 24px !important;
}

.stage {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 26px;
	height: 100%;
	border-radius: 0 var(--ck-border-radius) var(--ck-border-radius) 0;
}

.stageCircle {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	border-radius: 50%;
	width: 18px;
	height: 18px;
}

.stagePopup {
	top: unset !important;
	bottom: 50% !important;
	left: unset !important;
	right: 50% !important;

	height: 40px;
	padding-left: 12px !important;

	width: max-content;

	padding-right: 2rem !important;

	line-height: 1;

	transform: none !important;
	font-weight: 400;
	font-size: 13px !important;
	font-family: "Ubuntu", sans-serif;
}

.img {
	margin: -8px 20px -7px -20px;
	position: relative;
	width: 108px;
	height: 100px;

	img {
		width: 100%;
		height: 100%;
		border-bottom-left-radius: 10px;
		border-top-left-radius: 10px;
		object-fit: cover;
	}
}

.noImg {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	svg {
		width: 48px;
		height: 55px;
	}
}

.price {
	text-align: right;
	white-space: nowrap;
}

.priceDots {
	display: flex;
	align-items: center;
}

.responsiblePrice {
	display: grid;
	height: 100px;
}

.root {
	position: relative;
	width: 100%;
	padding: 7px 13px;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	transition: filter 0.2s;
}

.onActivePopup {
	z-index: 100;
}

.selectedCard {
	padding: 5px 11px;
	border: 2px solid var(--main-blue);

	.img img {
		border: 2px solid var(--main-blue);
		border-right: none;
	}
}

.card-application {
	display: flex;
	position: relative;
	width: 100%;
	flex-direction: row;
}

.root:hover {
	filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
	z-index: 1;
	background: #fff;
	cursor: pointer;
}

.popup {
	position: absolute;
	top: 9px;
	right: 29px;
}

.withPopup {
	padding-right: 55px;
}

.background_blue {
	background: var(--main-blue-disabled);
}
.background_white {
	background: #fff;
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1 1;
	height: 100%;
	&.contentDraggable {
		padding-right: 24px;
	}
	margin: -2px;
}

.flexColumn {
	display: flex;
	flex-direction: column;
}

.flexRow {
	display: flex;
	flex-direction: row;
}

.applicationFlexRow {
	margin-right: -23px;
	height: 100%;
	width: 100%;
}

.flexBetween {
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.users {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.flexRowAlignStart {
	align-items: flex-start;
}

.titleAndPrice {
	padding-right: 3px;
	justify-content: space-between;
	width: 100%;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-black);
	letter-spacing: 0.2px;
}


.tooltipInfo {
	margin: 0 4px 0 4px !important;
}
.tooltipWarn {
	margin: 0 8px 0 4px !important;
}

.tooltipDescription {
	margin: 0 0 0 4px !important;
}

.infoIconRow {
	display: flex;
	justify-content: center;
	align-items: center;
}
.infoIcon {
	margin: 0 6px 0 0;
}

.address {
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: var(--main-grey);

	span {
		color: #878787;
	}
}

.addressLabel {
	display: -webkit-box;
	max-width: 100%;
	-webkit-line-clamp: 10;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.lineThrough {
	text-decoration: line-through solid var(--main-black-dark);
}

.withPadding {
	padding-right: 10px;
}

.contact {
	color: #336BEB;
	font-size: 13px;
	font-weight: 400;
	line-height: 15.6px;
	letter-spacing: 0.20000000298023224px;
	text-align: left;
	display: flex;
	align-items: center;
	gap: 4px;
}


.price {
	text-align: right;
	white-space: nowrap;
	padding-right: 5px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.2px;
}


.responsible {
	display: flex;
	flex-direction: row;
	align-items: center;
	max-width: 300px;
	width: available;
	padding-right: 10px;
	justify-content: end;

	.responsibleLogo {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		position: relative;
	}

	span {
		max-width: 157px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 3px;
		font-family: $ubuntu-font;
		font-size: 13px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0.20000000298023224px;
		text-align: left;
		color: #7E7E7E;
	}
}
