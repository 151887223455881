
.root {
  padding: 0 0 32px;
}
h2 {
  padding: 0;
  margin: 0;
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}
.root h2 {
  margin-bottom: 8px;
}