@import "styles/vars.scss";

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 12px;
	margin: 0 auto 78px;
	max-width: 350px;
}
.react-pin-input__input {
  text-align: center !important;
  box-sizing: border-box; 
}
.codePrepend {
	font-weight: bold;
	font-size: 20px;
	color: var(--main-black);
	margin-left: 35px;

	.codePrependEmpty {
		font-weight: normal;
		color: var(--main-grey-light);
	}
}

.info {
	font-size: 14px;
	font-family: $ubuntu-font;
	color: #9595a3;
	text-align: center;
}

.buttonsContainer {
	display: flex;
	flex-flow: column;
	align-items: center;
	row-gap: 100px;
}

.resend {
	align-self: baseline;
	font-size: 14px;
	font-family: $ubuntu-font;
	color: #9595a3;
}

.timeLeft {
	color: var(--main-black);
}

.button {
	margin-top: 20px;
}

.buttons {
	display: flex;
	flex-flow: column;
	align-items: center;
}
.errorMessage {
	color: #FF575F;
	text-align: right;
	font-family: Ubuntu;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; 
	letter-spacing: 0.2px;
}