@import "styles/vars.scss";

.root {
	padding: 16px 8px 8px 16px;
	overflow: hidden;
	max-width: 220px;
	text-overflow: ellipsis;
	border-top: 1px solid white;
}

.base {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.avatarContainer {
	position: relative;

	cursor: pointer;

	&:hover .notifications {
		opacity: 1;
	}
}

.notifications {
	opacity: 0;

	transition: 0.2s;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 40px;
	height: 40px;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	border-radius: 20px;
	background: var(--main-blue);
}

.info {
	margin-left: 16px;
}

.company,
.name {
	font-weight: bold;
	font-size: 14px;
	line-height: 17px;
	color: rgba(255, 255, 255, 0.5);
	font-family: $default-font;

	max-width: 140px;
	overflow: hidden;
	text-overflow: ellipsis;

	white-space: nowrap;

	transition: 0.2s;
}

.baseHover:hover .name {
	color: var(--main-blue);
}

.infoCurrent .name {
	color: #fff;
}

.link {
	padding-left: 56px;

	cursor: pointer;
	white-space: nowrap;
	color: var(--main-blue);
	text-decoration: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
}
