@import "styles/vars.scss";

.form {
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: 10px;

	&:hover {
		.delete {
			opacity: 1;
		}

		.departmentName {
			color: var(--main-blue);
		}
	}
}

.department {
	display: flex;
	align-items: center;
	color: var(--main-grey-disabled);
	font-size: 16px;
	transition: 0.3s;
	font-family: $ubuntu-font;
}

.delete {
	opacity: 0;
	margin-left: 10px;
	cursor: pointer;
	transition: 0.2s;

	path {
		&:hover {
			fill: var(--accent-red);
		}
	}
}

.departmentName {
	word-break: break-all;
	display: flex;
	align-items: center;
	flex-flow: row;
}

.employers {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: var(--main-blue-secondary);
	margin-top: 1px;

	svg {
		opacity: 1;
		margin-left: 14px;
		margin-right: 4px;
	}
}

.alertWrapper {
	margin: 16px 0;
	max-width: 335px;

	box-shadow: 0 10px 30px rgba(0, 64, 229, 0.2);
}
