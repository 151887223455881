@import "styles/vars.scss";

.rewriteNotSelected {
	color: var(--main-black);
}

.first {
	font-weight: bold;
}

.second {
	font-weight: 500;
	font-size: 14px;
}

.third {
	font-size: 12px;
	font-weight: 400;
	margin-top: 4px !important;
	font-family: $ubuntu-font;
}

.secondOffset {
	margin-left: 24px;
}

.thirdOffset {
	margin-left: 30px;
}

.customOptionWrapper {
	margin-top: 12px;

	&:first-child {
		margin-top: 0;
	}
}

.customOptionSelected {
	color: var(--main-blue);
}

.arrowUp {
	@include flex-align-items-center;
	cursor: pointer;
	transform: rotate(180deg);

	svg {
		width: 20px;
		height: 20px;
		fill: var(--main-black);
	}
}

.arrowDown {
	transform: none;
}
