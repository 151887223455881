.rowEnd {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;
  position: absolute;
  width: 100%;
  height: 64px;
  left: 0;
  bottom: 0;
  padding-right: 40px;
  background: white;
  border-radius: 0px 0px 20px 20px;
  box-shadow: (0px 10px 30px rgba(0, 64, 229, 0.25));
}

.buttonCreate {
  margin-left: 12px;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.column {
  display: flex;
  flex-flow: column;
  gap: 4px;
  margin-bottom: 32px;
  margin-top: 4px;
}

.title {
  color: #2D2C46;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.2px;
}

.text {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.2px;
}

.infoRow {
  padding: 0 10px 4px;

  min-height: 0;
}

.calendarChild {
  margin-bottom: 29px !important;

  & > div {
    &:last-child {
      margin-left: -46px;
    }
  }
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendarLabel {
  flex-direction: row-reverse;

  svg {
    margin-right: 0 !important;
    margin-left: 20px;

    path {
      fill: var(--main-blue-secondary);
    }
  }
}

.calendar {
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.popupTrigger {
  padding: 12px 24px !important;
}

.timeRoot {
  margin-left: 21px;
}

.timeWrapper {
  border: none !important;
  padding: 0;
}

.timeInputs {
  background: white !important;
}

.timeError {
  margin-top: 7px !important;
  margin-left: -8px !important;
}

.applications {
  display: flex;
  flex-flow: column;
  gap: 12px;
  height: 350px;
  margin-top: 16px;
  overflow: auto;
  width: 750px;
  overflow-x: hidden;
  margin-left: -15px;
  padding-left: 15px;
  padding-top: 10px;
}

.objects {
  padding: 10px 0 10px 10px;
  margin-left: -10px;
  display: flex;
  flex-flow: column;
  gap: 12px;
  height: 350px;
  margin-top: 16px;
  overflow: auto;
  width: 740px;
  overflow-x: hidden;
}

.object {
  width: 720px;
  position: relative;
  cursor: pointer;
  background: #F2F5FB;
  border-radius: 10px;
}

.application {
  width: 720px;
  position: relative;
  cursor: pointer;
  background: #F2F5FB;
  height: 100px !important;
  padding: 5px 11px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px #2224281A;

  div img {

  }
}

.warningContainer {
  margin-top: 20px;
  width: 720px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--BW-4, #F2F2F2);
  padding: 12px 20px 20px 20px;

  .title {

    margin-bottom: 4px;
    color: var(--BW-0, #1E1E1F);
    font-family: "Cera Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.2px;
  }

  .warning {
    color: var(---, #FF9052);
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
  }
}

.appFlexRow {
  margin-right: 10px;
  height: 88px;
}

.addLabel {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
  margin-top: 28px;
}

.selected {
  border-left: 1px solid;
  width: 23px !important;
  margin: -5px -11px !important;
  max-height: 96px !important;
  border-radius: 0 10px 10px 0 !important;
}

.selectedCard {
  padding: 5px 11px;
  border: 2px solid var(--main-blue);
  border-radius: 10px 10px 10px 10px !important;

  .img img {
    border: 2px solid var(--main-blue);
    border-right: none;
  }
}

.selectedObjectCard {
  border: 2px solid var(--main-blue);
  border-radius: 10px !important;

  .img img {
    border: 2px solid var(--main-blue);
    border-right: none;
  }
}

.selectedObjectParserCard {
  border: 2px solid var(--main-blue);
  border-radius: 10px !important;

  .img img {
    border: 2px solid var(--main-blue);
    border-right: none;
  }
}

.calendarError {
  padding-top: 10px;
}