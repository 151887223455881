.root,
.table {
	width: 100%;
}

.table {
	border-collapse: collapse;
}

.thead {
	border-bottom: 2px solid var(--main-blue);
	padding-bottom: 8px;
	text-align: left;
	th {
		padding-bottom: 8px;
		font-weight: 400;
		opacity: 0.3;
		font-size: 12px;
		line-height: 16px;
	}
}

.tbody {
	tr {
		&:first-child {
			td {
				padding-top: 16px;
			}
		}
		td {
			text-align: left;
			padding-top: 12px;
		}
	}
}
