@import "styles/vars.scss";

.options {
  &:nth-child(n + 2) {
    padding: 32px 58px 0 24px;
  }
  display: flex;
  align-items: center;
}

.fixedWidthTitle {
  width: 130px;
  color: var(--main-black);
  font-weight: 500;
  line-height: 24px;
}

.paymentOptions {
  margin-top: 28px;
  display: flex;
  align-items: center;

  > div:last-child {
    margin-left: 36px;
  }
}

.radio {
  margin-left: 36px;
}

.startAd {
  &.cian {
    margin-top: 26px;
  }

  margin-top: 45px;
  display: flex;
  align-items: center;
}

.radioButton {
  &.cian {
    label {
      color: var(--main-black);
      max-height: 28px;
    }
  }
  label {
    border-radius: 100px;
    //color: var(--main-grey-disabled) !important;
    font-family: $ubuntu-font;
    font-weight: 400;
    .tariffOption {
      span {
        font-weight: 400;
      }
    }
  }
  label[class^="RadioButton_checked"] {
    font-weight: 500;
    color: white !important;
    .tariffOption {
      span {
        font-weight: 500;
        color: white !important;
      }
    }
  }
}

.tariffOption {
  @include flex-align-items-center;

  & > div {
    margin: 0;
  }

  svg {
    margin: 0 12px 0 0 !important;

    path {
      fill: var(--main-grey-light);
    }
  }

  div[class*="Tooltip_root"] {
    margin: 0 12px 0 0;
  }
}

.paymentOptionsDisabled {
  background-color: transparent !important;

  label {
    &:not(.checked) {
      color: white;
    }
  }
}

.paymentOptionsDisabled {
  background-color: transparent;

  label {
    &:not(.checked) {
      color: white;
    }
  }
}

.startAdDuration {
  background-color: #E3E9F7 !important;
}

.info {
  padding: 20px 58px 0 24px;
  p {
    color: #49D7BD;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
  }

  span {
    text-transform: capitalize;
  }
}