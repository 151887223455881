@import "styles/vars.scss";

.create {
	margin-top: 10px;
}

.buttonCreate {
	margin-top: 26px;
	margin-bottom: 40px;
}
