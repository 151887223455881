@import "styles/vars.scss";

.root {
	@include flex-align-items-center;
	justify-content: space-between;
	width: 200px;
}

.dotWrapper {
	width: 100%;
	position: relative;
	@include flex-align-items-center;
	&:last-child {
		width: auto;
	}
}

.label {
	position: absolute;
	top: -5px;
	transform: translate(-50%, -100%);
	font-family: $ubuntu-font;
	font-weight: 400;
	font-size: 14px;
	color: var(--main-blue-secondary);
	white-space: nowrap;
}

.line {
	width: 100%;
	background: var(--main-blue);
	height: 2px;
	@include flex-align-items-center;
	.dot {
		border-radius: 50%;
		width: 8px;
		height: 8px;
		background-color: var(--main-blue);
		border: 0;
		&.disabled {
			background-color: var(--main-blue-inactive);
			transform: none;
			position: relative;
			&:before {
				content: "";
				display: block;
				top: 50%;
				left: 50%;
				width: 4px;
				border-radius: 50%;
				height: 4px;
				position: absolute;
				transform: translate(-50%, -50%);
				background-color: #fff;
			}
		}
	}
}
