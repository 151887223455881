@import "styles/vars.scss";

.wrapper {
  width: 788px;
}

.empty {
  text-align: center;
}

.col:first-child {
  max-width: 680px;
}

.row {
  width: 680px;
  max-height: 64px;
  display: flex;
  justify-content: center;
  padding: 14px 8px;
}

.title {
  font-family: $ubuntu-font;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.7;
  border-bottom: 1px solid var(--main-blue-secondary);
}

.iconCell {
  max-width: 50px;
}

.selected {
  border: 1px solid #336BEB;
}
