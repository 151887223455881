@import "styles/vars.scss";

p {
	font-family: Ubuntu;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
letter-spacing: 0.2px;
}
.root {
	max-width: 350px;
}
.root h1 {
	margin-bottom: 8px;
}
.fieldsContainer {
	margin-bottom: 60px;
}

.blockedLink {
	position: relative;
	padding: 24px 44px 24px 30px;
	background: #f5f5f5;
	border-radius: 10px;
	font-size: 14px;
	font-family: "Ubuntu", sans-serif;
	width: 446px;
}

.blockedLinkIcon {
	position: absolute;
	top: 4px;
	right: 4px;
}

.submitContainer {
	display: flex;
	flex-direction: column;
	align-items: center;

	button {
		margin-bottom: 16px;
	}
}

.loginLinkContainer {
	font-family: "Ubuntu", sans-serif;
}

.link {
	color: var(--main-blue);
	font-size: 16px;
	text-decoration: none;
}

.errorMessage {
	width:100%;
	color: #FF575F;
	text-align: right;
	font-family: Ubuntu;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 14.4px */
	letter-spacing: 0.2px;
	padding-top: 4px;
}