.container {
  > p:first-child {
    font-weight: 500;
    margin-bottom: 8px;
  }
}

.messageTitle > p {
  color: var(--accent-red);
  * {
    color: var(--accent-red);
  }

  /* a {
    color: var(--main-blue);
  } */

  font-size: 14px;
}

.messagesContainer {
  > div:not(:last-child) {
    margin-bottom: 16px;
  }
}

.recommendation {
  background: #F2F5FB;
  border-radius: 12px;
  padding: 12px 20px;
  margin-top: 16px;

  > p:first-child {
    font-weight: 500;
    margin-bottom: 4px;
  }

  > p:last-child {
    color: var(--main-grey-inactive);
    font-size: 14px;
  }
}