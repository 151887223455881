.root {
  width: 720px;

}

.title {
  margin-top: 36px;
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;

}

.subtitle {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 8px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.submitBtns {
  margin: 16px 30px 24px;

  display: flex;
  justify-content: flex-end;
  column-gap: 24px;
}

.inforowContent {
  width: 200px;
}

.selected {
  border: none !important;
  width: 200px;
}

.footer {
  margin-top: 60px;
  height: 92px;
  display: flex;
  align-items: center;
  border-top: 1px solid #F2F2F2;
  width: 800px;
  margin-left: -40px;
  padding-left: 40px;

  div {
    display: flex;
    flex-flow: column;
    gap: 4px;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #BFBFC0;
  }
}

.calendar {
  position: relative;
  margin-left: 25px;
  z-index: 10;
  transform: translateX(-24px);
}

.specialisations {
  width: 720px;
  height: 90px;
  border-radius: 4px;

  border: 1px solid #BFBFC0;
}

.saveText {
  svg {
    position: relative;
    right: -692px;
    top: -25px;
    cursor: pointer;
    transition: 0.1s;
    fill: #336BEB;
  }
}

.saveTextSaving {
  svg {
    transform: rotate(-90deg);
    fill: #BFC4DF !important;
    transition: 0.1s;
  }
}