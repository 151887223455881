@import "styles/vars.scss";

.formWrapper {
	position: relative;
}

.emailField {
	max-width: 50%;
}

.input {
	padding: 0 0 5px 0;
	color: var(--, #2D2C46);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.2px;
}

.row {
	height: 45px;
	padding: 10px 0 14px 11px!important;
}

.calendar {
	position: relative;
	z-index: 10;
	transform: translateX(-24px);
}

.placeholder {
	&::placeholder {
		font-style: normal;
		font-weight: 500;
		color: var(--main-blue-secondary);
	}
}

.FIO {
	margin-left: 5px;
	width: 100%;
}

.selectInput {
	font-size: 14px!important;
	line-height: 20px!important;
	font-family: "Ubuntu", serif;
	font-weight: 500!important;
	background: transparent;
}