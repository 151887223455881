@import "styles/vars.scss";

$line-height: 16px;

.root {
	display: flex;
	align-items: center;

	column-gap: 6px;

	font-weight: 500;
	font-size: 12px;
	line-height: $line-height;
	color: var(--main-black);
}
