@import "./fonts.css";
@import "./vars";
@import "./css-variables";
@import "~animate.css";

* {
	box-sizing: border-box;
	letter-spacing: 0.2px;
}

html {
	display: flex;
	width: 100%;
	min-height: 100%;
	scroll-behavior: smooth;
}

body {
	flex-grow: 1;
	width: 100%;
	margin: 0;
	font-family: $default-font;
}

body {
	&.drawer-no-scroll,
	&.modal-no-scroll {
		overflow: hidden;
	}
}

#root {
	height: 100%;
}

button {
	&:focus {
		outline: none;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	box-shadow: 0 0 0 30px white inset !important;
}

input:focus {
	outline: none;
}

input[type="password"] {
	letter-spacing: 5px;
}

::-webkit-scrollbar {
	width: 4px;
}

::-webkit-scrollbar-track {
	background-color: var(--main-blue-disabled);
	border-radius: 100px;
}

::-webkit-scrollbar-thumb {
	background: var(--main-blue-secondary);
	border-radius: 100px;
	opacity: 0.5;
}

a {
	text-decoration: none;
}

// style for vertical line tooltip on charts
.apexcharts-xcrosshairs {
	display: none;
}
