.groupName {
  font-size: 20px;
  margin: 0;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 720px;
  margin-top: 16px;
}

.cardStatusIcon {
  max-width: 20px;
  max-height: 20px;
}

.card {
  cursor: pointer;

  :hover {
    transition: filter 0.2s;
    filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
  }
}