.root {
	width: 100%;
}

.columnsWrapper {
	margin-bottom: 40px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	column-gap: 12px;
}

.titleItem {
	flex: 1 1;
	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--main-blue-disabled);

	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: var(--main-blue);

	cursor: pointer;

	&.titleItemHeight__60 {
		height: 60px;
	}

	&.titleItemHeight__78 {
		height: 78px;
	}

	&.titleItemActive {
		background: var(--main-blue);
		color: #fff;
	}
}
