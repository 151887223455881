@import "styles/vars.scss";

.root {
	padding: 0 16px;
}

.rows {
	margin-top: 24px;
}

.inputs {
	margin-top: 20px;
}

.button {
	margin-top: 32px;
}

.inputWrapper {
	margin-bottom: 8px;

	& div[class^="TransparentInput"] {
		padding: 4px 16px;
		border: 1px solid var(--main-grey-light);
		border-radius: 10px;
	}
}

.taskName {
	input {
		font-weight: 300;
	}
}

.textarea {
	padding: 8px 16px;
    background: var(--BW-4, #F2F2F2);
	font-weight: 300;
	font-family: $ubuntu-font;
	border-color: var(--main-grey-light);
}

.executor {
	width: 60%;
}
