@import "styles/vars.scss";

.root {
	background: var(--main-blue-disabled);
	width: 720px;
	&:hover {
		background: #fff;
		box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.25);
		transition: 0.2s;
	}
	border-radius: 10px;

	&.rootWithObject {
		align-items: flex-start;
	}
}

.main {
	@include flex-align-items-center;
}

.noImg {
	width: 99px;
	height: 92px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--main-blue-inactive);
}

.contentNoObject {
	margin: 0 auto 4px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.contentNoObjectLabel {
		padding: 2px 0;

		font-size: 16px;
		line-height: 20px;
		color: var(--main-black);
	}
}
