.root {
	display: flex;
	flex-direction: column;

	padding-bottom: 64px;

	> :first-child {
		margin-top: 0px;
	}
	> div {
		margin-top: 25px;
	}
}
