.root {
	height: calc(100% - 50px);
	overflow-y: auto;
	overflow-x: hidden;
}
.cardLead {
	display: flex;
	justify-content: center
}
.card_lead {
	margin-bottom: 30px;
}
.root {
	padding: 20px 20px;
}