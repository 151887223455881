.inputsContainer {
  margin-bottom: 24px;
  width: 350px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.enterButtonContainer {
  margin-bottom: 16px;
  width: 122px;
  margin-top: 76px;
}

.buttonsGroup {
  display: flex;
  flex-direction: column;
  align-items: center;

  .link {
    color: var(--main-blue);
    text-decoration: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
  }
}

.forget {
  display: flex;
  justify-content: end;
  width: 100%;

  .link {
    color: var(--main-blue);
    text-decoration: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
  }
}
