@import "styles/vars.scss";

.root {
	display: flex;
	justify-content: space-between;
	padding-right: 27px;
}

.title {
	margin: 0;

	font-size: 26px;
}

.date {
	color: var(--main-grey-disabled);
	font-size: 14px;
}
