.root {
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	background: none;
	border-radius: 6px;
	transition: 0.2s;

	cursor: pointer;

	&:hover {
		background: #fff;
		filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
	}
}

.Icon {
	width: 100%;
	height: 100%;

	path {
		fill: var(--main-blue);
	}
}
