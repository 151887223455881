@import "styles/vars.scss";

.root {
	background: #f5f5f5;
	width: 100%;
	padding: 24px 50px 24px 30px;
	font-family: $ubuntu-font;
	border-radius: 10px;
	font-size: 14px;
	position: relative;
}

.icon {
	position: absolute;
	top: 6px;
	right: 6px;
	fill: var(--main-grey-light);
}
