.root {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 99;
	background: #fff;

	overflow-y: auto;
	overflow-x: hidden;
}

.close {
	position: absolute;
	top: 8px;
	z-index: 100;

	width: 32px;
	height: 32px;

	border-radius: 20px;
	background: var(--main-black);
	cursor: pointer;

	transition: 0.5s;

	svg {
		position: absolute;
		top: 50%;
		left: 53%;
		transform: translate(-50%, -50%) rotate(-90deg);

		fill: #fff;
	}

	&:hover {
		box-shadow: 0 10px 30px rgba(0, 64, 229, 0.25);
	}
}
