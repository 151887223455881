@import "styles/vars.scss";

.openTrigger {
	display: flex;
	align-items: center;

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	color: var(--main-blue);
}

.itemsContainer {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
}

.item {
	height: 20px;

	display: flex;
	align-items: center;

	white-space: nowrap;

	cursor: pointer;

	p {
		flex: 10 1;
		margin: 0;

		font-family: $ubuntu-font;
		font-size: 14px;
		line-height: 20px;
		font-weight: normal;
		color: var(--main-blue);
	}

	svg {
		margin-right: 12px;
		flex: 1 1;

		fill: var(--main-blue);

		opacity: 0.7;
		transition: 0.2s;
	}

	.empty {
		flex: 1 1;
		margin-right: 12px;
	}

	path {
		fill: var(--main-blue);
	}

	&:hover {
		svg {
			opacity: 1;
		}
	}
}
