.root {
	display: flex;
	flex-direction: column;

	> :first-child {
		margin-top: 0px;
	}

	> div {
		margin-top: 25px;
	}
}
