@import "styles/vars.scss";

.root {
	padding: 39px 4px 39px 10px;

	position: absolute;
	top: 0;
	left: $sidebar-opened-width;

	height: 100%;

	display: flex;
	flex-direction: column;

	background: rgba(30, 30, 31, 0.90);
	backdrop-filter: blur(6px);
	transition: 0.1s;
	pointer-events: none;
	opacity: 0;
}

.opened {
	pointer-events: auto;
	opacity: 1;
}
