
.wrap {
  position: absolute;
  // overflow: hidden;
  height: 100px;
  width: 26px;
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
}

.atList {
  right: -2px !important;
}

.mini {
  top: 0;
  right: -2px;
  height: 80px;
}

.root {
  position: absolute;
  height: 100px;
  width: 26px;
  background-color: transparent;
}

.canOpenInNew {
  position: absolute;
  top: 28px;
  right: 0;
  z-index: 1;
  cursor: pointer;

  svg {
    fill: white;
  }
}

.cart {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 27px;
  height: 100px;
  right: 0;
  z-index: 1;
  cursor: pointer;
  border-left: 1px solid #CCCCCC;

  svg {
    position: absolute;
    fill: white;
  }
}

.canOpenInNewWithoutStage {
  path {
    fill: #7788FF !important;
  }
}

.applications {
  background-color: #30CFB1;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.strap:first-child {
  border-top-right-radius: 10px;
}

.strap:nth-last-child(2) {
  border-bottom-right-radius: 10px;
}

.deals {
  background-color: #336BEB;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.preApplications {
  background-color: #D5D3CE;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.stagePopup {
  width: 360px;
  top: unset !important;
  bottom: 50% !important;
  left: unset !important;
  right: 4% !important;
  padding: 12px !important;
  max-width: 360px !important;
  transform: none !important;
  width: max-content;

  font-family: "Ubuntu", sans-serif;
  font-size: 13px !important;
  line-height: 15.6px;
  letter-spacing: 0.2px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// .popover {
//   visibility: visible;
// }

.hasPreApplication {
  width: 26px;
  background-color: #D5D3CE;
  margin: -7px -13px;
  border-radius: 0 10px 10px 0;
  // max-height: 100px;
}

.atCompilation {
  border-left: 1px solid;
  width: 25px;
  margin: -7px -13px;
  border-radius: 0 10px 10px 0;
  // max-height: 100px;
}

.stage {
  max-width: 336px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stagePopup {
  top: unset !important;
  bottom: 50% !important;
  left: unset !important;
  right: 4% !important;
  padding: 12px !important;
  max-width: 360px !important;
  transform: none !important;
  width: max-content;
  text-overflow: ellipsis;
  font-family: "Ubuntu", sans-serif;
  font-size: 13px !important;
  line-height: 15.6px;
  letter-spacing: 0.2px;
  white-space: nowrap;

}

.stageCircle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

.shedule {
  // display: flex;
  width: max-content;
  flex-direction: column;
  text-overflow: ellipsis;
  overflow: hidden;
  gap: 12px;
}

.shedule .stage:not(:last-child) {
  margin-bottom: 12px;
}