.root {
	display: flex;
}

.sideMenu {
	height: auto;
	max-height: 612px;

	border-radius: 20px 0 0 20px;
	background: var(--main-black);
	padding: 16px;
}

.sideMenuItem {
	cursor: pointer;
	border-radius: 10px;
	width: 100%;
	background: transparent;
	&.active {
		background: white;
		opacity: 1;
		border-radius: 10px 0 0 10px;
		width: calc(100% + 16px);

		&:hover {
			background: white;
		}
	}

	&:hover {
		background: rgba(255, 255, 255, 0.2);
	}
}

.children {
	width: 100%;
	background: white;
	border-radius: 0px 20px 20px 0px;
}
