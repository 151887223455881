.filterWrapper {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-right: 20px;
	padding-left: 17px;
	border-right: 1px solid var(--main-blue-inactive);
}

.periodWrapper {
	padding-top: 8px;

	width: 234px;
}

.periodLabelWrapper {
	color: var(--main-black) !important;
	white-space: nowrap;
}

.periodPopupWrapper {
	top: 6px;
	position: relative;

	height: 100%;
	width: 100%;
}

.resetting {
	top: -7px;
	position: relative;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);

	width: 100%;
	display: flex;
	justify-content: center;
	cursor: not-allowed;

	span {
		position: relative;
		top: 2px;
	}

	svg {
		margin-right: 12px;
	}

	cursor: not-allowed;
}
