.refuseTitle {
  font-size: 26px;
  line-height: 36px;

  span {
    font-weight: 700;
  }
}

.reasonCommentContainer {
  margin-top: 24px;
  padding: 24px 24px 20px;
  background: var(--main-blue-disabled);
  border-radius: 10px;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-black);
  }

  textarea {
    margin-top: 8px;
    border: none;
  }
}

.refuseSubmitContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}


.buttonCreate {
  margin-left: 12px;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.column {
  display: flex;
  flex-flow: column;
  gap: 4px;
  margin-left: 32px;
  margin-bottom: 50px;
  margin-top: 26px;
}

.title {
  color: #2D2C46;
  font-family: Ubuntu, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  letter-spacing: 0.2px;
}

.text {
  color: #1E1E1F;

  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;

}

.rowEnd {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;
}