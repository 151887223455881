@import "styles/vars.scss";

.root {
	width: 220px;
}
.arrow {
	position: absolute;
	top: 0;
	left: 30px;
}

.arrowCall {
	left: 18px;
}

.arrowOut {
	transform: rotate(180deg);
}

.sourceWrapper {
	width: 30px;
	position: relative;
}

.date {
	font-size: 12px;
	line-height: 16px;
	color: var(--main-grey-disabled);
}

.source {
	@include flex-justify-content-center;
}

.firstLine {
	display: flex;
}

.status {
	padding-left: 12px;
	font-size: 14px;

	text-overflow: ellipsis;
	white-space: nowrap;
	overflow-x: hidden;
}
