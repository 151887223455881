.root {

}

.title {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;

}

.add {
  font-family: Ubuntu;
  display: flex;
  align-items: center;
  width: 67%;
  justify-content: end;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #336BEB;
  cursor: pointer;
  position: absolute;
  height: 24px;
  right: 0;

  svg {
    path {
      fill: #336BEB;
    }
  }
}

.card {
  width: 720px;
}

.count {
  margin-left: 12px;
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #BFBFC0;
}

.labelText {
  margin-right: 8px !important;
}

.departments {
  display: flex;
  flex-flow: column;
}

.department {
  margin-top: 36px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.col {
  display: flex;
  flex-flow: column;
  gap: 12px;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.user {
  margin-bottom: 12px;
}

.footer {
  margin-top: 60px;
  height: 92px;
  display: flex;
  align-items: center;
  border-top: 1px solid #F2F2F2;
  width: 800px;
  margin-left: -40px;
  padding-left: 40px;

  div {
    display: flex;
    flex-flow: column;
    gap: 4px;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #BFBFC0;
  }
}