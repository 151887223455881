.root {
	padding: 12px 40px 40px;
	min-height: 100vh;

	h2 {
		margin: 0 0 28px;

		font-weight: bold;
		font-size: 26px;
		line-height: 31px;
		color: var(--main-black);
	}
}
