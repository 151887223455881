.bottoms {
  padding-right: 40px;
  position: sticky;
  bottom: 0;
  right: -0;
  z-index: 10000;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 32px;
  background: #fff;
  filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--main-grey-inactive);
  }
}

.row {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.warning {
  width: 250px;
  margin-left: 8px;
  color: var(--BW-0, #1E1E1F);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.2px;
}

.arrowIcon {
  width: 16px;
  height: 16px;
  transform: rotate(225deg);

  path {
    fill: var(--main-grey-inactive);
  }
}

.alertIcon path {
  fill: var(--accent-yellow) !important;
}

.wrap {
  padding: 20px;
}

.actions {
  width: 310px;
}

//обращение к попап
.bottoms .actions div div div div {
  justify-content: flex-end;
}

.bottoms .actions div div div div svg {
  fill: #336BEB;
}

.openTrigger {
  font-size: 14px;
  padding: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--main-blue);

}

.actionItem {
  margin: 16px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  span {
    flex: 10 1;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: var(--main-blue);
  }

  svg {
    margin-right: 12px;
    flex: 1 1;
    fill: var(--main-blue);
    opacity: 0.7;
    transition: 0.2s;
  }

  path {
    fill: var(--main-blue);
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.actionItem:first-child {
  margin-top: 0;
}

.actionItem:last-child {
  margin-bottom: 0;
}

.popupCard {
  width: 300px;
}

.deleteActionItem {
  display: flex;
  align-items: center;
  color: #FF575F !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  cursor: pointer;

  span {
    color: #FF575F !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
  }

  path {
    fill: #FF575F !important;
  }
}