@import "styles/vars.scss";

.wrapper {
	display: flex;
	flex-direction: row;
}

.root {
	height: 100%;
	width: 100%;
}

.container {
	position: relative;
	min-height: 100vh;
	width: 100%;

	overflow: auto;

	padding-left: $sidebar-opened-width;

	@include mdDesktopBreakpoint() {
		padding-left: $sidebar-closed-width;
	}
}

.containerFullSize {
	padding-left: calc(80px + $sidebar-opened-width);
	padding-right: 160px;

	@include mdDesktopBreakpoint() {
		padding-left: calc(100px + #{$sidebar-closed-width});
	}
}

.containerServices {
	padding-left: calc(230px + $sidebar-opened-width);
	padding-right: 160px;

	@include mdDesktopBreakpoint() {
		padding-left: calc(100px + #{$sidebar-closed-width});
	}
}

.background__blue {
	background: #E5E5E5;
}

.background__white {
	background: #fff;
}

.header {
	position: fixed;
	z-index: 101;
	left: 0;
	right: 0;

	&.headerWithRedLine {
		transform: translateY(20px);
	}
}
