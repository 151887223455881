@import "styles/vars.scss";

.root {
	display: inline;
	cursor: pointer;

	&.rootWithChildren {
		position: relative;
	}
}

.root:hover {
	> .over {
		transition: visibility 0s linear;
		visibility: visible;
	}
}

.over {
	z-index: 1;
	min-width: 150px;
	font-size: 12px;
	font-weight: 400;

	transition: visibility 0s linear 0s;

	position: absolute;
	visibility: hidden;

	@include flex-align-items-center;

	color: white;
	border-radius: 10px;
	background: var(--main-grey-inactive);
	padding: 13px 8px;
}

.over:hover {
	@include flex-align-items-center;
}

.top {
	bottom: calc(10px + 100%);
	left: 50%;
	transform: translateX(-50%);
}

.right {
	top: 50%;
	left: calc(100% + 5px);
	transform: translateY(-50%);
}

.bottom {
	top: calc(10px + 100%);
	left: 50%;
	transform: translateX(-50%);
}

.left {
	top: 50%;
	right: calc(100% + 5px);
	transform: translateY(-50%);
}

.bottomRight {
	top: calc(10px + 100%);
	left: calc(100% + 5px);
}

.bottomLeft {
	top: calc(10px + 100%);
	right: calc(100% + 5px);
}

.topRight {
	bottom: calc(10px + 100%);
	left: calc(100% + 5px);
}

.topLeft {
	bottom: calc(10px + 100%);
	right: calc(100% + 5px);
}

.emptyOver {
	width: 100%;
	height: 100%;
}
