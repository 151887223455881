@import "styles/vars.scss";

.lastHours {
	display: flex;
	align-items: center;

	color: var(--accent-red);
	font-size: 14px;
	line-height: 20px;
	font-family: $ubuntu-font;

	svg {
		margin-right: 7px;

		width: 18px;
		height: 25px;

		path {
			fill: var(--accent-red);
		}
	}
}

.adEditLimit {
	display: flex;
	align-items: center;

	font-size: 14px;
	font-family: $ubuntu-font;

	& > span {
		opacity: 0.5;
	}
}

.statusTooltipText {
	& > * {
		font-size: 12px;
		font-family: $ubuntu-font;
		font-weight: 400;
		line-height: 16px;

		&:last-child {
			margin-top: 4px;
		}
	}
}

.servicesStatus {
	& > span {
		margin-left: 7px;

		font-size: 14px;
		font-family: $ubuntu-font;
		font-weight: 400;
	}
	svg {
		margin-left: -6px;

		width: auto;
		height: auto;
	}
}

.tariff {
	max-width: 200px;
	margin-right: 20px;
}

.tariffContainer {
	padding: 4px 12px 4px 6px;

	display: flex;

	background: #fff;
	border-radius: 14px;

	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);

	.tooltip {
		margin: 0 12px 0 0;
	}
}

.info {
	& > span {
		display: block;
		opacity: 0.5;
	}
}

.priceContainer {
	padding: 4px 12px;
	background: #FFFFFF;
	border-radius: 100px;
	width: fit-content;
	height: 28px;

	p {
		margin: 0;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}
}