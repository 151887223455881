.tasksContainer {
	margin-top: 14px;
}

.text {
	color: var(--main-grey-disabled) !important;
}

.infoContainer {
	margin-bottom: 24px;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cardTaskContainer {
	margin-bottom: 12px;

	&:last-child {
		margin-bottom: 0;
	}
}
