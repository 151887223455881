.addressTooltip {
	margin: 0 8px !important;
}

.addressTooltipCard {
	max-width: 362px;
	background: #3F3F40!important;
}

.aboutDealContainer {
	max-width: calc(70% - 35px);
}

.logo {
	position: absolute;
	right: 56px;
	top: 142px;

	svg {
		width: 108px;
		height: 97px;
	}

	path {
		fill: #e1e1e1;
	}
}

.cardsContainer {
	display: flex;
	flex-direction: row;
	align-items: stretch;
}

.leftCardsContainer {
	flex: 7 1;
	max-width: 494px;

	.card {
		margin-bottom: 28px;
		margin-right: 22px;
	}
}

.rightCardsContainer {
	flex: 3 1;

	.card {
		margin-bottom: 28px;
	}
}

.roomsAmount {
	margin-bottom: 28px;
	margin-right: 22px;
}

.card {
	position: relative;

	padding: 20px;

	background: var(--main-blue-disabled);
	border-radius: 10px;

	h3 {
		margin: 0 0 20px;

		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
	}

	.addressH3 {
		display: flex;
		align-items: center;
	}
}

.stickyContainer {
	position: sticky;
	top: 28px;
	padding-bottom: 28px;
	z-index: 99;

	.card:last-child {
		margin-bottom: 0;
	}
}

.submitContainer {
	margin-bottom: 20px;
}

.error {
	margin-top: 16px;

	text-align: right;
	font-size: 16px;
	color: var(--accent-red);
}

.contact {
	margin-bottom: 20px;
}
