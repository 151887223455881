@import "styles/vars.scss";

.root {
	display: flex;
	align-items: center;
	svg {
		width: 25px;
		height: 25px;
		margin-right: 4px;
	}
	span {
		color: var(--main-black);
		font-weight: 700;
		font-size: 16px;
		font-family: $default-font;
	}
}

.ok {
	svg path:first-child {
		fill: var(--accent-green);
	}
}

.lastDays {
	svg {
		fill: var(--accent-red);
	}
}
