.root {

  height: 100px;
  flex-shrink: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);

  &:hover {
    background: #fff;
    box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.25);
    transition: 0.2s;
  }
}

.rootMini {
  width: 440px;
  height: 80px;
}

.inTable {
  background: #FFF;
}

.rootSuccess {
  background: #E9FBF1!important;
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.content {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 8px 30px 4px 20px;
}

.contentMini {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 8px 12px 4px 12px;
}


.executor {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;
  gap: 8px;
  overflow: hidden;
  color: #878787;
  width: 157px;
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--, #2D2C46);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}

.titleMini {
  width: 280px;
}

.titleFailed {
  text-decoration: line-through;
}

.date {
  color: #7E7E7E;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
}

.description {
  width: 500px;
  overflow: hidden;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--, #484848);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
}

.descriptionMini {
  width: 416px;
  overflow: hidden;
  color: var(--, #484848);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
}

.relatedTitle {
  width: 240px;
  overflow: hidden;
  white-space: nowrap;
  color: #336BEB;
  text-overflow: ellipsis;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 15.6px */
  letter-spacing: 0.2px;
}