.root {
  position: relative;

  width: 100%;

  cursor: pointer;

  &:hover {
    .card {
      background: #fff;
      box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.25);
    }

    .closeIcon {
      opacity: 1;
    }
  }
}

.backgroundWhite {
  background: #FFFFFF !important;
}

.card {
  padding: 0px 16px 0px 0px;
  min-height: 80px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // column-gap: 12px;


  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);
  transition: 0.2s;

  &.cardShorter {
    max-width: calc(100% - 36px);
  }
}

.img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.img img {
  border-radius: (10px 0 0px 10px);
}

.content {
  flex: 1;
  padding: 8px 20px 4px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  height: 100px;
  color: var(--main-black);

  // &.withMarginLeft {
  // 	margin-left: 28px;
  // }
}

.name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardPopup {
  width: 230px;
}

.cancel {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #FF575F;

  svg {
    path {
      fill: #FF575F;
    }
  }
}

.phone {
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  .phone_number {
    padding-right: 8px;
  }

  .position {
    font-size: 14px;
    opacity: 0.5;
  }
}

.col {
  display: flex;
  flex-flow: column;
}

.spec {
  margin-top: 4px;
  width: 390px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
}

.closeLoading {
  padding: 8px;

  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);

  svg {
    width: 12px !important;
    height: 12px !important;
  }
}

.closeIcon {
  padding: 8px;

  display: flex;
  opacity: 0;

  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);

  transition: 0.2s;

  svg {
    width: 12px;
    height: 12px;

    transition: 0.2s;
    fill: var(--main-blue-secondary);
  }

  &:hover svg {
    fill: var(--accent-red);
  }
}

.phoneAndDialog {
  display: flex;
  align-items: center;
}

.socials {
  display: flex;
  gap: 8px;
}

.socials div {
  width: 24px;
  height: 24px;
}

.socials svg {
  width: 24px;
  height: 24px;
  min-width: 100%;
}


.mini {
  height: 80px;
  width: 320px;
}