.loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: rgba(77, 77, 104, 0.3);
  backdrop-filter: blur(4px);
}

.root {
  margin-top: 60px;
}

.title {
  color: #2D2C46;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
}

.counter {
  color: #9595A3;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
}

.lookOther {
  color: #336BEB;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.collections {
  width: 720px;
  max-height: 324px;
  display: flex;
  flex-flow: column;
  gap: 12px;
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.header {
  justify-content: start!important;
  padding-bottom: 12px;
}
