@import "styles/vars.scss";

.root {
	display: flex;
	flex-direction: column;
	row-gap: 12px;

	margin-top: 48px;
}

.skeleton {
	@include skeleton();

	width: 100%;
	height: 100px;
}
