.root {
	position: relative;
}

.rootMinHeight {
	min-height: 300px;
}

.cover {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;

	background: rgba(14, 29, 68, 0.6);
	backdrop-filter: blur(8px);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	p {
		margin: 23px auto 55px auto;
		max-width: 70%;

		text-align: center;

		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
		color: #fff;
	}
}
