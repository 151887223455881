@import "styles/vars.scss";

.Pages {
	padding: 8px 12px;
	margin: 0;

	display: flex;
	flex-wrap: wrap;

	background-color: var(--main-black);
}

.Wrapper {
	margin: 6px 3px;

	position: relative;
	list-style: none;

	width: 252px;
	height: 100%;

	&.active {
		.Page {
			background-image: none !important;
			background-color: rgba(230, 230, 230, 0.3);
		}
	}

	&:hover {
		.bar,
		.Remove {
			opacity: 1;
		}

		.buttons {
			button {
				opacity: 1;
			}
		}

		.Page {
			&:after {
				position: absolute;
				content: " ";
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: 100;
				background: linear-gradient(360deg, rgba(45, 44, 70, 0.5) 3.25%, rgba(45, 44, 70, 0) 32.52%),
					linear-gradient(180deg, var(--main-black) 0%, rgba(45, 44, 70, 0) 45.53%);
			}
		}

		&.clone {
			margin-top: 10px;
			margin-left: 10px;

			width: 100%;
			height: 100%;

			.Page {
				animation: pop 150ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
				cursor: grabbing;

				&:after {
					position: absolute;
					content: " ";
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: 100;
					background: linear-gradient(360deg, rgba(45, 44, 70, 0.5) 3.25%, rgba(45, 44, 70, 0) 32.52%),
						linear-gradient(0deg, rgba(45, 44, 70, 0.5), rgba(45, 44, 70, 0.5));
				}
			}
		}
	}

	&:not(.active, .clone) {
		&.insertBefore,
		&.insertAfter {
			.Page:after {
				content: "";
				background-color: #4c9ffe;
			}
		}
	}

	&.insertBefore,
	&.insertAfter {
		.Page:after {
			width: 100%;
			height: 100%;
		}
	}

	&.insertBefore {
		&.clone {
			margin-left: -75px;
		}

		.Page:after {
			left: -9px;
		}
	}

	&.insertAfter {
		&.clone {
			margin-left: 75px;
		}

		.Page:after {
			right: -9px;
		}
	}
}

.Page {
	position: relative;
	display: block;
	width: 100%;
	height: 147px;
	background-color: rgba(255, 255, 255, 0.2);
	background-size: cover;
	border-radius: 3px;
	box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(34, 33, 81, 0.15);
	padding: 0;
	outline: none;
	appearance: none;
	border: none;
	touch-action: none;

	&:focus-visible:not(.active &) {
		box-shadow: 0 0 0 2px #4c9ffe;
	}
}

.Wrapper {
	&:not(.withoutHoverEffects) {
		.Page {
			cursor: grab;
		}
	}
}

.loader {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;

	width: 100% !important;
	height: 100% !important;

	background-color: rgba(77, 77, 104, 0.3) !important;
}

.bar {
	padding: 5px;

	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;

	z-index: 101;
	transition: opacity 0.2s;

	svg {
		fill: #fff;
	}
}

.buttons {
	position: absolute;
	bottom: 9px;
	left: 9px;

	display: flex;
	column-gap: 7px;

	button {
		@include button-no-style();

		width: 20px;
		height: 20px;

		padding: 0;

		cursor: pointer;
		opacity: 0;
		z-index: 101;
		transition: opacity 0.2s;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.Remove {
	@include button-no-style();
	position: absolute;

	cursor: pointer;
	opacity: 0;
	z-index: 101;
	transition: opacity 0.2s;
}

.Remove {
	top: 0;
	right: 0;

	&:hover {
		svg {
			fill: var(--accent-red);
		}
	}

	svg {
		fill: #fff;

		width: 20px;
		height: 20px;

		padding: 5px;
	}
}

.gridImg {
	width: inherit;
	height: inherit;

	object-fit: cover;
}

@keyframes pop {
	0% {
		transform: translate3d(-10px, -10px, 0) scale(1);
	}

	100% {
		transform: translate3d(0px, 0px, 0) scale(1.025);
	}
}
