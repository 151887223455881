.customInfoRow {
	padding: 10px 0 5px 12px;
}

.hideLabel {
	position: absolute;
	left: 0;
	top: 3px;

	width: 49%;
	height: 100%;

	background: var(--main-blue-disabled);
}
