.root {
	max-width: 350px;
}

.fieldsContainer {
	margin-bottom: 60px;
}

.blockedLink {
	position: relative;
	padding: 24px 44px 24px 30px;

	background: #f5f5f5;
	border-radius: 10px;

	font-size: 14px;
	font-family: "Ubuntu", sans-serif;
	width: 446px;
}

.blockedLinkIcon {
	position: absolute;
	top: 4px;
	right: 4px;
}

.submitContainer {
	display: flex;
	align-items: center;

	button {
		margin-right: 10px;
	}
}

.loginLinkContainer {
	font-family: "Ubuntu", sans-serif;
}

.link {
	color: var(--main-blue);
	font-size: 16px;
	text-decoration: none;
}
