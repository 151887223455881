@import "styles/vars.scss";

.root {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.h2 {
		margin-top: 0 !important;
		margin-bottom: 4px !important;
	}

	span {
		margin: 0;
		color: var(--main-black);
		font-weight: normal;
		font-size: 14px;
		opacity: 0.5;
		font-family: $ubuntu-font;
	}
}

.leftPart {
	display: flex;
	align-items: center;
}

.rightPart {
	display: flex;
	justify-content: space-between;
	width: 226px;
}

.link {
	border-bottom: dotted 1px var(--main-blue) !important;
	border-radius: 0 !important;
}

.companyLoading {
	@include skeleton();

	height: 15.5px;
	width: 100%;
}
