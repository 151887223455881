@import "styles/vars.scss";

.root {
  position: absolute;
  max-height: 100vh;
  right: 0;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 0px 35px 0px #124BF659;

  height: 100%;
  width: 134px;
  flex-flow: column;

  @include flex-align-items-center;

  background: #FFFFFF;
  backdrop-filter: blur(4px);
  overflow-y: scroll;
  overflow-x: hidden;

  transition: 0.3s;

  & > * {
    height: 100%;
  }

  &:hover {
    width: 500px;
    background: #FFFFFF;
    backdrop-filter: blur(4px);
    box-shadow: 0px 0px 35px 0px #124BF659;
  }
}

.underlayer {
  width: 134px;
}

.column {
  display: flex;
  flex-flow: column;
}

.header {
  display: flex;
  flex-flow: column;
  padding: 16px 20px 0px 25px;
  max-height: 120px;
}

.title {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
  margin-bottom: 4px;
}

.subTitle {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #8B8B8D;
}

.products {
  padding: 0 30px;
  margin-top: 8px;
  display: flex;
  flex-flow: column;
  gap: 12px;
  overflow: auto;
}

