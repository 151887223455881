@import "styles/vars.scss";

.root {
	//margin-top: 80px;
	display: flex;
	flex-direction: column;
}

.partnerAdHeader {
	padding: 8px 8px 0;
}

.partnersAdEditFooter {
	padding: 0 20px 24px 32px;
}

.startAd {
	padding-top: 29px;
	padding-bottom: 20px;
	padding-left: 32px;
}

.wasInAd {
	& > * {
		&:nth-child(2) {
			order: 3;
			padding-top: 0;
		}
	}
}

.archived {
	//* {
	//	color: var(--main-black);
	//}

	> div:nth-child(2) {
		> div > div {
			> div:first-child {
				> div:first-child {
					opacity: 0.5;
				}
				> div:last-child
				{
					> ul > div > div {
						opacity: 0.5;

						div {
							color: var(--main-black);
						}

						svg * {
							fill: #9595A3;
						}
					}
				}
			}

			> div:nth-child(2) {
				> div {
					> div:first-child {
						opacity: 0.5;
						> div {
							> span > div > div {
								pointer-events: none;
								opacity: 0.5;
							}
							p {
								color: var(--main-black);
							}
							&:last-child {
								pointer-events: none;
							}
						}
						svg * {
							fill: #9595A3;
						}
					}
					> div:nth-child(2) {
						> div:first-child {
							opacity: 0.5;
						}

						> div:nth-child(2) {
							opacity: 0.5;
							> div {
								pointer-events: none;
							}
							> div:first-child > label {
								color: white !important;
								background-color: #BFC4DF;
							}
							> div > label {
								color: #9595A3;
							}
						}

						> div:last-child {
							opacity: 0.5;
							pointer-events: none;

							svg * {
								stroke: #9595A3;
							}
						}
					}
				}
			}
		}
	}

	> div:nth-child(3) {
		> div:first-child {
			opacity: 0.5;
		}

		> div > div > div:last-child {
			pointer-events: none;
		}
	}
}

.archiveContainer {
	margin-top: 40px;
	display: flex;
	gap: 20px;
	opacity: 1 !important;
}
