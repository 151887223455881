.root {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 100000;

	width: 100%;
	height: 101%;
	overflow: auto;

	background: rgba(30, 30, 31, 0.90);

	display: flex;
	justify-content: center;
	align-items: center;
}

.modal__alert,
.modal__default {
	.content {
		display: flex;
		flex-direction: column;

		border-radius: 20px;
		max-height: 90vh;
		background: var(--BW-5, #FFF);
	}
}

.modal__alert .content {
	padding: 20px 40px;

	display: flex;
	flex-direction: row;

	min-height: 186px;

	.contentBody {
		height: unset;
	}
}

.alertButtonContainer {
	margin-top: 44px;

	display: flex;
	justify-content: flex-end;

	button {
		+ button {
			margin-left: 24px;
		}
	}
}

.alertcontentBodyContainer {
	width: 100%;
}

.modal__default .content {
	padding: 24px 32px;
}

.contentBody {
	height: 100%;

	color: var(--main-black);
}

.heading {
	margin: 0;

	font-size: 18px;
	line-height: 20px;
	color: var(--main-black);
}

.headingAlert {
	padding: 22px 0 16px;

	font-size: 20px;
	line-height: 28px;
}

.close {
	position: absolute !important;
	top: 12px;
	right: 12px;
}

.loaderWrapper {
	filter: blur(3px);
}

.loader {
	position: absolute !important;
	top: 0px;
	left: 0px;

	width: 100%;
	height: 100% !important;

	z-index: 100000;

	cursor: wait;
	background: black;
	opacity: 0.5;
}
