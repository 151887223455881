.root {
	width: 100%;
}

.applicationsTableRow {
	position: relative;
	height: 100px;

	& > div {
		&:first-child {
			flex-grow: 1;
			margin-right: 2px;
		}
	}
}
