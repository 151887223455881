.buttonBlock {
  height: 64px;
  align-items: center;
  width: 720px;
  border-radius: 0 0 20px 20px;
  margin-bottom: -20px;
  padding-right: 40px;
  box-shadow: 0 -8px 10px 0 rgba(0, 64, 229, 0.10);
  display: flex;
  justify-content: end;
  gap: 12px;

  svg {
    opacity: 1;
  }
}