@import "styles/vars.scss";

.objectItemWrapper {
	margin-bottom: 12px;
}

.objectsGroupItem {
	margin-bottom: 12px;
}

.skeleton {
	display: flex;
	flex-wrap: nowrap;

	width: 100%;
	height: 100px;

	margin-bottom: 10px;

	@include skeleton();
}

.list {
	display: flex;
	flex-direction: column;
	row-gap: 12px;
}
