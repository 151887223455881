@import "styles/vars.scss";

.root {
	padding-top: 28px;

	border-top: 1px solid #e5e5e5;
	& > div {
		&:first-child {
			@include flex-align-items-center;
		}
		&:last-child {
			margin-top: 32px;
		}
	}
}

.block {
	margin-right: 56px;

	font-family: $ubuntu-font;
	font-size: 14px;
	white-space: nowrap;
}

.title {
	color: var(--main-grey-light);
	font-weight: 400;
}

.info {
	margin-top: 4px;

	color: var(--main-blue);
}

.date {
	color: var(--main-black);
}

.buttons {
	margin-top: 12px;

	@include flex-align-items-center;

	& > button {
		&:first-child {
			margin-right: 40px;

			svg {
				path:last-child {
					fill: #fff;
				}
			}
		}

		svg {
			width: 24px;
			height: 24px;
		}
	}
}

.closeOutlined {
	path {
		&:last-child {
			fill: white;
		}
	}
}

.buttonsWithDate {
	max-width: 610px;

	display: flex;
	justify-content: space-between;
}
