.root {
	display: flex;
	align-items: center;
	justify-content: space-between;

	:last-child {
		display: inline;
	}
}

.title {
	font-weight: bold;
	font-size: 26px;
	line-height: 120%;
}
