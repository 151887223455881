.header {
	margin-bottom: 16px;

	font-weight: bold;
	font-size: 18px;
	color: var(--main-black);
}

.button {
	margin-bottom: 8px;

	svg {
		width: 16px !important;
		height: 16px !important;
		transform: rotate(90deg);
	}
}
