.body {
  background: #F2F5FB;
  padding: 24px 20px;
  border-radius: 0 0 20px 20px;
  overflow-x: hidden;
  max-height: 563px;
  padding-bottom: 0!important;
}

.root {
  margin-bottom: 40px;
  position: relative;
  border-radius: 0 0 20px 20px;
}

.stripe {
  height: 1px;
  opacity: 0.3;
  background: #BFC4DF;
  width: 100%;
}

.stripe2 {
  padding-bottom: 20px;
  background: #F2F5FB;
  border-radius: 0 0 20px 20px;
}

svg {
  opacity: 1;
}

.content {
  padding-right: 8px;
  background: #F2F5FB;
  border-radius: 0 0 20px 20px;
  padding-bottom: 20px;
  padding-top: 8px;

  ::-webkit-scrollbar-track {
    background: white;

  }
}