@import "styles/vars.scss";

.groupRadio {
	display: flex;
}

.groupRadio__row {
	flex-direction: row;
}

.groupRadio__column {
	flex-direction: column;
}

.groupRadio__grid {
	flex-wrap: wrap;

	.radio {
		width: calc(50% - 22px);
		margin-right: 22px;

		&:nth-child(even) {
			margin-right: 0;
			width: calc(50% - 10px);
			margin-left: 10px;
		}
	}
}

.groupRadio input[type="radio"] {
	display: none;
}

.groupRadio label {
	font-size: 16px;
	line-height: 24px;
	font-family: $ubuntu-font;
	color: #000000;

	cursor: pointer;

	display: flex;
	flex-direction: row;
	align-items: center;
}

.circle {
	position: relative;

	margin-right: 12px;

	width: 24px;
	height: 24px;

	border: 2px solid var(--main-blue-40);
	border-radius: 50%;
	transition: 0.2s;
}

.dot {
	display: none;
}

.groupRadio label:hover .circle {
	border: 2px solid var(--main-blue);
}

.groupRadio label.checked .circle {
	border: 2px solid var(--main-blue);
	background: var(--main-blue);
}

.error .circle {
	border: 2px solid var(--accent-red);
}

.groupRadio label.checked.error .circle {
	border: 2px solid var(--accent-red);
	background: var(--accent-red);
}

.groupRadio label.checked .dot {
	display: flex;
	position: absolute;
	left: 0;
	top: 0;

	width: 20px;
	height: 20px;
	border-radius: 50%;

	border: 4px solid #fff;
}

.labelOnLeft {
	width: 100%;
	justify-content: space-between;

	.circle {
		margin-right: 0;
		margin-left: 12px;
		order: 2;
	}

	.label {
		order: 1;
	}
}

.radio {
	margin: 6px 24px 6px 0;

	position: relative;
	display: flex;
}

// medium size
.groupRadio.size__medium label {
	font-size: 14px;
	line-height: 20px;
}

.groupRadio.size__medium .circle {
	width: 20px;
	height: 20px;

	margin-right: 8px;
}

.groupRadio.size__medium label.checked .dot {
	width: 16px;
	height: 16px;
}

// cera pro font
.groupRadio.cera label {
	font-family: $default-font;
}

.disabled {
	opacity: 0.5;
	cursor: not-allowed;

	label {
		pointer-events: none;
	}
}
