@import "styles/vars.scss";

.block {
	display: flex;
	margin-top: 40px;
}

.border {
	width: 4px;
	height: 38px;
	margin-right: 21px;
	background-color: var(--accent-yellow);
}

.content {
	display: flex;
	flex-direction: column;
}

.title {
	margin-bottom: 5px;
	color: var(--main-black);
	font-weight: bold;
	font-size: 16px;
}

.description {
	max-width: 565px;
	color: var(--main-grey-inactive);
	font-size: 14px;
	word-break: break-word;
	font-family: $ubuntu-font;

	&:first-child {
		max-width: 397px;
	}

	&:last-child {
		max-width: 451px;
		margin-bottom: 30px;
	}
}
