@import "styles/vars.scss";

.root {
	margin-top: 60px;
}
.text {
	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 21px;
	margin-top: 12px;
}

.title {
	color: #2D2C46;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 24px */
	letter-spacing: 0.2px;
}
