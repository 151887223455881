@import "styles/vars.scss";
@import "src/components/UI/Tag/TagTaskStatus/styles/utils";

.icon {
	@include flex-align-items-center;

	svg {
		width: 28px;
		height: 28px;

		path {
			fill: get-status-color();
		}
	}
}
