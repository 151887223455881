@import "styles/vars.scss";

.root {
	@include flex-align-items-center;
	justify-content: space-between;

	padding: 12px 28px;

	border: 1px solid rgba(var(--main-blue-secondary-rgb), 0.4);
	border-radius: 10px;
}

.title {
	margin-right: 16px;

	font-family: $ubuntu-font;
	font-size: 12px;
	line-height: 16px;
	opacity: 0.5;
	color: var(--main-black);
}

.item {
	display: flex;
	align-items: baseline;
}

.name {
	font-size: 16px;
	line-height: 24px;
	color: var(--main-black);
	opacity: 0.9;
	font-family: $default-font;
}

.phone {
	font-family: $ubuntu-font;
}
