.columnsWrapper {
	height: 30px;
	margin-bottom: 12px;

	width: -moz-fit-content;
	width: fit-content;

	background: var(--main-blue-disabled);
	border-radius: 100px;

	display: flex;
	flex-direction: row;
	align-items: center;
}

.titleItem {
	padding: 4px 12px;

	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-grey-sub);

	cursor: pointer;

	&.titleItemActive {
		background: var(--main-blue);
		color: #fff;
		border-radius: 100px;
	}
}
