/**
* This variable is used to clarify intentions in sharing
*  status color among TagTaskStatus children locally.
*
* It's better to manage color through get & set utils below
*/
$status-color-variable: --status-color;

@mixin set-status-color($color) {
	#{$status-color-variable}: $color;
}

@function get-status-color() {
	@return var($status-color-variable);
}
