@import "styles/vars.scss";

.root {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.inputWrapper {
	max-width: 28px;
}

.separator {
	margin: 0 12px;

	font-size: 12px;
	line-height: 20px;
}
