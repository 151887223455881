.root {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.cardObjectExist {
	width: 100%;
	z-index: 100;
}
.inDrawer {
	max-width: 800px;
}