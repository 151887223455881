.root {
  width: 720px;

  border-radius: 8px;
  background: #F2F2F2;
padding: 18px;
}


.rowBetween {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 42px;
  width: 166px;
  height: 164px;
  border-radius: 4px;
  background: #FFFFFF;

  img {
    width: 166px;
    height: 164px;
    border-radius: 4px
  }
}

.edit {

  display: flex;
  align-items: center;

  opacity: 0.5;
  transition: 0.2s;

  cursor: pointer;

  svg {
    margin-right: 16px;
    path {
      fill: #336BEB;
    }

  }

  &:hover {
    opacity: 1;
  }
}

.column {
  display: flex;
  width: 480px;
  flex-flow: column;
  justify-content: space-between;
}

.firstName {
  font-family: Cera Pro;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.name {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.phone {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.status {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
color: #336BEB;
}

.submitBtns {
  margin: 16px 30px 24px;

  display: flex;
  justify-content: flex-end;
  column-gap: 24px;
}

.editContent {
  width: 436px;
}