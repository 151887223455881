.root {
	display: flex;
	width: auto;

	> div {
		background-color: #fff;
	}
}

.itemWrapper {
	display: flex;
	align-items: center;
	padding: 11px 9px 9px 9px;
	width: 100%;
	justify-content: center;
	border-bottom: 1px solid var(--main-blue-inactive);
	border-right: 1px solid var(--main-blue-inactive);
}

.start {
	justify-content: flex-start;
}

.inputTitle,
.inputTitleBig,
.inputTitlePlace,
.inputTitleFlat,
.inputTitleFlatSquare {
	font-weight: 500;
	font-size: 14px;
	color: var(--main-black);
	margin-right: 12px;
}

.inputTitle {
	min-width: 50px;
}

.inputTitleBig {
	min-width: 90px;
}

.inputTitleFlatSquare {
	min-width: 55px;
}

.inputWrapperFlatSquare {
	min-width: 155px;
}

.inputTitleFlat {
	min-width: 65px;
}

.inputTitlePlace {
	min-width: 90px;
}

.flats {
	min-width: 626px;
}

.fullWidth {
	width: 100%;
	justify-content: flex-start;
}

.flatType {
	width: 255px;
}

.squareTrigger {
	display: flex;
	align-items: center;
	margin-right: 4px;
}

.specialSelect {
	min-width: 184px;
}

.material {
	max-width: 200px;
}
