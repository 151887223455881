.root {
	display: flex;
	flex-direction: column;

	height: 210px;
}

.input {
	padding-left: 10px;
}

.inputLabel {
	font-size: 14px;
	padding-left: 0;
}

.submitBtnContainer {
	width: fit-content;

	margin-top: auto;
	margin-left: auto;
}

.select::after {
	content: "";
	width: 100%;
	position: absolute;
	bottom: 0px;
	left: 0px;
	height: 1px;
	border-bottom: 1px solid var(--main-blue-secondary);
}

.loaderContainer {
	margin: 60px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
