.Toastify--animate {
	animation-duration: 0.4s !important;
}
.Toastify__toast-container {
	width: fit-content;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: 999999;
}

.Toastify__toast-icon-container {
	position: absolute;
	left: 10px;
	top: 10px;

	width: 40px;
	height: 40px;

	border-radius: 20px;

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		width: 20px;
		height: 20px;
	}

	svg,
	path {
		fill: #fff;
	}
}

.Toastify__toast-icon-container-info {
	background: var(--main-blue);
}
.Toastify__toast-icon-container-success {
	background: var(--accent-green);
}
.Toastify__toast-icon-container-error {
	background: var(--accent-red);
}

.Toastify__toast {
	padding: 22px 30px 22px 24px;

	background: #898996;

	color: #fff;
	font-size: 16px;
	line-height: 19px;
	min-height: unset;
	max-width: 400px;

	border-radius: 20px;
	cursor: default;
}

.Toastify__toast-text-container {
	margin: 0 0 0 42px;
}

.Toastify__toast-body {
	padding: 0;
}

.Toastify__close-button {
	opacity: 1;

	svg {
		position: absolute;
		top: 14px;
		right: 11px;

		width: 14px;
		height: 14px;
		fill: #fff;
	}
}
