@import "styles/vars.scss";

.body {
	margin: 0;
	padding: 0;

	li {
		list-style-type: none;
		margin-top: 10px;
		//background-color: #fff;
		//border-radius: 10px;

		&:first-child {
			margin-top: 0;
		}
	}
}

.noData {
	padding: 28px 0;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--main-blue-disabled);
	border-radius: 10px;

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 24px;
	color: var(--main-grey-inactive);
}
