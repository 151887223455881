.root {
	display: flex;
	flex-direction: column;
	padding: 0 0 0 1px;
	border-right: 1px solid var(--main-grey-light);
}

.sticky {
	width: 230px;
	position: sticky;
	top: 30px;
}

.header {
	margin-bottom: 7px;
	color: var(--main-black);
	font-weight: bold;
	font-size: 20px;
}
