.root {
  margin-top: 24px;
}

.title {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.header {
  padding-bottom: 8px;
}

.card {
  width: 680px!important;
}

.delete {
  color: #FF575F;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
  cursor: pointer;
  gap: 4px;

  svg {
    fill: #FF575F;
  }
}

.add {
  color: #336BEB;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
  cursor: pointer;
  gap: 4px;

  svg {
    fill: #7F8DEF;
  }
}

