.container {
  padding: 12px 20px 20px 20px;
  background-color: white;
  border-radius: 12px;
  margin-top: 28px;
  p {
    margin: 0;
    line-height: 20px;
    color: var(--main-black);
  }

  > div:not(:last-child) {
    margin-bottom: 16px;
  }
}