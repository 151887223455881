@import "styles/vars.scss";

button.root {
	padding: 20px 114px !important;

	height: auto !important;

	border-radius: 0 !important;

	svg {
		margin-right: 8px;
		fill: var(--main-blue) !important;
		opacity: 0.5;

		path {
			fill: var(--main-blue) !important;
		}
	}

	&:hover {
		background-color: var(--main-blue) !important;
		color: #fff !important;

		svg {
			path {
				fill: #fff !important;
			}
		}
	}
}
