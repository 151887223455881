@import "styles/vars.scss";

.root {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;

	width: 10px;
	border-radius: 0 10px 10px 0;
}

.overRoot {
	display: flex;
	align-items: center;
	justify-content: center;
}

.popoverCircle {
	width: 18px;
	min-width: 18px;
	height: 18px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: $ubuntu-font;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #fff;

	border-radius: 100px;
}

.whoIsClient {
	margin-left: 8px;
	font-family: $ubuntu-font;
	font-size: 12px;
	line-height: 14px;
	color: #fff;
}
