.checkbox {
	display: none;
}

.label {
	position: relative;
	display: flex;
	min-width: 19px;
	min-height: 19px;
	cursor: pointer;
}

.checkboxBg {
	position: absolute;
	top: 0;
	left: 0;

	width: 19px;
	height: 19px;

	border: 2px solid var(--main-blue-secondary);
	background: #fff;
	border-radius: 3px;
}

.labelDisabled {
	opacity: 0.2;
	cursor: not-allowed;
}

.labelText {
	margin-left: 27px;

	color: var(--main-black);
	font-weight: 400;
	font-size: 14px;

	opacity: 0.5;
	transition: 0.1s;
	user-select: none;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.checked {
	.labelText {
		opacity: 1;
	}

	.checkboxBg {
		background-image: url(../../../../assets/icons/checkbox.svg);
		background-repeat: no-repeat;
		background-position: center;
		border-color: var(--main-blue);
	}
}

.radioButton,
.radioButtonWhite {
	.checkboxBg {
		display: none;
	}

	.label {
		background-color: var(--main-blue-disabled);
		padding: 3.5px 12.5px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100px;

		&.checked {
			background-color: var(--Primary-Basic, #336BEB);

			.labelText {
				color: #fff;
			}
		}
	}

	.labelText {
		margin-left: 0;
		color: #1E1E1F;
		opacity: 1;
		font-weight: 500;
		font-size: 14px;
		margin-bottom: -1px;
	}

	&.small {
		.label {
			padding: 3.5px 8px;
		}

		.labelText {
			margin-top: 0;
		}
	}

	.smallRadioButton {
		width: 25px;
		height: 25px;
		padding: 0;

		.labelText {
			margin: 0;
		}
	}
}

.radioButtonWhite {
	.label {
		background-color: #fff;
	}
}

.noOpacity {
	.labelText {
		opacity: 1;
	}
}

.bold {
	.labelText {
		font-weight: 500;
	}
}
