@import "styles/vars.scss";

.root {
	display: flex;
	flex-flow: column;
	justify-content: center;
	height: 100%;
	h2 {
		margin-bottom: 24px;
	}
}
h1 {
	color: #2D2C46;
	font-family: Cera Pro;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px; /* 150% */
	letter-spacing: 0.2px;
	
}
.invitesList {
	// height: 210px;
	// margin-bottom: 57px;
	overflow-y: scroll;
	padding-right: 40px;
}
.no_invites {
	color: #ADB4D6;
	text-align: center;
	font-family: Ubuntu;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; 
	letter-spacing: 0.2px;
}
.invitesListItem {
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 8px;
	border-bottom: 1px solid #D5D3CE;
}

.invitesListItemCompany {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width:100%;
}
.invitesListItemCompanyName {
	display: flex;
	align-items: center;
	width: 50%;
	max-width: 50%;
}

.invitesListItemCompany span{
	color: #2D2C46;
	font-family: "Cera Pro";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
	letter-spacing: 0.2px;
	margin-bottom: 8px;
}
.invitesListItemCompany p{
	color:#484848;
	font-family: "Ubuntu";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 133.333% */
	letter-spacing: 0.2px;
}

.invitesListItemManipulation {
	display: flex;
}

.invitesListItemDecline {
	margin-left: 20px;
}

.invitesListItemName {
	max-width: 300px;
	overflow: hidden;
	color: var(--main-black);
	font-weight: bold;
	font-size: 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.invitesListItemInfo {
	margin-left: 10px;

	display: flex;

	cursor: pointer;

	svg {
		transform: scale(0.8);
		fill: var(--main-grey-disabled);
	}

	&:hover svg {
		fill: var(--main-blue);
	}
}

.continueBtnContainer {
	width: 425px;

	.button {
		min-width: 222px;
	}
}
