@import "styles/vars.scss";

.root {
	height: 92px;
	padding: 24px 40px 24px;
	border-top: 1px solid #e5e5e5;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	flex-wrap: wrap;
}

.column {
	display: flex;
	flex-direction: column;
	min-width: 165px;
}

.lightGrey {
	color: #CCC;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.2px;
}
.darkGrey {
	color: #2D2C46;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.2px;
}

.blue {
	color: #336BEB;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.2px;
}
