.root {
	display: flex;
	flex-direction: column;
	row-gap: 12px;
}

.secondLine {
	display: flex;
	width: 100%;
	align-self: center;
	column-gap: 20px;
}
