@import "styles/vars.scss";

.underlay {
	padding: 20px 36px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.input input {
		padding: 4px 0;

		text-align: center;

		border: 1px solid #bfc4df;

		font-family: $ubuntu-font;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}
}

.rootH4 {
	margin-top: 0 !important;
}

.rootPHeader {
	margin: 0 0 4px;
}

.rootP {
	margin: 0;
	padding: 4px 0;
}

.underlayBlock {
	width: 50%;
}
