@import "styles/vars.scss";

.number {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-black);
}

.date {
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: var(--main-black);
	opacity: 0.5;
}

.noResponsible {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: (--main-black);
}

.root {
	padding: -8px -20px -4px!important;
}