.root {
  display: flex;
  flex-direction: column;
  width: 350px;
}

.loadingRow {
  margin: 10px 0;
  width: 100%;
  height: 60px;
  background: var(--main-blue-inactive);
}
