@import "styles/vars.scss";

.root {
	max-width: 800px;
}

.content {
	position: relative;
	width: 796px;

	display: flex;
	flex-direction: column;
}
.inDrawer {
	max-width: 800px;
}
.contentContainer {
	padding: 2px 45px 60px 40px;
	width: 100%;
}

.callCenterMenu {
	margin-top: 24px;
}


.contentSources {
	margin-top: 43px !important;
	margin-bottom: 60px;
}

.adDescription {
	margin-top: 80px;
	width: 720px;
}

.menu {
	flex: 1;
	padding-right: 8px;
	min-width: 175px;
}

.count {
	color: var(--main-grey-disabled);
}

.probablyAd {
	display: flex;
	flex-direction: column;
	row-gap: 12px;
}

.contacts {
	width: 712px;
}