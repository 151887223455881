.map-handlers {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	background-color: #fff;
	box-shadow: 10px 9px 17px rgba(0, 0, 0, 0.2);
}

.map-handlers__draw {
	position: relative;
	padding: 11px 100px 11px 18px;
	width: 100%;
	transition: all 0.25s ease;

	&::after {
		position: absolute;
		top: 1px;
		right: 0;
		bottom: 3px;
		width: 1px;
		background-color: #e5e5e5;
		content: "";
	}

	&.active {
		background-color: var(--main-blue-disabled);
	}
}

.map-handlers__count {
	position: absolute;
	top: 50%;
	right: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	padding-right: 7px;
	padding-left: 15px;
	color: #fff;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	background-color: #336BEB;
	border-radius: 100px;
	transform: translateY(-50%);
	cursor: pointer;
	transition: all 0.25s ease;

	g {
		transition: all 0.25s ease;
	}

	&:hover {
		background-color: #9da9ff;

		g {
			opacity: 1;
		}
	}
}

.map-handlers__remove {
	margin-bottom: 1px;
}

.map-handlers__remove,
.map-handlers__button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	background-color: transparent;
	width: 100%;
	border: none;
	outline: none;
	cursor: pointer;
}

.map-handlers__button {
	cursor: pointer;
	transition: all 0.25s ease;

	path {
		transition: all 0.25s ease;
	}

	&:hover {
		path {
			fill: var(--main-blue);
		}

		.map-handlers__button-text {
			color: var(--main-blue);
		}
	}
}

.map-handlers__button-text {
	margin-left: 17px;
	color: #000;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	cursor: pointer;
	transition: all 0.25s ease;
}

.map-handlers__select {
	padding: 16px 24px;
	width: 100%;
}
