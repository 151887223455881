.block {
	margin-top: 41px;

	&:first-child {
		margin-top: 0;
	}
}

.graph {
	h3 {
		margin: 0;
		font-size: 18px;
		color: var(--main-black);
	}
}

.medianPriceMain {
	background: linear-gradient(268.12deg, #7f8def 37.95%, rgba(127, 141, 239, 0.7) 86.88%);
}

.medianPriceWrapper {
	background: var(--main-blue-disabled);
}

.graphBlock {
	margin-top: 30px;
}

.sentences {
	& > div {
		margin-top: 8px;
		&:first-child {
			margin-top: 0;
		}
	}
}
