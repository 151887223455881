@import "styles/vars.scss";

.root {
	display: flex;

	background: #fff;

	p {
		margin: 0;
	}
}

.root__horizontal {
	width: 100%;

	border-radius: 10px;

	.priceDateContainer {
		margin-top: 80px !important;
	}

	.address {
		margin: 16px 0 12px !important;
	}

	.content {
		padding: 50px 60px 40px 45px;

		width: calc(100% - 324px);
	}

	.imageContainer {
		width: 324px;
		max-height: 305px;

		img {
			border-radius: 10px 0 0 10px;
		}
	}
}

.root__vertical {
	flex-direction: column;

	width: 334px;

	border-radius: 20px;

	.priceDateContainer {
		margin-top: 50px !important;
	}

	.address {
		margin: 4px 0 20px !important;
	}

	.content {
		padding: 20px 20px 24px;
	}

	.imageContainer {
		width: 100%;
		height: 220px;

		img {
			border-radius: 20px 20px 0 0;
		}
	}
}

.imageContainer {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.content {
	display: flex;
	flex-direction: column;
}

.titleId {
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title {
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: var(--main-black);
}

.id {
	margin-left: 24px;

	font-family: $ubuntu-font;
	font-size: 12px;
	line-height: 17px;
	color: var(--main-blue-secondary);
}

.address {
	max-width: 526px;

	color: var(--main-grey) !important;
}

.priceContainer,
.priceDateContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.price {
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: var(--main-black);
}

.pricePerUnit {
	margin-left: 40px !important;

	font-family: $ubuntu-font;
	font-size: 16px;
	line-height: 22px;
	color: #878787;
}

.createdAt {
	font-family: $ubuntu-font;
	font-size: 12px;
	line-height: 16px;
	color: #afafaf;
}
