.title {
	font-weight: bold;
	font-size: 20px;
	line-height: 120%;

	margin-bottom: 12px;
}

.user {
	font-size: 16px;
	line-height: 16px;
	font-weight: normal;
	color: #336BEB;
}

.calls {
	:first-child {
		margin-top: 0px;
	}

	> div {
		margin-top: 8px;
	}
}
