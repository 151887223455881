@import "styles/vars.scss";

.root {
	margin-top: 60px;
	h1 {
		margin: 0;
		margin-bottom: 20px;
		color: #2D2C46;
		font-size: 20px;
		font-family: "Cera Pro";
		font-weight: 700;;
	}
}


.statistics {
	padding: 32px 58px 28px 32px;
}

.tooltip {
	display: flex;
	align-items: center;
}

.tooltipInfo {
	margin: 0;
}

.tooltipCard {
	padding: 12px 18px 16px 28px;
	max-width: 270px;
	background: #3F3F40!important;
}

.tooltipText {
	color: var(--accent-green);
	span {
		margin-left: 4px;
	}
}

.priceChangeContainer {
	font-size: 12px;
	font-weight: 400;
	.priceChangeHeader {
		opacity: 0.5;
	}
	.priceChangeInfo {
		margin-top: 12px;
	}
}

.disableStatistics {
	padding: 8px !important;
}

.editError {
	padding: 30px 58px 0px 32px;
}
