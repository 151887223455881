.container {
    margin-top: 20px;
    border-radius: 12px;
    background: #FFF;
}

.grid {
    display: grid;
    grid-template-columns: 50% 45% 1fr 1fr;
    color: #454545;
    background-color: #fff;
    border-radius: 16px;
}

.contentLeft {
    padding: 0 0 0 45px;
}

.contentLeft h3 {
    color: #2D2C46;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 25.2px */
    letter-spacing: 0.2px;
}

.contentLeft p {
    color: #2D2C46;
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.2px;
}

.proposal {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    border-radius: 67px;
    border: 1px solid #FF710B;
    margin-right: 20px;
    margin-top: 63px;
    margin-bottom: 33.5px;

    .titleProposal {
        color: #FF710B;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 16.8px */
        letter-spacing: 0.2px;
    }
}

.buttonContainer {
    float: right;
}

.contentRight {
    padding: 0 0 0 60px;
}

.contentRight h3 {
    color: #2D2C46;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 25.2px */
    letter-spacing: 0.2px;
}

.contentRight p {
    color: #2D2C46;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.2px;
}

.contentRight span {
    float: right;

    color: #7F8DEF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.2px;
}

.buttonContainer {
    margin-top: 95px;
    margin-bottom: 28px;
}

.buttonContainer p {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.2px;
}