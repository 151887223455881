.contentWrapperClassName {
	overflow-y: hidden !important;

	min-height: 660px !important;
}

.wrapperClassName {
	display: flex;
	flex-direction: column;

	height: 100%;

	overflow-y: auto;
}

.contentBody {
	overflow-y: auto;
}

.title {
	position: sticky;

	margin: 0;
	padding-left: 16px;

	font-size: 26px;
}
