.root {
  display: flex;
  flex-flow: column;
  gap: 10px;
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;

}

.column {
  display: flex;
  flex-flow: column;
}

.select {
  display: flex;
  align-items: center;
  max-width: 300px;
  justify-content: center;
  width: auto!important;
  min-width: 110px;
}

.infoRow {
  display: flex;
}

.row {
  display: flex;
  flex-flow: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.RadioButton {
  gap: 12px;
}


.totalPrice {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  margin-right: 100px;
}

.tariff {
  display: flex;
  font-family: Cera Pro;
  gap: 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #BFBFC0;
}


