@import "styles/vars.scss";

.root {
	display: inline-flex;
	align-items: center;
}

.name {
	font-family: $ubuntu-font;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: (--main-black);

	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.nameFontCera {
	font-family: $default-font;
}

.icon {
	margin-bottom: 4px;

	height: 16px;
	svg {
		width: auto;
		height: 20px;
	}
}

.avatar {
	margin-right: 12px;
}
