@import "styles/vars.scss";

.container {
	position: absolute;
	z-index: -1;
	display: flex;
	justify-content: space-between;
	width: 720px;
	background-color: #fff;
	border-radius: 0 20px 20px 20px;
	box-shadow: 0 10px 30px rgba(0, 64, 229, 0.25);
}

.dropdown {
	position: relative;
	z-index: 3;
	margin: 0 36px 0 40px;
}

.button {
	padding: 14px 21px 14px 20px;

	display: flex;
	align-items: center;

	font-family: $default-font;
	font-weight: 500;
	width: 159px;
	font-size: 14px;
	color: var(--main-black);

	background-color: #fff;
	border: none;
	border-radius: 20px;
	cursor: pointer;
}

.buttonActive {
	border-radius: 20px 20px 0 0;
}

.arrow {
	margin-left: 20px;
	transform: rotate(180deg);
}

.man {
	margin-right: 12px;
}
