@import "styles/vars.scss";

.root {
	display: inline-flex;
	align-items: center;
}

.name {
	font-family: $ubuntu-font;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);

	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.nameFontCera {
	font-family: $default-font;
}

.phone {
	font-size: 12px;
	line-height: 16px;
	color: var(--main-blue);
	opacity: 0.9;
}

.phoneFontUbuntu {
	font-family: $ubuntu-font;
}

.avatar {
	margin-right: 12px;
}
