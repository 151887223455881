.root div div div div {

  &:first-child {
    background: #F2F2F2 !important;
  }

  &:last-child {
    color: var(--BW-0, #1E1E1F)!important;
    font-size: 14px!important;
    font-style: normal!important;
    font-weight: 500!important;
    line-height: 20px!important; /* 142.857% */
    letter-spacing: 0.2px!important;
  }

  div {
    background: #336BEB !important;
  }
}