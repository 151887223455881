@import "styles/vars.scss";

.row {
	margin-top: 0 !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header {
	margin-bottom: 20px;
}

.hideHeader {
	display: none !important;
}

.title {
	color: #2D2C46;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 24px */
	letter-spacing: 0.2px;
	margin-right: 8px;
}

.type {
	color: rgba(45, 44, 70, 0.50);
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 24px */
	letter-spacing: 0.2px;
}