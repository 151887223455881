@import "styles/vars.scss";

.root {
	padding: 13px 0 17px 0;
}

.skeleton {
	margin-left: 6px;

	width: 60px;
	height: 18px;

	@include skeleton();
}

.selectActionsContainer {
	display: flex;
	align-items: center;
}

.total {
	color: var(--BW-0, #1E1E1F);
	font-family: "Cera Pro";
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 25.2px */
	letter-spacing: 0.2px;
}

.totalAmount {
	margin-left: 6px;

	font-weight: 400;
	font-size: 14px;
	color: var(--main-black);
	opacity: 0.5;
}

.objectManipulations {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 4px solid var(--main-blue);
	padding-bottom: 7px;
}

.objectDisplay {
	display: flex;
	column-gap: 17px;
}
