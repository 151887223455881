.container {
	margin-top: 60px;
}

.container h2 {
	color: #454545;
	font-size: 38px;
	font-style: normal;
	font-weight: 700;
	line-height: 46px;
	/* 121.053% */
}

.grid {
	display: grid;
	grid-template-columns: 50% 50% 1fr 1fr;
	color: #454545;
	background-color: #fff;
	border-radius: 16px;
}

.containerClientBlur {
	background-color: #fff;
	border-radius: 16px;
}

.contentLeft {
	padding: 0 0 0 45px;
}

.contentLeft h3 {
	color: #7F8DEF;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px;
	/* 125% */
}

.contentLeft p {
	color: #2D2C46;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	/* 19.6px */
	letter-spacing: 0.2px;
}

.contentRight {
	padding: 28px 0 28px 28px;
	border-radius: 24px;
}

.functionality {
	padding: 0 0 0 45px;
	background: #2D2C46;
	border-radius: 0 0 16px 16px;
}

.functionality h3 {
	color: #7F8DEF;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px;
	/* 125% */
}

.functionality p {
	color: #7F8DEF;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: 0.2px;
	padding: 0 45px 0 0;
}

.blockRow {
	display: grid;
	grid-template-columns: 33% 33% 33% 1fr 1fr 1fr;
	color: #454545;
	border-radius: 16px;
	min-width: 200px;
}

.containerRow {
	display: flex;
	flex-direction: column;
	margin-right: 25px;
	margin-bottom: 40px;
}

.containerRow p {
	color: #fff;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	/* 25.2px */
	letter-spacing: 0.2px;
	padding: 0;
}

.imgContent img {
	width: 336px;
	height: 170px;
	border-radius: 16px;
}

.headerFunctionality {
	display: flex;
	align-items: center;
}

.contentLeftSpan {
	color: #2D2C46;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	/* 25.2px */
	letter-spacing: 0.2px;
}

.link {
	display: flex;
	flex-direction: column;
	padding: 20px;
}

.contentLink span {
	margin-bottom: 42px;
	margin-left: 12px;
	margin-right: 49px;
}

.contentRight {
	display: flex;
	align-items: center;
	justify-content: center;
}

.imgContent {
	position: relative;
	width: 336px;
	height: 100%;
	overflow: hidden;
	border-radius: 16px;
}

.imgContent p {
	position: absolute;
	background: #7F8DEF;
	border-radius: 16px;
	padding: 6px 12px;
	color: #FFF;
	right: 10px;
	font-family: "Cera Pro";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	/* 19.6px */
	letter-spacing: 0.2px;
	z-index: 5;
}

.contentLink {
	display: flex;
	align-items: flex-start;
}

.footerInfo {
	display: flex;
	justify-content: space-between;
}

.moreInfo {
	display: flex;
	justify-content: right;
	background: #fff;
	padding: 11px 22px;
	// margin-left: 970px;
	border-radius: 10px 0px 0px 0px;
	cursor: pointer;

	span {
		color: #2D2C46;
		text-align: right;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 135%;
		/* 18.9px */
	}

	span,
	img {
		margin-right: 9px;
	}
}

.moreVideo {
	display: inline-flex;
	color: #fff;
}

.checkboxLabel {
	color: #fff;
	opacity: 0.5;
}

.importantInformation {
	display: flex;
	padding: 0 12px 0 12px;
	gap: 8px;
	border-radius: 8px;
	background: #F2F5FB;
	margin-bottom: 32px;

	p {
		color: #FF575F;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
		letter-spacing: 0.2px;
		flex: 1 0 0;
		display: flex;
		align-items: flex-start;
		align-self: baseline;
	}

	img {
		display: flex;
		align-items: flex-start;
		align-self: baseline;
		position: relative;
		top: 5px;
	}
}

.boxPlayer {
	position: relative;


	.videoPlayer {
		border-radius: 13px;
		overflow: hidden;
	}

	p {
		position: absolute;
		background: #FF575F;
		border-radius: 16px;
		padding: 6px 12px;
		color: #FFF;
		right: 10px;
		font-family: "Cera Pro";
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
		/* 19.6px */
		letter-spacing: 0.2px;
		z-index: 5;
	}

	.yellowSpoiler {
		background: #FFA857;
	}
}