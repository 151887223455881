@import "styles/vars.scss";

.showMore {
	margin: -7px 0 19px;

	display: flex;
	flex-direction: row;
	align-items: center;

	cursor: pointer;
}

.showMoreText {
	margin: 0 6px;

	font-family: $ubuntu-font;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: var(--main-blue);
}

.leftLineShowMore {
	height: 1px;
	width: 33px;

	background: #020202;
}

.rightLineShowMore {
	height: 1px;
	width: 12px;

	background: #e5e5e5;
}
