@import "styles/vars.scss";

.root {
	max-width: 720px;
}

.buildingBlock {
	color: var(--main-black);
}

.title {
	margin-top: 24px;
	margin-bottom: 5px;
	font-weight: 500;
	font-family: $ubuntu-font;
	font-size: 14px;
}
