.root {
	margin: 0;
	color: var(--main-black);
}

.title {
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
}

.count {
	margin-left: 4px;

	font-size: 16px;
	font-weight: 400;
	opacity: 50%;
}
