@import "styles/vars.scss";

.root {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 10px;
	padding: 6px 4px;
	background-color: #fff;
	border: 3px solid;
	border-radius: 10px;
	height: 72px;
}

.status {
	&__default {
		border-color: var(--accent-green);

		.requestIcon {
			margin-right: 12px;
			fill: var(--accent-green);
		}
	}

	&__accepted {
		display: none;
	}

	&__deleted {
		border-color: var(--main-grey-light);
	}

	&__warning {
		border-color: var(--accent-orange);

		.requestIcon {
			margin-right: 12px;
			fill: var(--accent-orange);
		}
	}

	&__hot {
		border-color: var(--accent-red);

		.requestIcon {
			margin-right: 12px;
			fill: var(--accent-red);
		}
	}
}

.requestBlock {
	display: flex;
	align-items: center;
	padding: 0 10px;
	border-right: 1px solid #f2f4fd;

	.requestSelect {
		min-width: 220px;
		max-width: 400px;
	}

	.requestSelectError {
		bottom: 6px;
	}

	.requestGroupSelectError {
		bottom: 9px;
	}

	&:last-child {
		border: none;
	}
}

.userBlock {
	flex-direction: column;
	align-items: baseline;
	justify-content: center;

	width: 220px;
}

.declined {
	margin-right: 50px;
}

.revert {
	@include button-no-style;
	margin-right: 12px;
	color: var(--main-blue);
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
}

.userName {
	font-weight: bold;
	font-size: 16px;
	line-height: 120%;

	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.userPhone {
	color: var(--main-grey-disabled);
	font-size: 12px;
	line-height: 140%;
}

.requestIcon {
	margin-left: 18px;
}

.days {
	margin-right: 14px;
	color: var(--main-grey-disabled);
	font-size: 14px;
	font-family: $ubuntu-font;
}

.decline {
	margin-left: 9px;
}
