.root {
	display: flex;
	flex-direction: column;
}

.title {
	color: var(--main-grey-disabled);
	font-size: 12px;
}

.value {
	font-size: 14px;
}
