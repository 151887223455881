.defferedTitle {
	font-size: 26px;
	line-height: 36px;

	span {
		font-weight: 700;
	}
}

.defferedReasonForm {
	margin-top: 24px;
	padding: 24px 24px 20px;

	background: var(--main-blue-disabled);
	border-radius: 10px;
}

.defferedReason {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.defferedReasonLabel {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: var(--main-black);
	}
}

.dateContiner {
	width: 250px;
}

.defferedSubmitContainer {
	margin-top: 20px;

	display: flex;
	justify-content: flex-end;
}
