@import "styles/vars.scss";

.root {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  min-height: 64px;
  display: flex;
  flex-flow: column;
  background: #fff;
  filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
}

.openTrigger {
  font-family: $ubuntu-font;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: var(--main-blue);
}

.popupArrow {
  fill: var(--main-blue) !important;
}

.actionItem {
  margin: 16px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  span {
    flex: 10 1;
    margin: 0;
    font-family: $ubuntu-font;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: var(--main-blue);
  }

  svg {
    margin-right: 12px;
    flex: 1 1;
    fill: var(--main-blue);
    opacity: 0.7;
    transition: 0.2s;
  }

  path {
    fill: var(--main-blue);
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.actionItem:first-child {
  margin-top: 0;
}

.loader {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  z-index: 100000;
  cursor: wait;
  background: black;
  opacity: 0.5;
}

.actionItem:last-child {
  margin-bottom: 0;
}

.arrows {
  width: 20px;
  height: 15px;
}

.plus {
  width: 12px;
  height: 12px;
}

.clip {
  width: 9px;
  height: 18px;
}

.poop {
  width: 15px;
  height: 19px;

  transform: scale(1.3);
}

.timer {
  width: 17px;
  height: 20px;
}

.finishLead {
  padding: 10px 22px !important;
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 32px;
  margin: 16px 0 10px 0;
  padding: 0 28px 0 28px;
}

.refuseButton {
  background-color: #FF575F !important;
}

.takeLoader {
  margin-left: 20px;
}

.leadTakenContainer button {
  cursor: not-allowed;
  background: var(--main-blue-secondary);
}

.freezeContainer {
  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: pointer;
}

.actionText {
  color: #336BEB;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
}