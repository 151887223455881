@import "styles/vars.scss";

.root {
  min-height: calc(100vh - 50px);
  display: flex;
  flex-flow: column;
  align-items: center;
}

.cardObjectHeader {
  background-color: var(--main-blue-disabled);
  width: 720px;
  border-radius: 10px;
  margin-top: 16px;
}

.block {
  margin-top: 60px;
}

.content {
  padding: 0 0 12px;
  width: 720px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.adDescription {
  margin-top: 60px;
}

.servicesTable {
  background-color: transparent;
}

.partnerAdHeader {
  padding: 8px 8px 0;
}

.partnersAdEditFooter {
  padding: 0 20px 24px 38px;
}

.statistics {
  margin-top: 30px;
}

/* Helpers styles */
.adContainer {
  & > div {
    &:first-child {
      display: flex;
      align-items: center;
    }

    &:last-child {
      font-family: $ubuntu-font;
      font-size: 14px;
      opacity: 0.5;
    }
  }
}

.adIcon {
  svg {
    margin-right: 4px;
  }
}

.tooltip {
  margin: 0 0 0 8px;
}

.adDate {
  margin-left: 28px;
}

.adWaiting {
  svg {
    fill: var(--accent-orange);
  }
}

.adError {
  svg {
    margin-right: 8px;
    width: 20px !important;
    height: 20px !important;

    path {
      fill: var(--accent-red);
    }
  }
}
