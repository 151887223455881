@import "styles/vars.scss";

.root {
	position: relative;

	padding: 20px 20px 20px 56px;

	border-radius: 10px !important;

	p {
		margin: 0 0 8px;

		font-family: $ubuntu-font;
		font-size: 16px;
		line-height: 22px;
		color: var(--main-grey-inactive);
	}
}

.copyIcon {
	position: absolute;
	top: 9.5px;
	left: 11px;

	width: 36px;
	height: 36px;
}

.userBlock {
	margin-bottom: 4px;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.createdAt,
.user {
	font-family: $ubuntu-font;
	line-height: 22px;
	color: var(--main-black);
}

.createdAt {
	margin-right: 20px;

	font-size: 14px;

	opacity: 0.5;
}

.user {
	font-size: 16px;
}

.position {
	margin-right: 16px;

	color: var(--main-grey-inactive);
}
