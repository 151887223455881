@import "styles/vars.scss";

.label {
	padding: 0 12px 4px 8px;

	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;

	border-radius: 0px 0px 10px 0px;
	background: var(--main-black);

	font-family: $ubuntu-font;
	font-weight: bold;
	font-size: 12px;
	line-height: 17px;
	color: #d5d5da;
}

.shadow {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;

	background: linear-gradient(180deg, #2d2c46 0%, rgba(45, 44, 70, 0) 75%);
}

.info {
	position: absolute;
	top: 32px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;

	width: 75%;

	font-family: $ubuntu-font;
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;
	color: #d5d5d9;
}
