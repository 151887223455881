.rootP {
	max-width: 360px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.linkBlock {
	margin: 8px 0;

	display: flex;
	align-items: center;
	justify-content: space-between;

	p {
		font-weight: 300 !important;
	}
}

.timeLeft {
	color: var(--accent-orange) !important;
}

.timeLeftBlock {
	width: 130px;
}

.actions {
	display: flex;
	align-items: center;

	svg {
		cursor: pointer;
		fill: var(--main-blue-secondary);
	}

	svg,
	path {
		transition: 0.2s;
	}

	.action {
		width: 20px;
		height: 20px;

		&:hover {
			path,
			svg {
				fill: var(--main-blue);
			}
		}
	}
}

.closeIcon {
	margin-left: 8px;

	width: 12px;
	height: 12px;
}
