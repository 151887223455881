@import "styles/vars.scss";

.infoRow {
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 10px 8px;

	align-items: flex-end;
}

.arrow {
	path {
		fill: var(--main-blue-secondary);
	}
}

.content {
	display: flex;
	align-items: center;
	column-gap: 8px;
}
