@import "styles/vars.scss";

.root {
	display: flex;
	align-items: center;
	position: relative;
	width: calc(100% + 34px);
	padding-left: 34px;
	margin-left: -34px;
	transition: 0.2s;

	&:hover {
		z-index: 1 !important;

		.icon {
			opacity: 1;
		}
	}
}

.icon {
	cursor: pointer;
	background-color: inherit;
	border: none;
	user-select: none;
	position: absolute;
	left: 0;
	padding: 0;
	display: flex;
	transition: 0.2s;
	opacity: 0;
}

.selected {
	opacity: 1 !important;
}

.unselected {
	width: 14px;
	height: 14px;
	border: 1px solid rgb(127, 141, 239, 1);
	border-radius: 100%;

	&:hover {
		border-width: 2px;
	}
}

.hoverIcon {
	opacity: 0;
	&:hover {
		opacity: 1;

		& + .itemWrapper {
			filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
		}
	}
}

.selectedRoot {
	padding-left: 56px;
	width: calc(100% + 56px);

	.icon {
		left: 22px;
	}
}

.itemWrapper {
	box-shadow: none;
	transition: 0.2s;
	width: 100%;
}
