.root {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.position__start {
	justify-content: flex-start;
}

.position__end {
	justify-content: flex-end;
}
