@import "styles/vars.scss";

.root {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.relatedAmount {
  padding-left: 8px;
  color: var(--main-grey-disabled);
}

.add {
  color: #336BEB;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
  cursor: pointer;
  gap: 4px;
  position: absolute;
  right: 28px;
  svg {
    fill: #7F8DEF;
  }
}

.title {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.header {
  margin-top: 24px;
  padding-bottom: 12px;
}

.labelText {
  margin-right: 8px !important;
}

.tableRoot {
  width: 440px;
}

.tooltipDescription {
  margin: 0 0 0 4px !important;
}

.tooltipCard {
  max-height: 260px;
  max-width: 360px !important;
  min-height: 40px;
  padding: 8px 8px 8px 36px !important;
  left: -65px!important;
  background: #3F3F40!important;

  div {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 13px;
    font-family: "Ubuntu", sans-serif;
  }

  svg {
    left: 8px !important;
    top: 8px !important;
  }
}