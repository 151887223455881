.root {
  margin-top: 60px;
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #2D2C46;
  margin-bottom: 12px;
}

.col {
  display: flex;
  flex-flow: column;
  gap: 12px;
}

.item {
  box-shadow: 0px 0px 30px 0px #2224281A;
  border-radius: 10px;
}