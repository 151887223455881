@import "styles/vars.scss";

.root {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	border-radius: 10px;
	height: 314px;
}

.dateAndTime {
	@include flex-align-items-center;
	flex-direction: column;

	margin-right: 28px;
}

.time {
	margin-top: 24px;

	& > div {
		&:first-child {
			background-color: #fff;
			min-width: 165px;
		}
	}
}
