.root {
	font-size: 20px;

	height: 24px;
	width: 100%;

	display: flex;
	align-items: center;

	margin: 0px 0px 16px 0px;

	svg {
		width: 24px;
		height: 24px;

		margin-right: 8px;
	}
}

.cancelIcon {
	path {
		fill: var(--main-black);
	}
}

.clockOutlineIcon {
	path {
		fill: var(--accent-orange);
	}
}

.clockTimerIcon {
	path:first-child {
		fill: var(--accent-green);
	}
}

.archiveIcon {
	path {
		fill: var(--main-black);
		opacity: 0.5;
	}
}
