.root {
	display: flex;
	flex-wrap: wrap;

	label {
		margin-right: 28px;
	}

	label:last-child {
		margin-right: 0;
	}
}
