@import "styles/vars.scss";

.label {
	position: relative;
	padding-left: 16px;

	white-space: nowrap;
}

.labelHovered {
	color: #fff;
}

.priceArrow {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.priceArrowUp {
	fill: var(--accent-green);
	transform: rotate(180deg) translateY(50%);
}

.priceArrowDown {
	fill: var(--accent-red);
}

.priceArrowNeutral {
	fill: #fff;
}

.fluctContainer {
	margin: 12px 0 12px 12px;
}

.fluct {
	margin: 4px 0;

	position: relative;

	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.priceArrow {
		left: -16px;
	}
}

.fluctPrice {
	font-family: $ubuntu-font;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;

	&.fluctPriceHigher {
		color: var(--accent-green);
	}
	&.fluctPriceLess {
		color: var(--accent-red);
	}
}

.fluctDate {
	margin-left: 12px;

	font-family: $ubuntu-font;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: var(--main-blue-secondary);
}
