.root {
	padding: 24px 18px 24px 30px;
	width: 500px;

	height: calc(100vh - 50px);
	overflow-y: overlay;

	background-color: var(--main-blue-inactive);
}

.header {
	margin: 0;
	color: var(--main-black);
}

.title {
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
}

.count {
	margin-left: 4px;

	font-size: 16px;
	font-weight: 400;
	opacity: 50%;
}

.list {
	display: flex;
	flex-direction: column;
	row-gap: 12px;

	margin-top: 20px;
}
