@import "styles/vars.scss";

.underlayer {
  width: 134px;
}

.root {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;

  height: 100%;
  width: 134px;

  @include flex-align-items-center;

  background: rgba(30, 30, 31, 0.95);
  backdrop-filter: blur(4px);
  overflow: hidden;

  transition: 0.3s;

  & > * {
    height: 100%;
  }

  &:hover {
    width: 500px;
    background: rgba(30, 30, 31, 0.95);
    backdrop-filter: blur(4px);

    .subTitle {
      opacity: 0.5;
    }

    .right {
      display: flex;
    }
  }
}

.left {
  padding: 12px;

  display: flex;
  flex-direction: column;

  width: 100%;

  backdrop-filter: blur(3px);
  opacity: 0.95;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  @include flex-align-items-center;
  color: white;
}

.title {
  font-size: 20px;
  margin-right: 15px;
}

.subTitle {
  opacity: 0;
  font-size: 14px;
  white-space: nowrap;
}

.right {
  padding: 7px 0 30px;

  width: 26px;

  display: none;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;

  backdrop-filter: blur(3px);

  &:hover {
    background: linear-gradient(
                    270deg,
                    rgba(var(--main-blue-rgb), 0.6) 0%,
                    rgba(var(--main-blue-rgb), 0) 100%
    );

    span {
      color: var(--main-blue);
    }

    svg {
      fill: var(--main-blue);
    }
  }
}

.button {
  @include flex-align-items-center;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;
  transform: rotate(90deg);

  color: white;
  font-size: 16px;

  svg {
    fill: white;
    transform: rotate(180deg);
  }

  span {
    white-space: nowrap;
  }
}

.childrenContainer {
  margin: 12px 0;
  padding-top: 10px;
  height: 100%;
  max-width: 450px;

  overflow-y: auto;

  border: 1px dashed #fff;
  border-radius: 10px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.dateBlock {
  position: relative;

  .divider {
    margin: 21.5px 20px;

    width: calc(100% - 40px);
    height: 1px;

    background: #9596a5;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 44px;
    padding: 0 8px;

    white-space: nowrap;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-blue-disabled);

    &:after,
    &:before {
      content: "";

      width: 100%;
      height: 1px;

      background: #9596a5;
    }

    &:before {
      margin-right: 8px;
    }

    &:after {
      margin-left: 8px;
    }
  }
}
