@import "styles/vars.scss";

.root {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	transform: translateX(-20px);

	&:hover {
		.delete {
			opacity: 1;
		}
	}
}

.position {
	padding: 10px 18px;

	cursor: pointer;

	word-break: break-word;
	font-family: $ubuntu-font;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	color: var(--main-black);
	opacity: 0.8;
}

.selectedPosition {
	background: var(--main-blue-inactive);
	border-radius: 10px;
	opacity: 1;
}

.delete {
	margin-right: 8px;
	opacity: 0;

	path {
		transition: 0.2s;
	}

	svg {
		display: flex;
		fill: var(--main-grey-disabled);
		cursor: pointer;

		&:hover {
			path {
				fill: var(--accent-red);
			}
		}
	}
}
