@import "styles/vars.scss";

.root {
	color: var(--main-black);
}

.type {
	font-weight: 700;
}

.direction {
	color: var(--main-black);
	opacity: 0.7;
	font-size: 14px;
	font-family: $ubuntu-font;
}
