@import "styles/vars.scss";

.buttons {
	@include flex-align-items-center();
	margin-left: auto;

	& > button {
		&:last-child {
			margin-left: 44px;
		}
	}
}

.platform {
	padding: 7px 16px;
	font-size: 12px;
	line-height: 16px;
	font-weight: 700;
	max-width: 161px;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-word;
	white-space: nowrap;
	border-radius: 100px;
	background-color: var(--main-blue-inactive);
}

.status {
	font-size: 14px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.5);
}

.statusError {
	@include flex-align-items-center();
	color: var(--accent-red);
	opacity: 1;
	svg {
		width: 16px;
		height: 16px;
		path {
			fill: var(--accent-red);
		}
	}
}

.errorTooltip {
	margin: 0 0 0 8px !important;
}

.errorTooltipCard {
	div {
		font-weight: 400;
		font-size: 12px;
		font-family: $ubuntu-font;
	}
}

.time {
	@include flex-align-items-center();
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;

	svg {
		width: 32px;
		height: 32px;
		margin-right: 4px;
	}
}

.tableWrapper {
	display: flex;
	flex-direction: column;
}

.table {
	table {
		table-layout: fixed;
		color: var(--main-black);
		thead {
			th {
				font-weight: 700;
				font-size: 14px;
				opacity: 0.5;
			}
		}
		tbody {
			tr {
				border-bottom: 1px solid var(--main-grey-light);
				td {
					span {
						font-size: 14px;
					}
					padding-top: 19px !important;
					padding-bottom: 19px;
					&:nth-child(n + 2) {
						font-family: $ubuntu-font;
					}
				}
			}
		}
	}
}

.errorTooltip {
	svg {
		margin-left: 0;
	}
}

.errorCard {
	padding: 0 !important;
	& > div {
		padding: 12px 26px;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}
}

.buttonsAction {
	@include flex-align-items-center;
	justify-content: flex-end;

	margin-top: 8px;

	& > button {
		&:last-child {
			margin-left: 24px;
		}
	}
}

.errorContainer {
	display: flex;
	align-items: center;
	color: var(--accent-red);

	svg * {
		fill: var(--accent-red) !important;
	}
}
