@import "styles/vars.scss";

.root {
	margin-bottom: 28px;
}

.skeleton {
	display: flex;
	flex-direction: column;
}

.skeletonBig,
.skeletonSmall {
	@include skeleton();
}

.skeletonBig {
	margin-bottom: 4px;

	height: 36px;
	width: 150px;
}

.skeletonSmall {
	margin-bottom: 60px;

	height: 24px;
	width: 200px;
}
