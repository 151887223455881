
.wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.wrap h1 {
	margin-bottom: 8px;
}
.errorMessage {
	width:100%;
	margin: 4px 0 0;
	text-align: right;;
	font-family: Ubuntu;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 125% */
	letter-spacing: 0.2px;
	color:#FF575F;
}
.message {
	color:#2D2C46;
	font-family: Ubuntu;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 125% */
	letter-spacing: 0.2px;
	margin-bottom: 60px;
}