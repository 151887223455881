.rateContainer {
	display: inline-flex;
	flex-direction: row;

	path {
		fill: var(--main-black);
		opacity: 0.3;
	}

	.rate {
		padding: 0 1px;

		cursor: pointer;
	}
	.rate:first-child {
		padding-right: 1px;
		padding-left: 0;
	}
	.rate:last-child {
		padding-right: 0;
		padding-left: 1px;
	}
}

.rateFilled {
	path {
		fill: var(--accent-yellow);
		opacity: 1;
	}
}
