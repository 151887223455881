@import "styles/vars.scss";

.nav,
.pinnedRealEstate,
.stages,
.header,
.blockItem {
	@include skeleton();
}

.blocks {
	.block:first-child {
		.header {
			margin-top: 30px !important;
		}
	}
}

.root {
	margin-bottom: 80px;
}

.nav {
	width: 100%;
	height: 49px;

	border-radius: 0;
}

.pinnedRealEstate {
	margin-top: 1px;

	width: 100%;
	height: 92px;

	border-radius: 0;
}

.stages {
	margin: 40px auto 0;

	width: calc(100% - 80px);
	height: 320px;
}

.block {
	padding: 0 40px;

	.header {
		margin-top: 80px;
		margin-bottom: 12px;

		width: 100%;
		height: 32px;
	}

	.blockItem {
		width: 100%;
		height: 80px;
	}
}
