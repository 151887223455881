.root {
	display: flex;
	flex-direction: column;
	row-gap: 12px;

	margin-top: 20px;
}

.card {
	cursor: pointer;

	:hover {
		transition: filter 0.2s;
		filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
	}
}