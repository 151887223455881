@import "styles/vars.scss";

.contactItem {
	position: relative;

	margin-bottom: 8px;
}

.contactInfo {
	padding-left: 39px;
}

.contactImg {
	position: absolute;
	left: -3px;
	top: 0;

	width: 35px;
	height: 35px;

	background: #c4c4c4;
	border-radius: 50%;
}

.contactImgMain {
	position: absolute;
	bottom: -5px;
	right: 0;

	width: 14px;
	height: 14px;

	background: var(--accent-yellow);
	border-radius: 50%;

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		width: 10px;
		height: 10px;
	}

	path {
		opacity: 1;
		fill: #fff;
	}
}

.contactName {
	font-weight: bold;
	font-size: 14px;
	line-height: 17px;
	color: var(--main-black);
}

.contactPhone {
	font-family: $ubuntu-font;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
	color: #000000;
	opacity: 0.5;
}

.loading {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.avatarLoading {
	margin-right: 4px;
	width: 35px;
	height: 35px;

	border-radius: 50%;
	background: var(--main-blue-inactive);
}

.textLoading {
	width: calc(100% - 39px);
	height: 33px;

	border-radius: 5px;
	background: var(--main-blue-inactive);
}

.contactPhoneInfo {
	@include flex-align-items-center;
}

.tooltip {
	margin: 0 4px 0 0 !important;

	@include flex-align-items-center;
}

.tooltipCard,
.tooltipIcon {
	flex-direction: row !important;
	@include flex-align-items-center;

	svg {
		path {
			fill: var(--accent-red);
		}
	}
}

.tooltipContent {
	margin-left: 9px;
}
