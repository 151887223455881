.rowEnd {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;
  margin-top: 32px;
}

.buttonCreate {
  margin-left: 12px;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.column {
  display: flex;
  flex-flow: column;
  gap: 4px;
  margin-bottom: 32px;
  margin-top: 4px;
}

.title {
  color: #2D2C46;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.2px;
}

.text {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.2px;
}

.infoRow {
  padding: 0 10px 4px;

  min-height: 0;
}

.calendarChild {
  & > div {
    &:last-child {
      margin-left: -46px;
    }
  }
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendarLabel {
  flex-direction: row-reverse;

  svg {
    margin-right: 0 !important;
    margin-left: 20px;

    path {
      fill: var(--main-blue-secondary);
    }
  }
}

.calendar {
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.popupTrigger {
  padding: 12px 24px !important;
}

.timeRoot {
  margin-left: 21px;
}

.timeWrapper {
  border: none;
  padding: 0;
}