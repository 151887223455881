@import "styles/vars.scss";
@import "styles/keyframes";

.root {
	cursor: pointer;

	position: fixed;
	bottom: 40px;
	left: 280px;

	min-width: 44px;
	max-width: 44px;

	min-height: 44px;
	max-height: 44px;

	visibility: hidden;
	transform: scale(0);

	transition: all 0.3s;
	svg {
		width: 100% !important;
		height: 100% !important;
		path {
			transition: fill 0.2s;

			width: 100% !important;
			height: 100% !important;
		}
	}

	@include mdDesktopBreakpoint() {
		left: 110px;
	}
}

.root:hover {
	svg {
		path:last-child {
			transition: fill 0.2s;
			fill: var(--main-blue);
		}
	}
}

.show {
	visibility: visible;
	transform: scale(1);

	transition: all 0.3s;
}
