@import "styles/vars.scss";

.root {
	display: flex;
	flex-direction: column;
	row-gap: 12px;
}

.objectWrapper {
	display: grid;
	grid-template-columns: 1fr;
	gap: 12px 0px;
}

.titleCount {
	color: var(--main-grey-disabled);
}

.more {
	display: flex;
	justify-content: flex-end;
	padding: 17px 0px;
}

.title {
	margin: 0px !important;
}

.btn {
	width: 97px;
	padding: 0px !important;

	background: white !important;

	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 20px !important;
	color: var(--main-blue) !important;
	text-decoration: none !important;

	border: none !important;
	border-radius: 0px !important;
	border-bottom: 1px dotted var(--main-blue) !important;
}

.notObject {
	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--main-blue-disabled);
	border-radius: 10px;

	padding: 17px;
}

.buttons {
	@include flex-align-items-center;
	justify-content: flex-end;

	margin-top: 8px;

	& > button {
		&:last-child {
			margin-left: 24px;
		}
	}
}
