@import "styles/vars.scss";

.h3 h3 {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.h3 {
	margin-bottom: 0px;
}

.editModeH3 h3 {
	width: 100%;
	justify-content: space-between;
}

.edit {
	margin-left: 16px;
	padding: 4px;

	cursor: pointer;

	display: flex;
}

.viewModeTitle {
	margin-bottom: 4px;

	font-family: $ubuntu-font;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-grey);
}

.viewModeDescription {
	margin-bottom: 8px;

	font-family: $ubuntu-font;
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
	color: var(--main-grey);
	ol,
	ul,
	p {
		margin: 0;
	}
	ol,
	ul {
		padding-left: 15px;
	}
}

.commentPlaceholder {
	color: var(--main-grey);
	opacity: 0.5;
}

.viewModeSignature {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);
}

.videoPreNode {
	width: 200px;
}

.inputWrapper {
	margin-bottom: 8px;
}

.viewModeVideo {
	font-size: 14px;
	font-family: $ubuntu-font;
	display: flex;
	align-items: center;
	span {
		min-width: 118px;
	}
	a {
		max-width: 380px;
		margin-left: 13px;
		overflow: hidden;
		text-overflow: ellipsis;
		color: var(--main-blue);
	}
}

.root {
	display: flex;
	flex-direction: column;
	row-gap: 8px;
}

.textarea {
	border: 1px solid var(--main-grey-light);
}

.inputWrapper {
	z-index: 10;
	& div[class^="TransparentInput"] {
		padding: 4px 16px;
		border: 1px solid var(--main-grey-light);
		border-radius: 10px;
	}
}

.selectWrapper {
	position: relative;

	padding: 4px 13px 0;

	max-width: 260px;

	border: 1px solid var(--main-grey-light);
	border-radius: 10px;
}

.selectBorder {
	position: absolute;
	top: 8px;
	left: 0;
	right: 0;
	bottom: 0;

	border: 1px solid var(--main-grey-light);
	border-radius: 10px;
}

.input {
	input {
		font-family: $ubuntu-font;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		color: var(--main-grey);
	}
}

.videoInputWrapper {
	display: flex;
	align-items: center;
}

.inputError {
	border-color: var(--accent-red) !important;
}
