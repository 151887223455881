@import "styles/vars.scss";

.name {
	height: 100px;
	background-color: #fff;
}

.row {
	padding: 8px 20px 4px;
	height: 100px;
	margin-bottom: 12px;
	flex-direction: row;
	display: flex;
	// align-items: center;

	background: #fff;
	border-radius: 10px;

	cursor: pointer;
	transition: box-shadow 0.25s ease;

	&:hover {
		box-shadow: 0 10px 30px rgba(0, 64, 229, 0.3);

		.hidden {
			opacity: 1;
		}
	}
}

.activeRow {
	padding: 10px 18px;
	border: 2px solid var(--main-blue);
}

.column {
	width: 100%;
	flex: 3;
	display: flex;
	flex-direction: column;
	justify-content: space-between
}

.hidden {
	transition: opacity 0.25s ease;
	opacity: 0;
}

.groupTitle {
	margin: 39px 0 16px;

	font-weight: bold;
	font-size: 26px;
	line-height: 20px;

	color: var(--main-black);
}

.groupTitleFirst {
	margin: 0 0 16px;
}

.rowLoading {
	@include skeleton();

	margin-bottom: 12px;

	width: 100%;
	height: 62px;
}

.tableBadge {
	top: -7px;
	left: unset;
	right: -7px;
	transform: unset;
}

.blinkRow {
	animation: blinkShadow 0.7s linear;
}

