.root {
	margin-bottom: 40px;
}

.stageCard {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 200px;
	display: flex;
	flex-direction: row-reverse;
	border-radius: 20px;

	&.firstStageCard {
		border-radius: 20px 20px 20px 0;
	}
	&.lastStageCard {
		border-radius: 20px 20px 0 20px;
	}

	&.stageCardGrey {
		background: var(--stage-banner-grey);
	}
}

.arrowRight,
.arrowLeft {
	position: absolute;
	z-index: 100;
	top: 50%;
	transform: translateY(-50%);
	width: 44px;
	height: 44px;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 50%;

	cursor: pointer;

	svg {
		position: absolute;
		top: 50%;

		fill: #fff;
	}
}

.arrowLeft {
	left: -20px;

	svg {
		right: 2px;

		transform: translateY(-50%) rotate(90deg);
	}
}

.arrowRight {
	right: -20px;

	svg {
		left: 2px;
		transform: translateY(-50%) rotate(-90deg);
	}
}

.currentTitle {
	padding: 32px 0 39px 44px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	width: 50%;

	span {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #fff;
	}

	p {
		margin: 4px 0 0 0;
		font-family: "Cera Pro";
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 28px; /* 140% */
		letter-spacing: 0.2px;
		color: #fff;
	}
}

.buttons {
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;

	> button {
		height: 35px!important;
		width: 168px!important;
		justify-content: center;
		color: var(--Primary-Basic, #336BEB);

		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 120%;
		letter-spacing: 0.2px;

		+ button {
			margin-top: 12px;
		}
	}
}

.image {
	width: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background: radial-gradient(circle, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 60%);

	img {
		height: 100%;
	}

	.imageBlackAndWhite {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
	}
}

.stagesContainer {
	display: flex;
	flex-direction: row;
}

.stageItem {
	position: relative;
	padding: 4px 16px 8px;
	width: 25%;
	cursor: pointer;

	span {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: var(--main-blue);
		opacity: 0.5;
	}

	p {
		margin: 0;

		font-weight: 500;
		font-size: 10px;
		line-height: 12px;
		color: var(--main-black);
		opacity: 0.5;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&.stageItemPrev {
		p {
			opacity: 0.3;
		}

		span {
			color: var(---main-black);
			opacity: 0.3;
		}
	}

	&.stageItemNext {
		p {
			opacity: 1;
			color: var(---main-black);
		}

		span {
			color: var(--main-blue);
			opacity: 1;
		}
	}
}

.stageItemBlur {
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	cursor: not-allowed;
	background: rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(3px);
}

.stageItemCurrent {
	border-radius: 0 0 10px 10px;

	span,
	p {
		color: #fff;
		opacity: 1;
	}

	&.stageItemGrey {
		background: var(--stage-banner-grey);
	}
}

.finishedStageLabel {
	display: flex;
	align-items: flex-end;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;
	min-height: 32px !important;
	width: 100%;
}
