.root {
	margin-top: 80px;

	width: 100%;
}

.rowsBlock {
	h3 {
		margin: 24px 0 8px;

		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: var(--main-black);

		opacity: 0.7;
	}
}
