.root {
  width: 720px;
  height: 100px;
  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);
  border-radius: 10px;

  //:hover {
  //  box-shadow: 0 10px 30px rgba(0, 64, 230, 0.3);
  //}
}

.image {
  margin-right: 16px;
}

.selected {
  border: 2px solid #336BEB;
  width: 720px!important;
  height: 100px!important;
  img {
    width: 102px!important;
    height: 96px!important;
    border-radius: 10px 0px 0px 10px;
  }
  .logo {
    width: 102px!important;
    height: 96px!important;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  width: 106px;
  height: 100px;
  border-radius: 10px
}

.content {
  display: flex;
  flex-flow: row;
  position: relative;
  border-radius: 10px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;

  img {
    width: 106px;
    height: 100px;
    border-radius: 10px 0px 0px 10px;
  }
}

.title {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;

}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.col {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}