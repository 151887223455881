@import "styles/vars.scss";

.root {
	padding-left: $sidebar-closed-width;
	display: flex;
	flex-direction: column;
}

.expanded.root {
	padding-left: $sidebar-opened-width;
}

.firstLine {
	display: flex;

	height: 50px;

	background: #fff;
	border-bottom: 1px solid var(--main-blue-inactive);
}

.secondLine {
	display: flex;
}

.filterWrapper {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-right: 20px;
	padding-left: 17px;
	border-right: 1px solid var(--main-blue-inactive);

	&:last-child {
		border-right: none;
	}
}

.filterBtn {
	color: var(--main-black);
	display: flex;
	align-items: center;

	svg {
		width: 25px !important;
		height: 25px !important;
		margin-right: 14px !important;
	}

	path {
		fill: var(--main-black) !important;
	}

	.filterBtnText {
		color: var(--main-blue);
		font-size: 12px;
	}
}

.submitWrapper {
	position: absolute;
	right: 420px;
	bottom: -30px;
}

.searchWrapper {
	padding-left: 0px;
	width: 216px;
}

.typeWrapper {
	width: 194px;
}

.responsibleWrapper {
	width: 214px;
}

.signedWrapper,
.statusWrapper {
	width: 274px;
}

.filtersWrapper {
	display: flex;
	height: 30px;
}

.typeSelect {
	width: 100%;
}

.more {
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: auto;
		height: auto;
	}
}

.moreWrapper {
	width: 176px !important;
	height: 30px !important;

	border: none !important;
	border-radius: 0 !important;

	font-family: $ubuntu-font !important;
	font-size: 12px !important;
	font-weight: 400 !important;
	background: #fff !important;

	border-right: 1px solid var(--main-blue-inactive) !important;
	border-bottom: 1px solid var(--main-blue-inactive) !important;

	svg {
		width: 20px !important;
		height: 20px !important;
	}
}

.filterBtnText {
	color: var(--main-blue);
	font-size: 12px;
}

.resetBtn {
	height: 30px;

	background: white !important;
	border-radius: 0px !important;
	padding: 0px 24px !important;

	border-bottom: 1px solid var(--main-blue-inactive) !important;
	border-right: 1px solid var(--main-blue-inactive) !important;

	span {
		opacity: 0.3;
	}
}

.cancelIcon {
	width: 36px !important;
	height: 36px !important;

	path {
		fill: var(--main-blue) !important;
	}
}

.resetBtn:hover {
	color: var(--accent-red) !important;

	span {
		opacity: 1;
	}

	.cancelIcon {
		path {
			fill: var(--accent-red) !important;
		}
	}
}
