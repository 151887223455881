.search {
	padding-right: 30px;
}

.listContainer {
	padding: 12px 8px 20px 0;

	max-height: 384px;

	border-radius: 10px;
	background: var(--main-blue-disabled);
}
