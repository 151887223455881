.root {
	position: relative;
	display: flex;
	flex-direction: row;
}

.leftBlock {
	position: relative;
	min-width: calc(100% - 805px);
}

.rightBlock {
	position: relative;
	min-width: 800px;
	flex: 1;
}

.rightBlockContent {
	position: relative;
	width: 100%;
	max-height: 100vh;
	overflow-y: scroll;
	z-index: 100;
	background: #fff;
}

.rightBlockMinHeight {
	max-height: calc(100vh - 50px);
}

.goBack {
	position: absolute;
	top: 8px;
	right: 6px;
	z-index: 1000;
}

.buttonMap {
	position: absolute;
	bottom: 10px;
	right: 6px;
	z-index: 1000;
}
