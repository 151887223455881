@import "styles/vars.scss";

.root {
  margin: 35px 0 25px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
  border-bottom: 5px solid var(--main-blue);
}

.filters {
  display: flex;
  align-items: center;
}

.filtersText {
  margin: 0;
  margin-left: 60px;
  color: rgba($color: var(--main-black), $alpha: 0.3);
  font-size: 12px;
  line-height: 17px;
}

.filtersLabel {
  color: rgba($color: var(--main-black), $alpha: 0.5);
}
