@import "styles/vars.scss";

.root {
	position: relative;
	width: 92px;
	height: 92px;
}

.circle {
	width: 100%;
	height: 100%;
}

.circle {
	svg {
		width: 92px;
		height: 92px;
		transform: rotate(-90deg);
	}

	.frontCircle {
		stroke-dasharray: 258;
		stroke-linecap: round;
		stroke-width: 8px;
		fill: none;
	}

	.backCircle {
		stroke-dasharray: 258;
		stroke-dashoffset: 0;
		stroke-linecap: round;
		stroke-width: 8px;
		stroke: var(--main-blue-inactive);
		fill: none;
	}
}

.circle.negative .frontCircle {
	stroke: var(--accent-red);
}
.circle.middle .frontCircle {
	stroke: var(--accent-orange);
}

.text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	font-family: $ubuntu-font;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-blue);

	span {
		font-size: 20px;
	}

	&.negativePercent {
		color: var(--accent-red);
	}
	&.middlePercent {
		color: var(--accent-orange);
	}
	&.neutralPercent {
		color: var(--main-blue-inactive);
	}
}
