.root {

}

.content {
  display: flex;
  flex-flow: column;
  padding: 4px 8px 78px 8px;
}

.description {
  color: var(--, #2D2C46);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.descriptionNotFull {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
}

.seeMore {
  display: flex;
  justify-content: end;
  align-items: center;
  color: #336BEB;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  gap: 4px;

  svg {
    fill: #7F8DEF;
  }
}

.textarea {
  padding: 8px 16px;

  font-weight: 300;
  border-color: var(--main-grey-light);
}

.info {

}

.footerShow {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
  color: #68687C;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.2px;
}

.footerDefault {
  display: flex;
  justify-content: end;
  gap: 32px;
}

.refuse {
  display: flex;
  justify-content: end;
  gap: 4px;
  align-items: center;
  color: #FF575F;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
  cursor: pointer;

  svg {
    fill: #FF575F;
  }
}

.rowAction {
  display: flex;
  flex-flow: row;
  justify-content: end;
  align-items: center;
  height: 64px;
  width: 800px;
  border-radius: 0 0 20px 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  box-shadow: (0px 10px 30px rgba(0, 64, 229, 0.25));
  z-index: 1;
  padding-right: 40px;
  padding-left: 40px;
}

.infoRow {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 10px 8px;
  align-items: flex-end;
}

.linkBlock {
  margin-top: 21px;
  border-top: 1px solid #E5E5E5;
  display: flex;
  gap: 12px;

  .item {
    margin-top: 24px;
    width: 161px;

    .linkLabel {
      color: #CCC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.2px;
      margin-bottom: 4px;
    }

    .link {
      color: #336BEB;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.2px;
    }
  }
}

.application {
  width: 720px;
  position: relative;
  height: 100px;
  padding: 7px 26px 1px 20px;
  background: #F2F5FB;
  border-radius: 10px;
}

.lead {
  width: 720px;
  position: relative;
  height: 100px;
  background: #F2F5FB;
  border-radius: 10px;
}

.name {
  display: flex;
  align-items: center;
  color: #2D2C46;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 31.2px */
  letter-spacing: 0.2px;
  gap: 8px;
  margin-bottom: 32px;
}

.title {
  margin-top: 32px;
  margin-bottom: 8px;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.2px;
}


.calendarChild {
  & > div {
    &:last-child {
      margin-left: -46px;
    }
  }
}

.flexRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.calendarLabel {
  flex-direction: row-reverse;

  svg {
    margin-right: 0 !important;
    margin-left: 20px;

    path {
      fill: var(--main-blue-secondary);
    }
  }
}

.calendar {
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;

}

.popupTrigger {
  padding: 12px 24px !important;
}

.timeRoot {
  margin-left: 21px;
}

.timeWrapper {
  border: none;
  padding: 0;
}
