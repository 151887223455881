.formContainer {
	margin: 23px 0 70px;
	max-width: 350px;
}

.buttonsContainer {
	display: flex;
	align-items: center;
}

.button {
	margin-right: 48px;

	min-width: 222px;

	display: flex;
	justify-content: center;
}

.link {
	color: var(--main-blue);
	font-size: 16px;
	line-height: 20px;
	text-decoration: none;
}
