@import "styles/vars.scss";

.openTrigger {
	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	color: var(--main-blue);
}

.popupArrow {
	fill: var(--main-blue) !important;
}

.list {
	margin: 0;
	padding: 0;
}

.item {
	cursor: pointer;
	display: flex;
	align-items: center;
	column-gap: 10px;

	color: var(--main-blue);

	svg {
		opacity: 0.5;
		fill: var(--main-blue);
	}

	&:hover {
		svg {
			opacity: 1;
		}
	}
}
