.root {
	padding: 40px 75px 35px 54px;
}

.comments,
.comment {
	margin-top: 60px;
}

.button {
	margin-top: 26px;
}

.comments {
	& > div {
		margin-top: 60px;
		&:first-child {
			margin-top: 0;
		}
	}
}

.header {
	h2 {
		margin: 0 0 16px;

		font-weight: 500;
		font-size: 24px;
	}
}
