.expenses {
	color: var(--main-black);

	& > div {
		margin-top: 20px;

		font-weight: 700;
		font-size: 36px;
	}
}

.adPrice {
	margin-top: 20px;

	max-width: 280px;
}
