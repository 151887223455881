.root {
	position: relative;
}

.radioWrapper {
	position: relative;
	z-index: 2;
}

.hideLine {
	position: absolute;
	top: 50%;
	left: -12px;
	transform: translateY(-50%);
	z-index: 1;

	width: calc(100% + 12px);
	height: 45px;
	background: var(--main-blue-disabled);
}

.loading {
	width: 100%;
	height: 28px;

	border-radius: 100px;
	background: #e3e9f7;
}
