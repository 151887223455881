.root {
  position: sticky;
  width: 800px;
  bottom: 0;
  background: white;
  display: flex;
  padding: 12px 40px;
  align-items: center;
  justify-content: end;
  gap: 26px;
  box-shadow: 0px 0px 30px 0px #2224281A;
}


.trigger {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  color: #336BEB;

  svg {
    path {
      fill: #336BEB;
    }
  }
}
.actions {
  width: 143px;
}
.popupCard {
  width: 300px;
  bottom: calc(100% + 40px)!important;
}

.actionItem {
  margin: 16px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  gap: 4px;

  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #336BEB;
}