.root {
	margin-top: 24px;
	margin-right: 48px;

	width: 90px;
	height: 90px;

	display: flex;
	justify-content: center;
}

.warningIcon {
	width: 90px;
	height: 80px;

	path {
		fill: var(--accent-red);
	}
}

.trashIcon {
	width: 53px;
	height: 68px;
}

.plusIconRoot {
	width: 90px;
	height: 90px;

	background: #bfc4df;
	border-radius: 100px;

	display: flex;
	align-items: center;
	justify-content: center;

	.plus {
		width: 44px;
		height: 44px;
		fill: white;
	}
}
