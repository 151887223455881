.container h2 {
	color:#454545;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
    margin-top: 130px;
}

.grid {
	display: grid;
	grid-template-columns: 550px 550px;
	grid-gap: 20px;
	color: #454545;
}

.contentLeft {
	padding: 0 0 0 45px;
}

.containerRead {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 12px;
}

.leftRead {
    width: 120px;
    height: 147px;
    flex-shrink: 0;
    border-radius: 12px 4px 4px 12px;
    background: #124BF6;
    margin-right: 12px;
}

.leftRead img {
    position: relative;
    padding: 30px 15px 30px 15px;
    border-radius: 40px;
}

.rightRead {
    margin-top: 8px;
}

.rightRead .labelRead {
    color:#2D2C46;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.2px;
}

.rightRead p {
    color: #ADADAD;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.2px;
}

.btnContainer {
    display: flex;
}

.buttonContainer {
    margin-right: 16px;
}