.root {
	max-width: 390px;
}


.root p{
	margin: 0;
	font-weight: 400;
}
.root h1{
	margin: 0;
	font-weight: 700;
}
.fieldsContainer {
	margin-bottom: 60px;
	padding-top: 40px;
}

.blockedLink {
	position: relative;
	padding: 24px 44px 24px 30px;

	background: #f5f5f5;
	border-radius: 10px;

	font-size: 14px;
	font-family: "Ubuntu", sans-serif;
	width: 446px;
}

.blockedLinkIcon {
	position: absolute;
	top: 4px;
	right: 4px;
}

.submitContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	button {
		margin-right: 10px;
		margin-bottom: 16px;
	}
}

.loginLinkContainer {
	font-family: "Ubuntu", sans-serif;
}

.link {
	color: #FF575F;
	font-family: Ubuntu;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.2px;
	border: none;
}
.passwordRulesContainer {
	margin:0;
	padding:0;
	padding-top:12px;
	font-family: Ubuntu;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 14.4px */
	letter-spacing: 0.2px;
}
.passwordRule  {
	margin-bottom:8px;
}
.green {
	color: #49D7BD;
}
.gray {
	color: #9595A3;
}
.error {
	color:red;
}
.passwordRow div input::placeholder{
	letter-spacing: normal;
}
.passwordRow div div div svg {
	display: none;
}
.passwordIcon {
	position: absolute;
	cursor: pointer;
	right: 15px;
	top: 15px;
}