.content {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    width: 100%;
    margin: 0 0 20px;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
    text-align: left;
  }
}
