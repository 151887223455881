@import "styles/vars.scss";

.root {
	position: absolute;
	right: 7px;
	top: 13px;
	z-index: 2;
}

.rootActive {
	.iconToggle {
		border-radius: 20px 20px 0 0;
	}
}

.icon {
	fill: var(--main-grey-inactive);
}

.content {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.iconToggle {
	@include button-no-style();

	padding: 9px;

	width: 36px;
	height: 36px;

	background-color: #fff;
	border-radius: 100%;
	cursor: pointer;
	z-index: 11;
}

.dropdown {
	padding: 10px 0;

	width: 255px;
	max-height: 345px;
	height: 100%;

	background-color: #fff;
	border-radius: 20px 0 20px 20px;
	box-shadow: $default-box-shadow;
}
