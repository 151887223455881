.root {

}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
margin-bottom: 60px;
}

.footer {
  margin-top: 60px;
  width: 800px;
  height: 64px;
display: flex;
  align-items: center;
  justify-content: end;
  padding: 12px 40px;
  margin-left: -32px;
  margin-bottom: -24px;
  box-shadow: 0px 0px 35px 0px #124BF659;

}