.root {
	position: relative;
	overflow-y: visible;

	padding: 0 12px;
	margin: 12px 0;

	animation: grow 1s ease;

	&::-webkit-scrollbar {
		display: none;
	}
}

@keyframes grow {
	0% {
		height: 0;
		opacity: 0.3;
		transform: translateY(-76px);
	}
	15% {
		height: 64px;
	}
	50% {
		transform: translateY(0);
	}
	100% {
		opacity: 1;
	}
}
