.root {
  height: 70vh;
  overflow-y: scroll;

  ol {
    padding: 0px;
    list-style: none;
    counter-reset: li;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 1em;

    h2 {
      display: inline;
    }

    ol {
      row-gap: 0.5em;
      text-align: left;
    }

    li {
      &::before {
        font-size: 24px;
        counter-increment: li;
        content: counters(li, ".") ". ";
      }

      li:before {
        font-size: 16px;
      }
    }
  }
}
