.root {
  display: flex;
  width: 680px;
  padding: 24px 0;
  align-items: flex-start;
  gap: 12px;
  border-top: 1px solid #E5E5E5;
}

.column {
  display: flex;
  flex-flow: column;
  gap: 4px;
  width: 161px;
  align-items: flex-start;
}

.label {
  color: #CCC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.text {
  color: #2D2C46;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.link {
  color: #336BEB;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}