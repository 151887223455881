.root {
  width: 720px;
  height: 100px;
  border-radius: 4px;
  padding: 8px 13px 12px 0;
  display: flex;
  background: #F2F2F2;
}

.rowTags {
  display: flex;
  flex-flow: row;
  gap: 10px;
}

.row {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.column {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.img {
  img {
    width: 180px !important;
    height: 100px !important;
  }

  position: relative;
  top: -8px;
  margin-right: 12px;
}

.title {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #2D2C46;
}

.description {
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #484848;
}

.tag {
  padding: 2px 10px 2px 10px;
  border-radius: 30px;
  border: 1px solid #336BEB;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #336BEB;
}

.noImg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  svg {
    width: 48px;
    height: 55px;
  }
}

.stage {
  border-left: 1px solid #BFBFC0;
  width: 25px;
  margin: -8px -13px;
  border-radius: 0 10px 10px 0;
  height: 100px;

  svg {
    position: relative;
    top: 36px;
    path {
      fill: #336BEB;
    }

  }
}
