@import "styles/vars.scss";

.wrapper {
	display: flex;
	font-family: $ubuntu-font;
}

.image {
	height: 110px;
	width: 137px;
	margin-right: 27px;
	border-radius: 10px;
}

.title {
	font-size: 18px;
	line-height: 140%;
	color: var(--main-black);
}

.address {
	margin-top: 3px;

	font-size: 14px;
	color: var(--main-grey);
}

.tags {
	display: flex;
	flex-wrap: wrap;

	> span {
		margin-right: 11px;
		margin-top: 10px;
	}
}
