@import "styles/vars.scss";

.root {
	position: relative;

	margin-bottom: 5px;
	padding: 10px 0 5px 11px;

	.label {
		font-family: $ubuntu-font;
		font-size: 14px;
		line-height: 20px;
		color: #afafaf;
	}
}

.dots {
	position: absolute;
	top: 20px;
	left: 0;
}
