.wrapper {
	position: relative;
	display: inline-block;
	width: 100%;
}

.value {
	display: inline-flex;
	flex-direction: row;
	align-items: center;

	font-size: 14px;
	line-height: 20px;
	width: 100%;
	& > div:first-child {
		width: 100%;
	}

	.pencil {
		display: flex;
		transition: opacity 0.2s;
		opacity: 0;
		margin-left: 10px;
	}

	&:hover {
		.pencil {
			opacity: 1;
		}
	}
}

.valueHide {
	opacity: 0;
}

.edit {
	cursor: pointer;
}

.copy {
	cursor: copy;
	color: var(--main-blue);
}
