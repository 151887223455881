@import "styles/vars.scss";

.columnsWrapper {
	margin-top: 20px;
}

.columnsWrapper,
.infoContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.column {
	display: flex;
	flex-direction: column;
}

.infoContainer {
	margin-bottom: 8px;

	width: 193px;
}

.label {
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	font-family: $ubuntu-font;
	color: var(--main-black);
	opacity: 0.5;
}

.value {
	font-family: $ubuntu-font;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
	color: var(--main-black);

	&.orange {
		color: var(--accent-orange);
	}
	&.red {
		color: var(--accent-red);
	}
}
