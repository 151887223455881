.root {
	padding: 25px 43px 25px 40px;
}

.header {
	font-size: 26px;
	margin-bottom: 30px;
	margin-top: 0;
	color: var(--main-black);
}

.content {
	display: flex;
	width: 100%;
}

.leftBlock {
	min-width: 473px;
}

.actions {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 34px;
	width: 200px;
}

.cancelBtn {
	margin-top: 12px;
}
