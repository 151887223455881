.pinHeader {
	display: flex;
	align-items: center;

	h3 {
		margin: 0 28px 0 0;

		font-weight: bold;
		font-size: 26px;
		line-height: 36px;
		color: var(--main-black);
	}
}

.radio {
	border-radius: 100px;

	label {
		padding: 8px 20px;

		border-radius: 100px;
		font-size: 16px;
		line-height: 24px;
	}
}

.pinContainer {
	margin-top: 20px;
	padding: 12px 8px 12px 20px;

	border-radius: 10px;
}

.buttonContainer {
	margin-top: 28px;

	display: flex;
	justify-content: flex-end;
}
