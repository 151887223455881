.root {
	display: flex;
	align-items: center;

	column-gap: 4px;

	margin-top: 10px;

	color: var(--main-blue);
	cursor: pointer;

	&::before,
	&::after {
		content: "";

		height: 1px;

		background-color: var(--main-grey-border);
	}

	&::before {
		flex: 39;
	}

	&::after {
		flex: 14;
	}
}
