@import "styles/vars.scss";

.root {
  max-width: 720px;
  width: 100%;
}

.row {
  padding: 0 !important;
}

.skeleton {
  display: flex;
  flex-wrap: nowrap;

  width: 100%;
  height: 100px;

  margin-bottom: 10px;

  @include skeleton();
}

.tableGroupTitle {
  display: flex !important;

  &:last-child {
    margin-left: 8px;
  }
}

.title {
  font-size: 20px !important;
  display: flex;
  gap: 4px !important;
}

.count {
  margin-left: 12px;
  color: var(--BW-2, #8B8B8D);
  font-family: "Cera Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.noObjects {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 75px;

  span {
    width: 315px;
  }
}
