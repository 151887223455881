.root {
  margin-top: 60px;
}

.title {
  color: #2D2C46;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.header {
  justify-content: start!important;
  padding-bottom: 12px;
}
