@import "styles/vars";
@import "styles/keyframes";

.root {
	display: flex;
	flex-direction: row;
	margin-left: 220px;

	height: 50px;

	@include mdDesktopBreakpoint() {
		margin-left: 70px;
	}
}

.leftFilters {
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 6 6 100px;

	width: 10vw;
	@include lgDesktopBreakpoint() {
		flex: 8 8 100px;
	}
}

.itemWrapper {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	padding: 11px 9px 9px 9px;

	border-right: 1px solid var(--main-blue-inactive);
}

.selectListWrapper {
	width: 300px;
}

.responsible {
	width: 260px!important;
	padding-top: 4px!important;
}

.lastElem {
	border-right: none;
}
.dealType {
	min-width: 182px;
	max-width: 225px;
}

.price {
	width: 442px;
	justify-content: flex-start;
}

.priceAgency {
	width: 422px;
	justify-content: start;
}

.select {
	top: 3px;
	max-width: 200px;
}

.priceTrigger {
	display: flex;
	align-items: center;
	margin-right: 10px;
}

.priceTriggerAgency {
	display: flex;
	align-items: center;
}

.firstLine {
	display: flex;
	background-color: #fff;
	border-bottom: 1px solid var(--main-blue-inactive);
}

.objectTypeWrapper {
	width: 273px;
}

.objectTypeWrapperAgency {
	width: 193px;
}

.addressFilters {
	flex: 4 4 100px;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	position: relative;
	border-left: 1px solid var(--main-blue-inactive);
}

.address {
	padding-left: 20px;

	max-width: 90%;
	width: 90%;
}

.listClassName {
	max-width: 100%;
}

.placeholder {
	&::placeholder {
		font-family: Cera Pro;
		font-style: normal;
		font-weight: 500;

		color: var(--main-blue-secondary);
	}
}

.priceText {
	margin-right: 15px;
	min-width: 53px;
	color: var(--BW-0, #1E1E1F);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 16.8px */
	letter-spacing: 0.2px;
}

.priceTextAgency {
	min-width: 53px;
	color: var(--BW-0, #1E1E1F);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 16.8px */
	letter-spacing: 0.2px;
}

.moreWrapper {
	width: 176px !important;
	height: 30px !important;

	border: none !important;
	background: #fff !important;

	font-family: $ubuntu-font !important;
	font-weight: 400 !important;
	border-radius: 0 !important;

	border-right: 1px solid var(--main-blue-inactive) !important;
	border-bottom: 1px solid var(--main-blue-inactive) !important;

	svg {
		width: 20px !important;
		height: 20px !important;
	}
}

.more {
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: auto;
		height: auto;
	}
}

.moreText {
	color: var(--main-blue);
	font-size: 12px;
}

.submitBtn {
	position: absolute;
	top: 70px;
	right: 20px;
}

.buttonSubmit {
	border-radius: 1px;
	background: var(--Primary-Basic, #336BEB)!important;
	box-shadow: 0px 10px 30px 0px rgba(18, 75, 246, 0.15), -8px -7px 20.8px 0px rgba(1, 20, 86, 0.25) inset, 2px 2px 10.2px 0px rgba(255, 255, 255, 0.38) inset;
}

.afterSecondLine {
	top: 115px;
}

.spinner {
	animation: rotation 2s linear infinite;
	width: 20px !important;
	height: 20px !important;
	margin-left: 15px;
	margin-bottom: -3px;

	path {
		fill: #fff;
	}
}

.actionsRow {
	display: flex;

	height: 30px;
}

.compilationButton {
	height: 100%;
	min-width: 152px;

	border: none;
	background: var(--main-blue);

	cursor: pointer;

	font-family: $default-font;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #fff;
}

.resetBtn {
	height: 30px;

	background: white !important;
	border-radius: 0px !important;
	padding: 0px 24px !important;

	border-bottom: 1px solid var(--main-blue-inactive) !important;
	border-right: 1px solid var(--main-blue-inactive) !important;

	span {
		opacity: 0.3;
	}
}

.cancelIcon {
	width: 36px !important;
	height: 36px !important;

	path {
		fill: var(--main-blue) !important;
	}
}

.resetBtn:hover {
	color: var(--accent-red) !important;

	span {
		opacity: 1;
	}

	.cancelIcon {
		path {
			fill: var(--accent-red) !important;
		}
	}
}
