@import "styles/vars.scss";

.userInfo {
	@include button-no-style();
	display: flex;
	flex-direction: column;
	height: 57px;
	padding: 11px 8px 10px 8px;
	cursor: pointer;
	width: 100%;

	&:hover {
		background-color: var(--main-blue-disabled);
		border-radius: 10px;
	}
}

.lastName {
	font-weight: bold;
	font-size: 16px;
	font-family: $default-font;
}

.firstName {
	color: var(--main-grey-disabled);
	font-size: 12px;
	font-family: $ubuntu-font;
	font-weight: 400;
}

.defaultRow {
	color: var(--main-black);
	font-size: 14px;
	height: 76px;

	max-width: 285px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.phone {
	font-family: $default-font;
	font-weight: bold;
	font-size: 14px;
}

.date {
	color: var(--main-grey-disabled);
	font-size: 12px;
}
