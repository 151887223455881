@import "styles/vars.scss";

.header,
.blockItem,
.stages {
	@include skeleton();
}

.root {
	margin-bottom: 80px;
}

.blocks {
	.block:first-child {
		.header {
			margin-top: 30px !important;
		}
	}
}

.block {
	margin: 40px auto 0;
	width: calc(100% - 80px);

	.header {
		margin-top: 80px;
		margin-bottom: 12px;

		width: 100%;
		height: 32px;
	}

	.blockItem {
		width: 100%;
		height: 80px;
	}
}

.stages {
	margin: 40px auto 0 auto;

	width: calc(100% - 80px);
	height: 320px;
}
