@import "styles/vars.scss";

.root {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	color: var(--main-black);

	h2 {
		margin: 0;
		width: 100%;
		font-size: 26px;
	}
}

.important {
	background: #00bfa5;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 12px;
	border-radius: 100px;
	height: 20px;
	width: 56px;
	font-family: $ubuntu-font;
	color: white;
	margin-right: 27px;
}
