@import "styles/vars.scss";

.block {
	padding: 12px 16px;

	border-radius: 10px;
	background: var(--main-blue-disabled);

	display: flex;
	justify-content: space-between;

	transition: 0.2s;

	&:hover {
		filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
		background: #fff;
	}
}

.pinContact {
	padding: 16px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 24px;
	color: var(--main-blue);
}

.card {
	padding: 4px 21px;

	height: 60px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	cursor: pointer;

	background: var(--main-blue-disabled);
	border-radius: 10px;
}

.contactDataPhone {
	color: var(--main-black);
	font-size: 12px;
	font-weight: 400;
}

.header {
	margin: 24px 0 4px;

	display: flex;
	align-items: center;

	&.headerEditMode {
		justify-content: space-between;
	}

	h5 {
		margin: 0 !important;
	}
}

.editButton {
	margin-left: 8px;
}
