.root {
	margin-right: 60px;
}

.error {
	text-align: right;
	font-size: 12px;
	color: var(--accent-red);
}

.textareaWrapper {
	margin-top: 16px;
}

.comment {
	font-family: "Cera Pro";
	padding: 15px 20px;
}

.infoRow {
	min-height: 48px!important;
}