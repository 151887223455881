.root {
  width: 720px;
  height: 100px;
  border-radius: 10px;
  background: #F2F5FB;
  padding: 12px 30px 4px 20px;
}

.rootMini {
  width: 440px;
  height: 80px;
  border-radius: 10px;
  background: #F2F5FB;
  padding: 8px 30px 4px 12px;
}

.inTable {
  background: #FFF;
}

.rootMini {
  width: 440px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #F2F5FB;
}

.content {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.rowEnd {
  justify-content: end!important;
}


.title {
  color: var(--, #2D2C46);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}

.contact {
  width: 390px;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  color: #336BEB;
  text-overflow: ellipsis;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 15.6px */
  letter-spacing: 0.2px;
}

.contactMini {
  width: 342px;
}

.adSource {
  color: #2D2C46;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  opacity: 0.7;
}

.responsible {
  width: 160px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;
  gap: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #7E7E7E;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 15.6px */
  letter-spacing: 0.2px;
}