@import "styles/keyframes";
@import "styles/vars";

.root {
	position: relative;

	display: flex;
	flex-direction: column;

	margin-left: 220px;

	height: 50px;

	background: white;

	@include mdDesktopBreakpoint() {
		margin-left: 70px;
	}
}

.line {
	display: flex;
}

.filterWrapper {
	padding: 11px 9px 9px 9px;

	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	border-bottom: 1px solid var(--main-blue-inactive);
	border-right: 1px solid var(--main-blue-inactive);

	&:last-child {
		border-right: none;
	}
}

.responsibleWrapper {
	padding-left: 10px;
	min-width: 214px;
}

.dealType {
	min-width: 194px;
	max-width: 225px;
}

.objectTypeWrapper {
	display: flex;
	align-items: center;
	width: 194px;
	padding: 0px 17px 0px 24px;
	border-right: 1px solid var(--main-blue-inactive);
	border-bottom: 1px solid var(--main-blue-inactive);
}

.addressFilters {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-right: 1px solid var(--main-blue-inactive);
	border-bottom: 1px solid var(--main-blue-inactive);
}

.listClassName {
	width: calc(100% + 10px);
}

.addressWrapper {
	padding-left: 20px;

	min-width: 275px;
}

.addressInput {
	width: 90%;
}

.feedWrapper {
	min-width: 194px;
}

.dataWrapper {
	min-width: 214px;
	max-width: 214px;
}

.submitBtn {
	left: calc(-654px + 100%);
	bottom: 15px;
	width: 200px;
	min-height: 50px;
}

.resetBtn {
	height: 30px;

	background: white !important;
	border-radius: 0px !important;
	padding: 0px 24px !important;

	border-bottom: 1px solid var(--main-blue-inactive) !important;
	border-right: 1px solid var(--main-blue-inactive) !important;

	span {
		opacity: 0.3;
	}
}

.cancelIcon {
	width: 36px !important;
	height: 36px !important;

	path {
		fill: var(--main-blue) !important;
	}
}

.resetBtn:hover {
	color: var(--accent-red) !important;

	span {
		opacity: 1;
	}

	.cancelIcon {
		path {
			fill: var(--accent-red) !important;
		}
	}
}
