.wrapper {
	width: 720px;
}

.empty {
	text-align: center;
}

.counts {
	font-weight: normal;
	font-size: 16px;
	line-height: 28px;
	opacity: 0.5;
}
