@import "styles/vars.scss";

.root {
	display: flex;
	flex-direction: column;
	padding: 16px 20px 100px;

	:first-child {
		margin-top: 0px;
	}

	> div {
		margin-top: 24px;
	}
}

.header {
	height: 62px;
}

.item {
	display: flex;
	flex-direction: column;
}

.title {
	height: 20px;
	max-width: 45%;
}

.body {
	margin-top: 6px;
	height: 40px;
}

.skeleton {
	width: 100%;
	@include skeleton();
}
