@import "styles/vars.scss";

.root {
  &:hover {
    .editIcon,
    .removeIcon {
      opacity: 1;
    }
  }
}

.editIcon,
.removeIcon {
  transition: 0.2s;
  opacity: 0;
}

.delete {
  color: #FF575F;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
  cursor: pointer;
  gap: 4px;

  svg {
    fill: #FF575F;
  }
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.add {
  color: #336BEB;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
  cursor: pointer;

  svg {
    fill: #336BEB;
  }
}

.title {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
}

.headlineContainer {
  margin: 24px 0 8px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 8px;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--accent-red);
    font-weight: 500;
    font-size: 16px;

    path {
      fill: var(--main-blue-secondary);
      transition: 0.2s;
    }

    &:hover {
      path {
        fill: var(--main-blue);
      }

      .removeIcon {
        path {
          fill: var(--accent-red);
        }
      }
    }
  }

  h4 {
    margin: 0 8px 0 0 !important;
  }
}

.objectContainer {
  position: relative;
  margin-bottom: 12px;
}

.applicationContainer {
  position: relative;
}

.inEdit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 10px;
  cursor: not-allowed;
  background: var(--main-black);
  opacity: 0.3;
}

.otherReason {
  opacity: 0.7;
  font-weight: 400;
  font-size: 14px;
}

.pin {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background: var(--main-blue-disabled);
  color: var(--main-blue);
  font-size: 14px;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
}

.cancel {
  color: var(--accent-red);
  margin-left: auto;
}

.applicationCard {
  transition: 0.2s;

  &:hover {
    background: #fff;
    box-shadow: 0 10px 30px rgb(0 64 229 / 25%);
  }
}

.leadBackground {
  background-color: white;
  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);
}
