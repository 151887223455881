@import "styles/vars.scss";

.root {
	padding: 12px 20px 12px 40px;
	min-height: calc(100vh - 50px);
	max-height: calc(100vh - 50px);

	overflow: hidden;
}

.skeleton {
	margin-bottom: 24px;

	width: 100%;
	height: 40px;

	@include skeleton();
}

.header {
	height: 160px;
}

.gallery {
	height: 214px;
	width: calc(100% + 60px);
	transform: translateX(-40px);
	border-radius: 0;
}

.body {
	height: 1000px;
}
