@import "styles/vars";

.inputsWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-basis: 100%;
}

.small {
	.inputWrapper {
		max-width: 28px;
	}
}

.medium {
	.inputWrapper {
		max-width: 35px;
	}
}

.large {
	.inputWrapper {
		max-width: 63px;
	}
}

.extraLarge {
	.inputWrapper {
		max-width: 80px;
	}
}

.superLarge {
	.inputWrapper {
		max-width: 100px;
	}
}

.separator {
	margin: 0 12px;

	font-size: 12px;
	line-height: 20px;
	white-space: nowrap;
}

.unFixed {
	max-width: 100%;

	.inputWrapper {
		max-width: 45%;
		overflow: hidden;
	}
}

.ubuntu {
	.separator {
		font-family: $ubuntu-font;
	}
}

.loading {
	width: 100%;
	height: 28px;

	background: var(--main-blue-inactive);
}

.close {
	position: absolute;
	right: 8px;
	top: 60%;
	transform: translateY(-50%);

	padding: 4px;
	cursor: pointer;

	svg {
		transition: 0.2s;
		width: 10px;
		height: 10px;
		fill: var(--main-grey-light);
	}

	&:hover {
		svg {
			fill: var(--accent-red);
		}
	}
}

.withCleanUp {
	padding-right: 20px;
}

.error {
	margin-top: 8px;
	color: var(--accent-red);
	font-size: 12px;
}
