.root {
  position: sticky;
  bottom: 0;
  z-index: 1000;
  padding: 9px 40px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: end;
  background: #fff;
  filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
  font-family: "Ubuntu", sans-serif;
  //color: #2D2C46;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
}