.root {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	column-gap: 58px;
	row-gap: 10px;
}

.listItem {
	padding-bottom: 4px;

	width: calc(50% - 29px);

	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);

	border-bottom: 1px solid var(--main-blue-inactive);
}
