$margin-to-center: 0 auto;
$margin-ro-right: 0 0 0 auto;

.root {
	width: 100%;
	max-width: 650px;
	margin: $margin-to-center;

	@media screen and (max-width: 1500px) {
		margin: $margin-ro-right;
	}

	@media screen and (min-width: 1600px) and (max-width: 1640px) {
		margin: $margin-ro-right;
	}
}

.groups {
	display: flex;
	flex-direction: column;
	row-gap: 40px;

	margin-top: 24px;
}

.pagination {
	justify-content: center;
	margin-top: 40px;
}
