@import "styles/vars.scss";

.root {
	padding: 20px 24px;
	border-radius: 10px;
	&.disabled {
		padding: 10px 24px;
	}
}

.wrapper {
	justify-content: space-between;
}

.body,
.wrapper {
	@include flex-align-items-center;
}

.label,
.selected {
	color: var(--main-black);
	font-size: 16px;
	font-weight: 500;
}

.label.disabled {
	opacity: 0.5;
}

.selected {
	font-size: 14px;
}

.edit {
	cursor: pointer;
	margin-left: 30px;
	svg path {
		fill: var(--main-black);
	}
}
