@font-face {
	font-family: "Ubuntu";
	src: url("Ubuntu-BoldItalic.eot");
	src: url("Ubuntu-BoldItalic.eot?#iefix") format("embedded-opentype"),
		url("Ubuntu-BoldItalic.woff2") format("woff2"), url("Ubuntu-BoldItalic.woff") format("woff"),
		url("Ubuntu-BoldItalic.ttf") format("truetype");
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Ubuntu";
	src: url("Ubuntu-MediumItalic.eot");
	src: url("Ubuntu-MediumItalic.eot?#iefix") format("embedded-opentype"),
		url("Ubuntu-MediumItalic.woff2") format("woff2"), url("Ubuntu-MediumItalic.woff") format("woff"),
		url("Ubuntu-MediumItalic.ttf") format("truetype");
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Ubuntu";
	src: url("Ubuntu-LightItalic.eot");
	src: url("Ubuntu-LightItalic.eot?#iefix") format("embedded-opentype"),
		url("Ubuntu-LightItalic.woff2") format("woff2"), url("Ubuntu-LightItalic.woff") format("woff"),
		url("Ubuntu-LightItalic.ttf") format("truetype");
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Ubuntu";
	src: url("Ubuntu-Light.eot");
	src: url("Ubuntu-Light.eot?#iefix") format("embedded-opentype"), url("Ubuntu-Light.woff2") format("woff2"),
		url("Ubuntu-Light.woff") format("woff"), url("Ubuntu-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Ubuntu";
	src: url("Ubuntu-Italic.eot");
	src: url("Ubuntu-Italic.eot?#iefix") format("embedded-opentype"), url("Ubuntu-Italic.woff2") format("woff2"),
		url("Ubuntu-Italic.woff") format("woff"), url("Ubuntu-Italic.ttf") format("truetype");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Ubuntu";
	src: url("Ubuntu-Regular.eot");
	src: url("Ubuntu-Regular.eot?#iefix") format("embedded-opentype"),
		url("Ubuntu-Regular.woff2") format("woff2"), url("Ubuntu-Regular.woff") format("woff"),
		url("Ubuntu-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Ubuntu";
	src: url("Ubuntu-Bold.eot");
	src: url("Ubuntu-Bold.eot?#iefix") format("embedded-opentype"), url("Ubuntu-Bold.woff2") format("woff2"),
		url("Ubuntu-Bold.woff") format("woff"), url("Ubuntu-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Ubuntu";
	src: url("Ubuntu-Medium.eot");
	src: url("Ubuntu-Medium.eot?#iefix") format("embedded-opentype"), url("Ubuntu-Medium.woff2") format("woff2"),
		url("Ubuntu-Medium.woff") format("woff"), url("Ubuntu-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
