.grid-row-class {
	width: 100%;
}

.grid-row-class .grid-col-class:first-child {
	padding-left: 0;
}

.grid-row-class .grid-col-class:last-child {
	padding-right: 0;
}
