.root {
	margin-bottom: 12px;
	padding: 12px 12px 0;
	display: none;
}

.block {
	overflow: hidden;

	height: 64px;
	width: 100%;

	background: #9599c1;
	border-radius: 10px;

	&.blockWhite {
		background: #fff;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;

		opacity: 0.3;
	}

	svg {
		fill: #fff;
	}
}

.helpIcon path {
	fill: #D5D3CE;
}

.imgBlock {
	height: 64px;
	width: 84px;

	display: flex;
	align-items: center;
	justify-content: center;

	&.imgBlockBlindOpen {
		width: 69px;
	}
}

.block {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.help {
	width: calc(100% - 85px);
	height: 100%;

	padding-right: 25px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: #7b81b1;

	span {
		position: relative;

		white-space: nowrap;

		text-align: center;
		font-size: 16px;
		line-height: 20px;
		color: #fff;
	}

	svg {
		position: absolute;
		top: 2px;
		right: -25px;
	}

	&.helpBlindOpen {
		width: calc(100% - 69px);
	}
}

.buildingIcon {
	width: 24px;
}
