.pinHeader {
	display: flex;
	align-items: center;

	margin-bottom: 24px;

	h3 {
		margin: 0 28px 0 0;

		font-weight: bold;
		font-size: 26px;
		line-height: 36px;
		color: var(--main-black);

		span {
			font-weight: 400;
		}
	}
}

.radio {
	border-radius: 100px;

	label {
		padding: 8px 20px;

		border-radius: 100px;
		font-size: 16px;
		line-height: 24px;
	}
}

.listContainer {
	padding: 12px 8px 20px 20px;

	max-height: 384px;

	border-radius: 10px;
	background: var(--main-blue-disabled);
}

.buttonContainer {
	margin-top: 20px;

	display: flex;
	justify-content: flex-end;
}
