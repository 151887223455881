.root {
	margin-top: 36px;
}

.buttonContainer {
	margin-bottom: 16px;
}

.buttonContainer,
.buttonDeleteContainer {
	display: flex;
	justify-content: flex-end;
}

.buttonDeleteContainer {
	margin-top: 16px;

	&:hover button {
		color: var(--accent-red);
	}
}

.cardsContainer {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
}

.card {
	padding: 20px;

	background: #fff;
	border-radius: 10px;
}

.calendarIconStyle {
	margin-right: 12px;
}

.dateConteiner {
	display: flex;
	align-items: center;
}

.tillNowBlock {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
