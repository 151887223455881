@import "styles/vars.scss";

.root {
	margin-bottom: 87px;
	margin-top: 60px;
	padding-left: 6px;
}

.titleActions {
	@include flex-align-items-center;

	& > button {
		&:last-child {
			margin-left: 38px;
		}
	}
}

.application {
	margin: 10px 0;
	padding: 14px 17px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	border-radius: 10px;
	background: var(--main-blue-inactive);

	transition: background 0.2s ease;

	&:hover {
		padding: 11px 14px;

		background: var(--main-blue-disabled);
		border: 3px solid var(--main-blue);

		.leftColumn {
			a,
			div {
				opacity: 1;
			}
		}
	}
}

.leftColumn {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;

	div {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: var(--main-black);
		opacity: 0.5;
	}

	a {
		text-decoration: none;
		font-size: 13px;
		line-height: 16px;
		color: var(--main-blue);
		opacity: 0.5;
	}
}

.rightColumn {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;

	a {
		text-decoration: none;
		font-size: 14px;
		line-height: 17px;
		color: var(--main-blue);
	}

	div {
		font-size: 13px;
		line-height: 16px;
		color: #7e7e7e;
	}
}

.withIcon {
	display: flex;
	flex-direction: row;
	align-items: center;

	svg {
		margin-right: 8px;
	}
}

.showMore {
	margin-top: 16px;
	text-align: right;

	color: var(--main-blue);
	font-size: 14px;
	line-height: 17px;
	cursor: pointer;
}

.requestsTableRow {
	position: relative;

	height: 100px;
	max-width: 720px;
	background-color: var(--main-blue-disabled) !important;

	& > div {
		&:first-child {
			flex-grow: 1;
		}
	}
}

.buttons {
	@include flex-align-items-center;
	justify-content: flex-end;

	margin-top: 8px;

	& > button {
		&:last-child {
			margin-left: 24px;
		}
	}
}
