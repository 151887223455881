@import "styles/vars.scss";

.root {
	display: flex;

	background: #fff;
	border-radius: 10px;
}

.img {
	background: radial-gradient(25.01% 49.51% at 54.37% 53.88%, rgba(89, 195, 255, 0.5) 0%, #59c3ff 100%);
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	img {
		width: 200px;
		height: 100%;
		object-fit: cover;
	}
}

.body {
	align-self: center;
	flex-grow: 1;

	min-height: 205px;
	padding: 28px;
}

.title {
	font-weight: 500;
	font-size: 18px;
	color: var(--main-black);
}

.subTitle {
	margin-top: 4px;

	font-weight: 300;
	font-family: $ubuntu-font;
	font-size: 16px;
	color: var(--main-grey);
}

.stage {
	margin-top: 8px;

	@include flex-align-items-center;
}

.onStage {
	font-weight: 400;
	font-family: $ubuntu-font;
	font-size: 14px;
	color: var(--main-black);
	opacity: 0.5;
}

.tooltip {
	@include flex-align-items-center;

	margin-left: 8px;
	margin-right: 4px;

	& > div {
		font-weight: 400;
		font-family: $ubuntu-font;
		font-size: 14px;
		color: var(--main-black);
	}
}

.lastDate {
	font-weight: 300;
	font-size: 12px;
	color: var(--main-black);
	opacity: 0.5;
}

.createdDate {
	margin-top: 50px;
	font-weight: 400;
	font-size: 14px;
	color: var(--main-black);
	opacity: 0.5;
}

.sideData {
	margin: 8px 0;
	padding: 15px 53px;

	min-width: 445px;
	border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.sideDataLabel {
	font-size: 14px;
	color: var(--main-black);
	font-family: $ubuntu-font;
	font-weight: 400;

	opacity: 0.5;
}

.sideDataValue {
	margin-top: 4px;

	font-weight: 500;
	font-size: 24px;
}

.sideDataRoot {
	margin-top: 8px;

	&:first-child {
		margin-top: 0;
	}
}
