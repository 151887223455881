@import "styles/vars.scss";

.root {
	@include flex-align-items-center;
	justify-content: space-between;

	font-family: $ubuntu-font;
	& > * {
		&:last-child {
			margin-left: 16px;
		}
	}
}

.label {
	font-size: 14px;
	line-height: 20px;
	opacity: 0.5;
}

.value {
	font-weight: 500;
}

.bold > * {
	opacity: 1;
	font-weight: 700;
}
