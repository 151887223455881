@import "styles/vars.scss";

.root {
	position: relative;
	transition: filter 0.2s;

	&.rootHovered:hover {
		filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
		z-index: 101;
	}
}

.cardRoot {
	&:hover {
		filter: none !important;
	}
}

.content {
	padding-right: 24px;
}

.contentInCompilation {
	opacity: 0.4;
}

.nonActiveImage {
	border: 0;
	margin: -8px -48px -8px -12px !important;
	width: 85px !important;
	height: 81px !important;

	img {
		border-radius: 10px;
	}
}

.hide {
	display: none;
}

.loader {
	circle {
		stroke: #fff !important;
	}

	svg {
		width: 14px !important;
		height: 14px !important;
	}
}

.bar {
	position: absolute;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 2;
	background: top!important;
	border-left: 1px #CCCCCC;
	width: 25px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--main-blue-inactive);
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	transition: background 0.2s;

	svg {
		fill: white;
	}
}

.add,
.remove {
	cursor: pointer;
}

.add {
	&:hover {
		background: initial!important;
		background: var(--main-blue)!important;
	}

	svg {
		width: 24px;
		height: 24px;
		border: 0;
	}
}

.addPreApp {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border-left: 1px solid #CCC;
	width: 25px;
	&:hover {
		background: initial!important;
		background: var(--main-blue)!important;

		svg {
			width: 100px;
			height: 100px;
			border: 0;
		}

		path {
			fill: white !important;
		}
	}
}

.remove {
	path {
		transition: 0.3s;
	}

	svg {
		width: 24px;
		height: 24px;
	}

	&:hover {
		background: initial!important;
		background-color: var(--accent-red)!important;

		path {
			fill: white !important;
		}
	}
}

.inCompilationText {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	background: rgba(45, 44, 70, 0.5);
	backdrop-filter: blur(3px);
	border-radius: 10px 0 0 10px;
	display: flex;
	align-items: center;
	width: 108px;
	height: 100px;
	font-family: $ubuntu-font;
	font-size: 14px;
	font-weight: 300;
	line-height: 20px;
	color: #fff;
	text-align: center;
}

.preApplication {
	width: 26px;
	height: 100%;
	background-color: #D5D3CE;
}
