.codeWrapper {
  position: relative;
}

.code {
  position: absolute;
  color: #fff;
  font-weight: bold;
  font-size: 50px;
  transform: rotate(29.94deg);
  right: 79px;
  top: 90px;
  width: 257px;
  text-align: center;
}
