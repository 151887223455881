@import "styles/vars.scss";

.root {
	position: relative;
}

.input {
	padding: 8px 16px;

	width: 100%;

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
	color: var(---main-black);

	background: #fff;
	border-radius: 795px;
	border: 1px solid transparent;

	&::placeholder {
		color: var(--main-blue-secondary);
	}

	&:disabled {
		cursor: not-allowed;
	}

	&.withPrepend {
		padding-left: 44px;
	}

	&.withAppend {
		padding-right: 44px;
	}

	&.withBorder {
		border: 1px solid #d3d3d3;
	}

	&.withAppend {
		padding: 8px 44px 8px 16px;
	}
}

.append,
.prepend {
	position: absolute;
	top: 49%;
	transform: translateY(-49%);

	display: flex;
}
.prepend {
	left: 12px;
}
.append {
	right: 12px;
}

.loading {
	width: 100%;
	height: 38px;

	border-radius: 795px;
	background: var(--main-blue-inactive);
}
