@import "../../../../../styles/vars.scss";

.root {
	@include flex-align-items-center;
	justify-content: space-between;

	padding: 36px 60px 36px 80px;

	background: url(../../../../../assets/images/socials.jpeg) center;
	background-size: cover;
	border-radius: 16px;
}

.text {
	font-size: 24px;
	color: var(--main-black);
	font-weight: 500;
	max-width: 315px;
}
