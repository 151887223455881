.phoneItem {
	display: flex;
	align-items: center;

	column-gap: 0.5rem;

	.tooltip {
		height: 16px;
		margin: 0;
	}

	p {
		white-space: nowrap;
	}
}

.link {
	color: inherit;
}
