@import "styles/vars.scss";

.root {
	padding-top: 22px;
	padding-bottom: 0;
	min-height: 334px;
}

.select::after {
	content: "";
	width: 100%;
	position: absolute;
	bottom: 0px;
	left: 0px;
	height: 1px;
	border-bottom: 1px solid var(--main-blue-secondary);
}

.buttonContainer {
	@include flex-align-items-center;
	justify-content: flex-end;

	margin-top: 40px;
}
