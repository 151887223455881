@import "styles/vars.scss";

.wrapper {
	margin-top: 60px;
	padding-bottom: 10px;

	position: relative;
	overflow-x: scroll;
	overflow-y: hidden;

	max-width: 100%;

	&::-webkit-scrollbar {
		height: 7px;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--main-grey-inactive);
	}
}

.table {
	position: relative;

	min-width: 1200px;
	width: 100%;

	overflow: hidden;
	border-spacing: 0;

	thead {
		tr {
			vertical-align: bottom;
		}
	}
}

.arrowIcon {
	margin-bottom: 1px;
	margin-left: 14px;

	opacity: 0;
	fill: var(--main-grey-disabled);

	&.arrowIconAsc {
		opacity: 1;
	}
	&.arrowIconDesc {
		opacity: 1;
		transform: rotate(180deg);
	}
}

.td {
	&:last-child {
		.tableTdContent {
			border-right: none;
		}
	}
}

.tdContent {
	padding: 26px 21px;

	height: 74px;

	border-right: 1px solid var(--main-grey-light);
	font-family: $ubuntu-font;
}

.fixedFirstColumn {
	.headerTh:first-child {
		background-color: #fff;
		border-bottom: 5px solid rgba(127, 141, 239, 0.5);
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;

		.headerThContent {
			border-right: none;
			padding: 24px 20px 8px 20px;
		}
	}

	.td:first-child {
		background-color: #fff;
		border-right: 1px solid var(--main-grey-light);

		.tdContent {
			padding: 7px 12px 13px 12px;
			border-right: none;
		}
	}
}

.headerTh {
	border-bottom: 5px solid var(--main-blue);
	font-family: $ubuntu-font;

	&:last-child {
		.headerThContent {
			border-right: none;
		}
	}
}

.headerThContent {
	padding: 23px 20px 10px 20px;

	font-weight: normal;
	font-size: 12px;
	text-align: left;
	line-height: 140%;
	border-right: 1px solid var(--main-grey-light);
}

.row {
	cursor: pointer;
	transition: 0.07s;

	&:hover {
		background-color: #fff;
	}
}
