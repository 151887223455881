.form {
	margin-top: 28px;
	padding: 24px;
}

.valuesSet {
	padding: 8px 24px;
}

.success {
	padding: 20px 24px;
}

.cancel {
	padding-left: 4px;

	font-size: 14px;
}

.formMain {
	margin-top: 24px;
	padding: 21px 8px 0;

	border-top: 1px solid var(--main-blue-secondary);
}
