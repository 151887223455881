.root {

}

.title {
  color: #2D2C46;
  font-family: Ubuntu, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 138.462% */
  letter-spacing: 0.2px;
}

.search {
  border: 1px solid #BFBFC0;
  width: 720px;
  margin-top: 32px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  height: 50px;
}

.col {
  display: flex;
  flex-flow: column;
  gap: 12px;
  height: 364px;
  overflow-y: auto;
}

.footer {
  box-shadow: 0px 0px 30px 0px #2224281A;
  width: 800px;
  height: 64px;
  background: #FFFFFF;
  display: flex;
  justify-content: end;
  padding: 12px 40px;
  margin-bottom: -24px;
  margin-left: -32px;
  border-radius: 0px 0px 20px 20px;
  margin-top: 10px;
}
