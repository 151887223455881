@import "styles/vars.scss";

.modalLoading {
	min-height: 500px;
}

.modalWrapper {
	& > div:first-child {
		padding: 22px 36px;
	}
}

.modal {
	overflow-y: unset;
	color: var(--main-black);
}

.h5 {
	margin-top: 12px !important;
}

.modalTitleImage {
	width: 116px;
	height: 40px;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.modalPriceUnderlay {
	border-radius: 10px;
}

.modalPrice {
	padding: 10px 28px;
	display: flex;
	align-items: flex-start;
	color: var(--main-black);

	& > div {
		&:first-child {
			white-space: nowrap;
			margin-top: 10px;
			margin-right: 33px;
			font-weight: 400;
			font-size: 14px;
			font-family: $ubuntu-font;
		}
	}
}

.modalPriceInputs {
	& > * {
		padding: 0 8px;

		&:last-child {
			margin-top: 8px;
		}
	}

	div {
		display: flex;
		align-items: center;
		background-color: #fff;
		border-radius: 100px;
		white-space: nowrap;

		input {
			padding: 8px 12px !important;
			text-align: right;
			font-size: 16px;
			font-weight: 500;
			white-space: nowrap;

			& + div {
				position: relative;
				transform: none;
				right: 0;
				font-weight: 400;
				opacity: 0.5;
			}
		}
	}
}

.modalForm {
	margin-top: 24px;

	form {
		display: flex;
		flex-direction: column;
		padding: 0;
		font-size: 14px;

		div[class*="title"] {
			font-size: 16px;
			margin-left: -10px;
		}

		div[class*="inputWrapper"] {
			font-size: 16px;
		}

		div[class*="selectWrapper"] {
			margin-bottom: 0;
		}
	}
}

.modalError {
	margin: 0 !important;
	position: absolute;
	left: -25px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 11;

	svg {
		path {
			fill: var(--accent-red);
		}
	}
}

.modalErrorCard {
	font-size: 12px;
	font-family: $ubuntu-font;
	line-height: 16px;
	font-weight: 400;

	div {
		&:last-child {
			margin-top: 8px;
		}
	}

	span {
		margin-left: 4px;
		font-weight: 500;
	}
}

.modalSubmit {
	margin: 15px 0 0 auto;
}
