.root {
	margin-top: 80px;

	h3 {
		margin: 60px 0 16px 0;

		font-weight: bold;
		font-size: 24px;
		color: var(--main-black);
	}
}
