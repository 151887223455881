.root {
	display: flex;
	flex-direction: row;
}

.title {
	display: flex;
	flex-direction: column;

	margin-left: 16px;
}

.company {
	margin: 0px !important;
}
