@import "styles/vars.scss";

.root {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
}

.filters {
  padding: 15px 15px 15px 235px;
  background: #fff;
  @include mdDesktopBreakpoint() {
    padding: 15px 15px 15px 85px;
  }
}

.list {
  padding: 20px 40px;
  overflow: auto;
  width: 100%;
}

.bottomLine {
  span {
    height: 6px;
    background: #FFF!important;
  }
}

.sideContainer {
  width: 500px;
  min-width: 500px;
  background: var(--main-blue-inactive);
}

.tabContent {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tableWithPagination {
  width: 100%;
  @include flex-align-items-center;
  flex-direction: column;
}

.pagination {
  margin-top: 38px;
  justify-content: center;
}

.tabTitle {
  min-width: 130px;
}

.tabs {
  margin-bottom: 30px;
  margin-top: 20px;
  @include flex-justify-content-center;
}

.tabLabel {
  @include flex-justify-content-center;
}
