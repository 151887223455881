.h2 {
	justify-content: flex-start !important;

	button {
		margin-left: 20px;

		svg {
			margin-right: 8px;
		}
	}
}

.responsibles {
	margin-top: 60px;
}

.comment {
	word-break: break-word;
}

.comment,
.object {
	margin-top: 80px;
}
