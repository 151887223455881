.tabs {
	display: flex;
	align-items: center;
	justify-content: center;
}

.root {
	span {
		height: 6px;
		background: #FFF!important;
	}
}