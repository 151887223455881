@import "src/components/UI/Tag/TagTaskStatus/styles/utils";

.root {
	padding: 5px 12px;

	border-radius: 100px;
	background-color: get-status-color();

	color: #fff;
	font-size: calc(14 / 16) + rem; // 14px
	font-weight: 500;
}
