.root {
	display: flex;
	flex-direction: column;
}

.optionWrapper {
	display: flex;
	align-items: center;
	margin-top: 16px;

	&:first-child {
		margin-top: 0;
	}
}

.optionGroup {
	width: 120px;
	word-break: break-all;
	margin-right: 25px;
	color: #2D2C46;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.2px;
}

.optionWrapperSelected {
	.optionGroup {
		color: var(--main-blue) !important;
	}
}

.checkbox {
	display: none;
}

.checkboxBg {
	display: none;
}

.label {
	background-color: inherit;
	width: 25px;
	height: 25px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	margin-right: 5px;
	border: 1px solid #7f8def80;
	cursor: pointer;
	transition: 0.1s;
	user-select: none;

	&.checked {
		background-color: var(--main-blue);
		border-color: var(--main-blue);

		.labelText {
			color: #fff;
		}
	}

	&:hover {
		border-color: var(--main-blue);
	}
}

.labelText {
	color: var(--main-blue);
	font-weight: 500;
	font-size: 14px;
}

.labelWithSign {
	margin-left: 1px;
}

.buttonWrapper {
	position: relative;

	.preview {
		display: none;
		position: absolute;
		z-index: 100;
		width: 433px;
		height: 260px;
		top: 53px;
		border-radius: 10px;
		right: -36px;
	}

	.previewLeft {
		left: -36px;
		right: auto;
	}

	.previewTop {
		top: auto;
		bottom: 53px;
	}

	&:hover {
		.preview {
			display: block;
		}
	}
}
