.root {
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 14px;
	line-height: 20px;
	padding: 12px 6px 0;

	// Hide button while graphs is not ready
	button {
		opacity: 0;
		cursor: default;
	}
}

.h1 {
	margin: 0;
}
