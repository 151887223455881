.checkBoxContainer {
	margin: 12px 0 14px auto;
	padding: 0 0 4px 11px;

	width: 50%;

	display: flex;

	border-bottom: 1px solid var(--main-blue-inactive);
}
