@import "styles/vars.scss";

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--main-blue-disabled);
}

.image {
  position: absolute;
  top: 26px;
  left: 28px;
  width: 159px;
  height: 70px;
}

.content {
  width: 470px;
  margin: 142px auto;
}

.title {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--main-black);
  font-size: 20px;
  line-height: 120%;
}

.desc {
  margin-top: 0;
  margin-bottom: 17px;
  padding-bottom: 40px;
  color: var(--main-black);
  line-height: 140%;
  font-family: $ubuntu-font;
  font-size: 14px;
  word-break: break-word;
  border-bottom: 1px solid var(--main-grey-light);
}

.footer {
  display: flex;
  align-items: center;
}

.people {
  width: 92px;
  margin-right: 17px;
  color: var(--main-grey-disabled);
  font-family: $ubuntu-font;
  font-size: 14px;
  word-break: break-word;
}

.number {
  margin-right: 65px;
  color: var(--main-black);
  font-weight: 300;
  font-size: 38px;
}

.phone,
.address {
  display: flex;
  flex-direction: column;
  color: var(--main-grey-disabled);
  font-family: $ubuntu-font;
  font-size: 14px;
  line-height: 120%;
}

.phone {
  &__link {
    color: var(--main-black);
    font-size: 16px;
    text-decoration: none;
    line-height: 140%;
  }
}

.address {
  margin-top: 24px;

  &__text {
    color: var(--main-black);
    font-size: 16px;
    line-height: 140%;
    margin-top: 6px;
  }
}
