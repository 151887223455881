@import "styles/vars.scss";

.list {
	padding: 20px 40px;
	overflow: auto;
	width: 100%;
}

.sideContainer {
	width: 400px;
	min-width: 400px;

	background: var(--main-blue-inactive);
}

.tabContent {
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: center;
}

.tableWithPagination {
	width: 100%;

	@include flex-align-items-center;
	flex-direction: column;
}

.pagination {
	margin-top: 38px;

	justify-content: center;
}

.tabTitle {
	min-width: 130px;
}

.tabs {
	margin-bottom: 65px;

	@include flex-justify-content-center;
}

.tabLabel {
	@include flex-justify-content-center;
}