.container h2 {
    color: #454545;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    /* 121.053% */
    margin-top: 60px;
}

.containerSubscribe {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.box {
    border-radius: 12px;
    background: #FFF;
    height: 230px;
}

.contentBox {
    display: flex;
    padding: 28px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;

    .title {
        display: flex;
        align-items: center;
        gap: 12px;
        align-self: stretch;

        color: #2D2C46;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        /* 25.2px */
        letter-spacing: 0.2px;
    }

    .username {
        color: #2D2C46;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        /* 25.2px */
        letter-spacing: 0.2px;
    }
}

.textBox {
    display: flex;
    padding-left: 0px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    p {
        padding-left: 52px;
        color: #2D2C46;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: 0.2px;
    }
}

.link {
    flex: 1 0 0;
    display: flex;
    cursor: pointer;

    p {
        padding-left: 12px;
        color: #2D2C46;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: 0.2px;
    }

    img {
        padding-left: 52px;
    }
}