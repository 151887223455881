.loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: rgba(77, 77, 104, 0.3);
  backdrop-filter: blur(4px);
}

.cardBody {
  background: #FFF;
}

.countBlock {
  background-color: #F2F5FB;
}

.noFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--BW-3, #BFBFC0);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
}

.title {
  color: #2D2C46;
  font-family: Ubuntu, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 138.462% */
  letter-spacing: 0.2px;
}

.collections {
  width: 740px;
  height: 400px;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  gap: 12px;
}

.search {
  border: 1px solid #BFBFC0;
  width: 720px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  height: 50px;
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: end;
  align-items: center;
  height: 64px;
  width: 800px;
  border-radius: 0 0 20px 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  box-shadow: (0px 10px 30px rgba(0, 64, 229, 0.25));
  z-index: 1;
  background: var(--BW-5);
  padding-right: 40px;
}

.selected {
  border-radius: 10px;
  border: 2px solid #7F8DEF;
  background-color: white;
  img {
    height: 96px;
    margin-top: 2px;
  }
}