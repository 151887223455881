@import "styles/vars.scss";

.input {

}

.submitBtns {
	margin: 16px 0 24px;

	display: flex;
	justify-content: flex-end;
	column-gap: 24px;
}
