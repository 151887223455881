@import "styles/vars.scss";

.contact {
	display: flex;
	align-items: center;
	column-gap: 8px;
}

.icon {
	width: 36px;
	height: auto;

	path {
		fill: var(--main-blue);
	}
}

.name {
	flex: 1;
	margin: 0 !important;
}
