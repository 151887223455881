.root {
  padding: 16px 40px;
  min-height: calc(100vh - 64px);
}

.cardCreation {
  margin-top: 16px;
}

.cardWrapper {
  & > div {
    margin-top: 16px;
  }
}

.noDataText {
  margin-top: 20px !important;

  color: var(--main-grey-disabled) !important;
}
