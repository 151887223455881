@import "styles/vars.scss";

.root {
	margin-bottom: 88px;
}

.titleActions a:last-child {
	svg {
		width: 11px;
		height: 11px;
	}
}

.relatedContacts {
	margin-top: 16px;
	padding-left: 0;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);

	display: flex;
	flex-direction: column;

	> div {
		margin-top: 12px;
	}
}

.buttons {
	@include flex-align-items-center;
	justify-content: flex-end;

	margin-top: 8px;

	& > button {
		&:last-child {
			margin-left: 24px;
		}
	}
}
