@import "styles/vars.scss";

.root {
	display: flex;
	flex-direction: row;

	min-height: calc(100vh - 50px);
	max-height: calc(100vh - 50px);
}

.listWrapper {
	padding-bottom: 40px;
	overflow: auto;

	flex: 6 6 100px;

	@include lgDesktopBreakpoint() {
		flex: 8 8 100px;
	}
}

.list {
	position: relative;
	margin: 100px auto 0 auto;

	max-width: 746px;
}

.listWithNoFilters {
	margin: 50px auto 0 auto;
}

.map {
	flex: 4 4 100px;
}

.filters {
	padding: 15px 15px 15px 0;
	background: #fff;
	margin-left: 235px;

	@include mdDesktopBreakpoint() {
		margin-left: 85px;
	}
}

.filtersModal {
	overflow: hidden;
}

.filtersShorter {
	margin-right: 134px;
}

.pagination {
	justify-content: center;
	margin-top: 36px;
}
