.root {
  margin-top: 60px;
  width: 720px;
}

.archived {
  opacity: 0.1;
}

.card {
  width: 720px;
  height: 100px;
  border-radius: 10px;
  background: var(--BW-5, #FFF);
  padding: 8px 20px 4px;

  /* Default object */
  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);
}

.title {
  color: var(--, #2D2C46);
  font-family: "Cera Pro";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 138.462% */
  letter-spacing: 0.2px;
  margin-bottom: 8px;
}