.icon {
	width: 90px;
	height: 90px;

	path {
		fill: var(--main-blue-secondary);
	}
}

.toast {
	max-width: 260px;
	width: 260px;
}
