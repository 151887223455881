@import "../../../styles/vars.scss";

.balloon-container {
	width: 280px;
	font-size: 0;
	background: #fff;
	border-radius: 12px;
	box-shadow: 0 10px 30px rgba(0, 64, 229, 0.5);
}

.real-estate-balloon {
	&__img {
		border-radius: 12px 12px 0 0;
		width: 280px;
		height: 190px;
		object-fit: cover;
	}

	&__noimg {
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 96px;
			height: 110px;
		}
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0;
		margin-bottom: 4px;
		color: var(--main-blue);
		font-weight: 500;
		font-size: 16px;
	}

	&__button {
		padding: 0;
		color: inherit;
		font: inherit;
		background-color: transparent;
		border: none;
		outline: none;
		cursor: pointer;

		&.skeleton {
			width: 220px;
			height: 20px;
			background-color: var(--main-blue-disabled);
		}
	}

	&__object-icon {
		position: relative;
		z-index: 1;
		width: 24px;
		min-width: 24px;
		height: 24px;
		min-height: 24px;
		padding: 0;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		border: none;
		border-radius: 50%;
		outline: none;
		cursor: pointer;

		&:hover {
			.balloon__desc-full-text {
				display: block;
			}
		}

		&.skeleton {
			background-color: var(--main-blue-disabled);
		}
	}

	&__object-info {
		&::after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			background-image: url("../../../assets/icons/info.svg");
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			content: "";
		}
	}

	&__desc-full-text {
		position: absolute;
		top: -5px;
		left: -5px;
		z-index: -1;
		display: none;
		min-width: 220px;
		padding: 25px 30px;
		color: #fff;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		text-align: left;
		background: var(--main-grey-inactive);
		border-radius: 12px;
		box-shadow: 0 10px 30px rgba(0, 64, 229, 0.2);
	}

	&__desc {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin: 4px 0 20px 0;
		color: var(--main-grey);
		font-weight: 300;
		font-size: 14px;
	}

	&__desc-text {
		width: 228px;
		height: 52px;
		overflow: hidden;

		&.skeleton {
			width: 82px;
			height: 16px;
			margin-top: 15px;
		}

		&.skeleton_big {
			width: 128px;
			margin-top: 3px;
			margin-bottom: 26px;
		}
	}

	&__prices {
		display: flex;
		align-items: center;
	}

	&__full-price {
		position: relative;
		margin: 0;
		margin-right: 12px;
		padding-left: 15px;
		color: var(--main-black);
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;

		&.skeleton {
			width: 123px;
			height: 20px;
			background: var(--main-blue-disabled);
		}

		&::before {
			position: absolute;
			top: 50%;
			left: 0;
			width: 7px;
			height: 5px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			transform: translateY(-50%);
			content: "";
		}

		&.decreased::before {
			background-image: url("../../../assets/icons/triangle-down.svg");
		}

		&.increased::before {
			background-image: url("../../../assets/icons/triangle-up.svg");
		}
	}

	&__price-per-unit {
		margin: 0;
		color: #878787;
		font-weight: normal;
		font-size: 12px;
		line-height: 20px;

		&.skeleton {
			width: 84px;
			height: 20px;
		}
	}
}

.skeleton {
	@include skeleton();
}

.balloon-container__context {
	padding: 17px 6px 36px 11px;
}

.balloon__footer {
	padding: 12px;
	border-top: 1px solid #e5e5e5;
}

.real-estate-contacts {
	display: flex;

	&__img {
		width: 36px;
		height: 36px;
		margin-right: 15px;
		object-fit: contain;
		border-radius: 50%;
	}

	&__title {
		margin: 0;
		margin-bottom: 3px;
		color: var(--main-black);
		font-weight: normal;
		font-size: 14px;
		line-height: 120%;

		&.skeleton {
			width: 82px;
			height: 16px;
		}
	}

	&__phone-number,
	&__phone-title {
		padding: 0;
		color: var(--main-blue);
		font-weight: normal;
		font-size: 12px;
		line-height: 120%;
		background-color: transparent;
		border: none;
		outline: none;
		cursor: pointer;

		&.skeleton {
			width: 128px;
			height: 12px;
			margin-top: 3px;
			margin-bottom: 0;
			background-color: var(--main-blue-disabled);
		}
	}
}

.balloon__waiting-title {
	font-size: 20px;
}
