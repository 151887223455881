.button {
	margin-top: 32px;
}

.actions {
	display: flex;
	flex-wrap: nowrap;
	column-gap: 30px;
}

.content {
	display: flex;
	flex-direction: column;
	row-gap: 36px;

	margin-top: 36px;
}
