.content {
  display: flex;
  flex-flow: column;
  padding: 4px 8px;
}

.modal {

}

.contentBody {
  height: 552px!important;
  padding: 0 0 0 8px;
}