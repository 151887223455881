@import "styles/vars.scss";

.inputWrapper {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	z-index: 99;

	width: 100%;
	min-width: 150px;
}

.input {
	padding: 7px 25px 7px 12px;

	width: calc(100% - 20px);

	background: #ffffff;
	border: 1px solid var(--main-blue-secondary);
	border-radius: 10px;

	font-family: $ubuntu-font;
	font-weight: 300;
	font-size: 14px;
	line-height: 15px;
	color: var(--main-black);

	&:focus {
		outline: none;
	}
}

.errorInput {
	padding: 7px 25px 7px 30px;
	border: 1px solid var(--accent-red);
}

.errorIcon {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
}

.spinnerContainer,
.arrowContainer,
.submit {
	position: absolute;
	top: 50%;
	right: 27px;
	transform: translateY(-50%);

	padding: 0;
	height: 16px;
	width: 16px;

	background: transparent;
	border: none;

	cursor: pointer;

	svg {
		fill: var(--main-blue);
		height: 16px;
		width: 16px;
	}
}

.spinnerContainer {
	animation: rotating 2s linear infinite;
}

@keyframes rotating {
	from {
		transform: translateY(-50%) rotate(0deg);
	}
	to {
		transform: translateY(-50%) rotate(360deg);
	}
}

.htmlSelect {
	display: none;
}

.customSelectWrapper {
	position: absolute;
	top: -6px;
	left: 0;
	z-index: 10;

	font-size: 14px;
	line-height: 15px;
	color: var(--main-black);

	min-width: 171px;
}

.customSelect {
	padding: 3px 25px 3px 12px;

	word-break: break-word;

	background: #ffffff;
	border: 1px solid var(--main-blue-secondary);
	border-radius: 10px;
}

.errorCustomSelect {
	padding: 3px 25px 3px 28px;
	border: 1px solid var(--accent-red);
}

.customOption {
	padding: 4px 0;
}

.customOptionNotSelected {
	cursor: pointer;

	&:hover {
		color: var(--main-blue);
	}
}

.selectedOptionWrapper {
	cursor: pointer;
	display: inline-block;
	width: 100%;
}
