.fixed {
	display: flex;
}

.empty {
	flex: 1 1;
}

.inputContainer {
	margin: 8px 0 16px 20px;
	flex: 1 1;

	display: flex;
	flex-direction: row;

	border-bottom: 1px solid var(--main-blue-inactive);
}

.inputText {
	margin-bottom: 4px;

	flex: 1 1;

	transform: translateX(8px);
}

.inputRadio {
	flex: 1 1;
	text-align: right;
}

.customInfoRow {
	padding: 10px 0 5px 12px;
}
