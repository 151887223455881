@import "styles/vars.scss";

.headerMain {
	@include flex-align-items-center;

	h2 {
		margin: 0;

		font-weight: 500;
		font-size: 18px;
	}
}

.header {
	@include flex-align-items-center;
}

.image {
	margin-right: 12px;

	height: 40px;
	width: 40px;
	border-radius: 100px;

	img {
		width: 100%;
		border-radius: 100px;
		height: 100%;
		object-fit: cover;
	}
}

.area {
	margin-top: 10px;

	position: relative;
	border-radius: 10px;

	textarea {
		font-family: $ubuntu-font;
	}

	svg {
		cursor: pointer;
		position: absolute;
		bottom: 8px;
		right: 8px;
		path {
			fill: var(--main-blue-secondary);
		}
	}
}

.editable {
	@include flex-align-items-center;

	padding: 6px;
	margin-left: 2px;

	cursor: pointer;

	svg {
		width: 12px;
		height: 12px;
		path {
			fill: var(--main-blue-secondary);
		}
	}
}

.text {
	transform: translate(-5px, -10px);
	&.withImage {
		transform: translate(-5px, -16px);
	}
}

.dialog {
	width: 100%;
}

.dialogRoot,
.dialog {
	transform: rotate(180deg) scaleX(-1);
}

.dialogRoot {
	margin: 0;
}

.dialog {
	border-top-left-radius: 0;
	border-bottom-left-radius: 20px;
}
