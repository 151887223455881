.root {
	padding: 0 !important;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 30px;
	height: 30px !important;

	border: 2px solid var(--main-blue-inactive) !important;

	svg {
		margin-right: 0 !important;
		transform: rotate(45deg);
	}

	path {
		fill: var(--main-grey-disabled) !important;
	}

	&:hover {
		border-color: var(--accent-red) !important;

		box-shadow: none !important;

		path {
			fill: var(--accent-red) !important;
		}
	}
}
