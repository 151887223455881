@import "styles/vars.scss";

.title {
	display: inline-flex;
	align-items: center;
	color: #2D2C46;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 24px */
	letter-spacing: 0.2px;
}

.row {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
}

.header {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 24px;
}

.card {
	margin-top: 8px;
}

.change {
	color: #336BEB;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 171.429% */
	letter-spacing: 0.2px;
	cursor: pointer;
	gap: 4px;

	svg {
		fill: #336BEB;
	}
}

