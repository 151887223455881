.container h2 {
	color: #454545;
	font-size: 38px;
	font-style: normal;
	font-weight: 700;
	line-height: 46px;
	/* 121.053% */
	margin-top: 60px;
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	color: #454545;
}

.contentLeft {
	padding: 28px 45px 28px 45px;
	border-radius: 12px;
	background: #FFF;
}

.contentLeft h3 {
	color: #2D2C46;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	/* 25.2px */
	letter-spacing: 0.2px;
}

.contentLeft p {
	color: #2D2C46;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	/* 19.6px */
	letter-spacing: 0.2px;
}

.buttonContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 80px;
}

.proposal {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 6px 12px;
	border-radius: 67px;
	border: 1px solid #FF710B;
	margin-right: 20px;

	.titleProposal {
		color: #FF710B;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		/* 16.8px */
		letter-spacing: 0.2px;
	}
}

.contentRight {
	padding: 28px 45px 28px 60px;
	border-radius: 12px;
	background: #FFF;
}

.contentRight h3 {
	color: #2D2C46;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	/* 25.2px */
	letter-spacing: 0.2px;
}

.contentRight p {
	color: #2D2C46;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	/* 19.6px */
	letter-spacing: 0.2px;
}

.contentRight span {
	float: right;
	color: #7F8DEF;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	/* 19.6px */
	letter-spacing: 0.2px;
}

.moreInfo {
    display: flex;
    float: right;
	cursor: pointer;

    p {
		margin-right: 4px;
        color: #7F8DEF;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.2px;
    }
}