@import "styles/vars.scss";

.root,
.input {
	@include flex-align-items-center;
}

.separator {
	margin: 0 20px;
	opacity: 0.5;
}

.firstInput {
	input {
		text-align: right;
	}
}
