.container {
    margin-top: 96px;
}

.container h2 {
    color: #454545;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    /* 121.053% */
}

.grid {
    display: grid;
    grid-template-columns: 73% 27% 1fr 1fr;
    color: #454545;
    background-color: #fff;
    border-radius: 16px;
}

.containerClientBlur {
    background-color: #fff;
    border-radius: 16px;
}

.contentLeft {
    // padding: 0 0 0 45px;
    // width: 820px;
    // overflow: auto;
    // overflow-x: hidden;
    // height: 530px;
    // margin-top: 15px;

    padding: 0 0 0 45px;
    overflow: auto;
    overflow-x: hidden;
    height: 530px;
    margin: 15px 15px 0 0;
}

.contentLeft h3 {
    color: #454545;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    /* 125% */
}

.contentLeft span {
    color: #ADADAD;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.2px;
}

.contentLeft p {
    color: #2D2C46;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.2px;
    // width: 704px;
}

.paragraph span {
    color: #454545;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
}

.paragraph {
    width: 704px;
}

.contentRight {
    // margin-top: 15px;
    // margin-bottom: 15px;
    // background: #F2F5FB;
    // width: 272px;
    // overflow: auto; 
    // overflow-x: hidden; 
    // height: 530px;
    // border-radius: 8px;
    // display: flex;
    // align-items: center;
    // flex-direction: column;

    background: #F2F5FB;
    overflow: auto;
    overflow-x: hidden;
    height: 530px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 15px 15px 15px 0;
}

.article {
    // padding: 12px;
    // background: #fff;
    // border-radius: 8px;
    // margin-top: 12px;
    // margin-bottom: 12px;
    // width: 250px;
    // cursor: pointer;

    padding: 12px;
    background: #fff;
    border-radius: 8px;
    margin: 12px;
    cursor: pointer;
}

.article h3 {
    overflow: hidden;
    color: #2D2C46;
    text-overflow: ellipsis;
    whitespace: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.2px;
}

.article span {
    color: #ADADAD;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.2px;
}