@import "styles/vars.scss";

.arrow {
	path {
		fill: var(--main-blue-secondary);
	}
}

.infoRow {
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 10px 8px;

	align-items: flex-end;
}

.content {
	display: flex;
	column-gap: 8px;
	align-items: center;
}

.user {
	path {
		fill: var(--main-grey-light);
	}
}

.responsible {
	display: flex;
	column-gap: 4px;
	align-items: center;
}

.rotate180 {
	transform: rotate(180deg);
}
