.rowEnd {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;
}

.buttonCreate {
  margin-left: 12px;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.column {
  display: flex;
  flex-flow: column;
  gap: 4px;
  margin-left: 32px;
  margin-bottom: 50px;
  margin-top: 26px;
}

.title {
  color: #2D2C46;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  letter-spacing: 0.2px;
}

.text {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.2px;
}