.content {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 206px;
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: end;
  align-items: center;
  padding-top: 10px;
}

.root {
  display: flex;
  align-items: center;
  flex-flow: row;
  color: #ADB4D6;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.2px;
}

.discountRow {
 display: flex;
  flex-flow: row;
}

.buttonContainer {
  margin-top: 20px;
  padding: 0 24px;
  display: flex;
  justify-content: flex-end;
}

.option {
  font-size: 14px;
}

.title {
  color: #2D2C46;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 138.462% */
  letter-spacing: 0.2px;
}