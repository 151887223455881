@import "styles/vars.scss";

.root {
	display: grid;

	grid-template-columns: 360px;
	row-gap: 8px;
}
.title {
	line-height: 24px;
	font-weight: bold;
	color: var(--main-black);

	max-width: 210px;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.card {
	padding: 4px 21px;

	height: 60px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	background: var(--main-blue-disabled);
	border-radius: 10px;
}

.active:hover {
	filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
	background: #fff;
	cursor: pointer;
}

.phone {
	font-size: 16px;
	font-weight: 400;
	color: var(--main-blue);
}

.arrow {
	margin-right: 9px;
	width: 14px;
	height: 14px;
	path {
		fill: var(--main-grey-inactive);
	}
}

.reverse {
	transform: rotate(180deg);
}

.actions {
	display: flex;
	align-items: center;
}

.name {
	max-width: 180px;
}

.additional {
	margin-top: 10px;
	font-size: 14px;
}
