@import "styles/vars.scss";

.root {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);
}

.textIcon {
	display: flex;
	align-items: center;
	margin-left: -35px;
	svg {
		width: 25px;
		height: 25px;
	}
	span {
		font-weight: 700;
		font-size: 16px;
		margin-left: 10px;
		font-family: $default-font;
	}
}

.date,
.tariff {
	opacity: 0.5;
}

.ok {
	svg path {
		fill: var(--accent-green);
	}
}

.warn {
	svg path {
		fill: var(--accent-orange);
	}
}

.lastDays {
	svg path {
		fill: var(--accent-red);
	}
}

.end {
	svg path {
		fill: #694500;
	}
}

.noAd {
	margin-left: 0;
	span {
		margin-left: 0;
	}
}

.actionsLeft {
	span {
		&:first-child {
			opacity: 0.5;
		}
		&:last-child {
			opacity: 1;
			margin-left: 6px;
		}
	}
}
