.label {
	display: inline-flex;
	align-items: center;

	> :first-child {
		margin-right: 8px;
	}
}

.circle {
	display: inline-flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;
	width: 16px;
	height: 16px;
}
