.filterWrapper {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-right: 20px;
	padding-left: 17px;
	border-right: 1px solid var(--main-blue-inactive);
}

.taskTypeWrapper {
	width: 260px;
}
