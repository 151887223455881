@import "styles/vars.scss";

.main {
	@include flex-align-items-center;

	padding: 12px;

	border-radius: 10px;
}

.form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-left: 28px;

	color: var(--main-black);
}

.medianWrapper {
	width: 75% !important;
}

.formHeader {
	font-size: 18px;
	line-height: 28px;
	font-weight: 500;
}

.formInfo {
	font-family: $ubuntu-font;
	font-size: 14px;
	font-weight: 400;
}

.radio {
	margin-top: 20px;
}

.list {
	margin: 12px 0 0;
	padding: 0;

	li {
		list-style-type: none;
		margin-top: 8px;

		list-style-type: none;
	}
}

.icon {
	padding: 4px 6px 3px;

	cursor: pointer;
	svg path {
		fill: var(--main-blue-secondary);
	}
	&.active {
		svg path {
			fill: var(--main-blue);
		}
	}
}

.wait {
	width: 32px;
	height: 32px;

	svg path:first-child {
		fill: var(--accent-orange);
	}
}

.children {
	margin-top: 10px;
}

.markers {
	ul {
		padding: 0;
		li {
			margin-top: 8px;
			&:first-child {
				margin-top: 0;
			}
		}
	}
}

.success {
	color: var(--main-blue);
}
