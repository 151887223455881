@import "styles/vars.scss";

.root {
	@include flex-align-items-center;
	justify-content: space-between;
	background-color: #fff;
	border-radius: 10px;
	margin-top: 16px;
	padding: 24px;
	&.withClose {
		padding-right: 14px;
	}
	& > div {
		&:first-child {
			color: var(--main-black);
		}
		&:last-child {
			@include flex-align-items-center;
			text-align: right;
		}
	}
}

.close {
	@include flex-align-items-center;
	justify-content: center;
	padding: 10px;
	cursor: pointer;
	svg {
		width: 10px;
		height: 10px;

		transition: 0.2s;
		fill: var(--main-grey-disabled);
	}

	&:hover svg {
		fill: var(--accent-red);
	}
}

.title {
	font-weight: 500;
	color: var(--main-black);
	font-size: 16px;
}

.name {
	font-size: 14px;
	font-weight: 700;
	color: var(--main-black);
	font-family: $default-font;
	opacity: 0.9;
}

.phone {
	font-family: $ubuntu-font;
}

.phone {
	color: var(--main-black);
	opacity: 0.7;
}
