.root {
	padding: 82px 154px 0 53px;
}

.h1 {
	margin-top: 0 !important;
	margin-bottom: 70px !important;
}

.content {
	display: flex;
}
