@import "../../../../../styles/vars.scss";

.root {
	padding-right: 64px;

	@include flex-align-items-center;

	background: url(../../../../../assets/images/like-hand.jpg);
	background-size: cover;
	height: 180px;
	border-radius: 10px;
}

.main {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-left: auto;

	color: var(--main-black);

	& > div {
		&:first-child {
			font-weight: 400;
			font-size: 20px;
			opacity: 0.5;
		}
		&:last-child {
			margin-top: 8px;

			font-size: 52px;
			font-weight: 700;
		}
	}
}

.button {
	margin-left: 144px;
}
