@import "styles/vars.scss";

.comment {
  padding: 8px 16px;
  border: 1px solid var(--main-grey-light);
  border-radius: 10px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: var(--main-grey);
}

.title {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: start;
  align-items: center;
  margin-top: 24px;
  padding-bottom: 8px;
}

.saveText {
  svg {
    position: relative;
    right: -415px;
    top: -25px;
    cursor: pointer;
    transition: 0.1s;
    fill: #336BEB;
  }
}

.saveTextSaving {
  svg {
    transform: rotate(-90deg);
    fill: #BFC4DF!important;
    transition: 0.1s;
  }
}

.tooltipDescription {
  margin: 0 0 0 4px !important;
}

.tooltipCard {
  max-height: 260px;
  max-width: 360px !important;
  min-height: 40px;
  padding: 8px 8px 8px 36px !important;
  left: -70px!important;

  div {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 13px;
    font-family: "Ubuntu", sans-serif;
  }

  svg {
    left: 8px !important;
    top: 8px !important;
  }
}