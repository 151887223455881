@import "styles/vars.scss";

.root {
	@include flex-align-items-center;
}

.stage {
	font-size: 14px;
	font-weight: 400;
	color: var(--main-black);
	opacity: 0.5;
	padding: 0 12px;
	border-left: 5px solid black;
	line-height: 28px;
}

.title {
	div {
		font-size: 18px;
		color: var(--main-black);
	}
}
