.root {
	margin: 0 auto;
	display: flex;
	flex-flow: column;
	justify-content: center;
	height: 100%;
	max-width: 375px;
	transform: translateX(25px);

	h1 {
		margin-bottom: 23px;
		font-weight: bold;
		font-size: 30px;
		line-height: 120%;
		text-align: left;
	}
}

.form {
	max-width: 350px;
}

.companyInfo {
	transform: scale(1.05);
	cursor: pointer;
	fill: var(--main-grey-disabled);
	margin-bottom: -3px;
	margin-left: 7px;

	&:hover {
		fill: var(--main-blue);
	}
}

.button {
	margin-top: 60px;
}
