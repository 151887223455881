@import "styles/vars.scss";

.root {
	display: flex;

	border-radius: 20px;
	background: #fff;

	&.blue {
		background: var(--main-blue-disabled);

		.chat {
			background: #fff;
		}
	}
}

.image {
	width: 400px;

	display: flex;
	align-items: center;
	justify-content: center;

	img {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;

		width: 400px;
		height: 100%;

		display: block;
		object-fit: cover;
	}

	svg {
		width: 120px;
		height: 120px;
	}

	path {
		fill: var(--main-blue-secondary);
	}
}

.body {
	padding: 35px 40px 32px 60px;
	align-self: center;

	width: 100%;
}

.header {
	@include flex-align-items-center;
	justify-content: space-between;
}

.company {
	color: rgba(var(--main-black-rgb), 0.5);
	font-weight: 400;
	font-size: 18px;
}

.rating {
	@include flex-align-items-center;

	color: var(--main-black);
	& > div {
		&:first-child {
			margin-right: 8px;
			font-weight: 700;
			font-size: 20px;
		}
	}
}

.fullName {
	margin-top: 4px;

	max-width: 242px;
	color: var(--main-black);
	font-weight: 700;
}

.lastName {
	font-size: 36px;
}

.restName {
	margin-top: 4px;
	font-size: 28px;
}

.phone {
	margin-top: 12px;

	font-size: 16px;
	font-weight: 400;
	font-family: $ubuntu-font;
	color: var(--main-grey-inactive);
}

.socials {
	@include flex-align-items-center;

	margin-top: 20px;
}

.link {
	margin-right: 12px;

	cursor: pointer;
	svg {
		width: 30px;
		height: 30px;
		fill: var(--main-blue-secondary);
		path {
			fill: var(--main-blue-secondary);
		}
	}
}

.footer {
	margin-top: 76px;

	@include flex-align-items-center;
	justify-content: space-between;
}

.share {
	@include flex-align-items-center;

	font-weight: 500;
	font-family: $ubuntu-font;
	color: var(--main-blue-secondary);
	cursor: pointer;
	span {
		margin-right: 12px;
	}

	svg {
		path {
			fill: var(--main-blue-secondary);
		}
	}
}

.root.medium {
	.image {
		img {
			width: 200px;
		}
	}

	.body {
		padding: 12px 16px 8px 24px;
	}

	.company {
		font-size: 14px;
	}

	.lastName {
		font-size: 20px;
	}

	.restName {
		margin-top: 0;

		font-size: 16px;
	}

	.footer {
		margin-top: 16px;
	}

	.rating {
		& > div {
			&:first-child {
				font-size: 16px;
			}
		}
	}

	.share {
		span {
			font-size: 14px;
		}

		svg {
			width: 20px;
			height: 20px;
		}
	}

	.link {
		svg {
			width: 28px;
			height: 28px;
		}
	}

	.phone {
		margin-top: 4px;
	}

	.socials {
		margin-top: 8px;
	}
}
