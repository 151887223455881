.root {
	padding: 24px 28px 20px;

	display: flex;
}

.checkbox {
	color: #fff;
}

.loaderContainer {
	margin-left: 24px;

	width: 16px;
	height: 16px;

	svg {
		width: 16px;
		height: 16px;
	}
}
