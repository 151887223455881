.unFixedDoubleInput {
	max-width: 170px;
}

.withSelect {
	margin-left: 10px;
}

.rangeContent {
	display: flex;
	align-items: center;
	max-width: 200px;
}

.unit {
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: var(--main-black);
	white-space: nowrap;
}

.unitSelect {
	width: auto;
}

.wrapper {
	max-width: 200px;
}
