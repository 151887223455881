.root {
  padding: 16px 40px;
}

.title {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 12px;
  display: flex;
  gap: 4px;
}

.count {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #BFBFC0;
}

.elements {
  height: 24px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  :last-child {
    border: none !important;
  }

  :first-child {
    width: 250px !important;
  }
}

.historyHeader {
  width: 680px;
  border-bottom: 5px solid #336BEB;
  margin-top: 20px;
}

.history {
  background: #F2F2F2;
  width: 720px;
  height: 300px;
  border-radius: 8px;
  padding: 20px;
}

.historyContent {
  height: 250px;
  display: flex;
  flex-flow: column;
  gap: 20px;
  overflow: auto;
  margin-top: 20px;
}

.historyItem {
  display: flex;
  justify-content: space-between;
  font-family: Cera Pro;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;

  div {
    width: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
  }

  :first-child {
    width: 240px !important;
    text-align: left;
  }
}

.historyItemDate {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #8B8B8D;
}

.item {
  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);
  border-radius: 12px;
}

.element {
  width: 140px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
  border-right: 1px solid #CCCCCC;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col {
  display: flex;
  flex-flow: column;
  gap: 12px;
}