@import "styles/vars.scss";

.root {
	padding: 0 28px;

	max-width: 516px;
}

.voterBlock {
	margin-bottom: 20px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.voterBlockVoted {
	justify-content: space-between;
}

.viewRequest {
	display: flex;
	align-items: center;

	font-size: 16px;
	line-height: 24px;
	color: var(--main-black);

	svg {
		width: 20px;
		height: 20px;
		margin-right: 12px;
	}

	path {
		fill: var(--main-blue-secondary);
	}
}

.viewRequestAnswered {
	color: var(--main-blue);

	path {
		fill: var(--accent-green);
	}
}

.voteBtnContainer {
	margin-left: 64px;

	width: 190px;

	display: flex;
	justify-content: space-between;
}

.voteBtnContainerVoted {
	width: 50px;
}

.voteBtn {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 50px;
	height: 50px;

	border: 2px solid var(--main-blue-secondary);
	border-radius: 100px;

	cursor: pointer;
}

.voteBtnHide {
	display: none;
}

.thumbUp {
	svg {
		transform: translateY(-1px);
	}

	&:not(.thumbUp__active):hover {
		border: 2px solid var(--accent-green);

		path {
			fill: var(--accent-green);
		}
	}

	&__active {
		background: var(--accent-green);
		border: 2px solid var(--accent-green);

		path {
			fill: #fff;
		}
	}
}
.thumbDown {
	svg {
		transform: translateY(1px) rotate(180deg);
	}

	&:not(.thumbDown__active):hover {
		border: 2px solid var(--accent-red);

		path {
			fill: var(--accent-red);
		}
	}

	&__active {
		background: var(--accent-red);
		border: 2px solid var(--accent-red);

		path {
			fill: #fff;
		}
	}
}

.question {
	&:not(.question__active):hover {
		border: 2px solid var(--accent-orange);

		path {
			fill: var(--accent-orange);
		}
	}

	&__active {
		background: var(--accent-orange);
		border: 2px solid var(--accent-orange);

		path {
			fill: #fff;
		}
	}
}

.comment {
	padding: 8px 12px;
	margin: 0 -8px;

	border: 1px solid transparent;

	cursor: pointer;

	h4 {
		margin: 0 0 4px;

		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: var(--main-black);
	}

	p {
		margin: 0;

		font-family: $ubuntu-font;
		font-weight: 300;
		font-size: 14px;
		line-height: 20px;
		color: var(--main-black);
	}

	&:hover {
		padding: 8px 12px;
		border: 1px solid var(--main-blue);
		border-radius: 10px;
	}
}

.editButtonContainer,
.submitContainer {
	margin-top: 20px;

	display: flex;
	justify-content: flex-end;
}

.submitContainer button {
	min-width: 228px !important;
}
