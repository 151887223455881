@import "styles/vars.scss";

.root {
	height: 100%;
	overflow: auto;
	background: var(--BW-5, #FFF);
}

.block {
	padding: 12px 24px;
	box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);
	background-color: #fff;
	border-radius: 10px;

	cursor: pointer;
}

.title {
	padding: 18px 24px 0;

	@include flex-align-items-center;

	font-size: 18px;
	line-height: 24px;
	color: var(--main-black);
	font-weight: 500;

	span {
		margin-left: 4px;
		font-weight: 400;
		opacity: 0.5;
	}
}

.body {
	padding: 20px 24px;

	& > div {
		margin-bottom: 12px;
	}
}

.address {
	color: var(--main-black);
	font-weight: 500;
	font-size: 14px;
}

.userInfo {
	color: var(--main-blue);
	font-weight: 400;
	font-size: 14px;
}

.name {
	padding-left: 10px;
}

.icons {
	padding: 0;
	margin: 14px 0 0;

	@include flex-align-items-center;
}

.badge {
	&.newMessage {
		left: 100%;
		top: -12px;
	}

	&.poop {
		top: -4px;
		right: -13px;
		left: auto;
	}

	&.megaphone {
		top: -3px;
		left: calc(100% - 5px);
	}
}

.iconWrapper {
	margin-right: 15px;

	list-style-type: none;

	&:nth-child(2) {
		margin-right: 6px;
	}

	&:hover {
		.icon {
			svg {
				path {
					transition: $default-transition-duration;
					fill: #68687c;
				}
			}
		}
	}
}

.icon {
	cursor: pointer;

	svg {
		fill: var(--main-blue-secondary);
		path {
			fill: var(--main-blue-secondary);
		}
	}
}

.tooltip {
	margin: 0 !important;
}

.card {
	padding: 12px 26px;

	top: unset !important;
	bottom: calc(100% + 16px);

	& > div {
		&:first-child {
			display: none;
		}
	}
}

.errorTitle {
	margin-bottom: 4px;

	font-size: 14px;
	color: white;
	font-weight: 700;
}

.errorList {
	margin: 0;
	padding: 0;

	li {
		margin-top: 4px;

		font-weight: 400;
		font-size: 14px;
		font-family: $ubuntu-font;
		list-style-type: none;
		opacity: 0.7;
	}
}

.noAlertApplications {
	padding: 20px 24px;

	font-family: $ubuntu-font;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-grey-disabled);
}
