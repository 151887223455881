.root {
	display: flex;
	flex-direction: column;

	padding: 12px 20px;

	background-color: #fff;
	border-radius: 10px;
	& > * {
		margin-top: 4px;
		&:first-child {
			margin-top: 0;
		}
	}
}
