.infoRow {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;

  align-items: flex-start;
}

.row {
  display: flex;
  flex-flow: row;
  gap: 24px;
  margin-top: 26px;
  margin-bottom: 32px;
}

.column {
  display: flex;
  flex-flow: column;
  min-width: 440px;
}

.button {
  gap: 20px;
  background: white;
  color: var(--BW-0, #1E1E1F);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.2px;

  label {
    .unchecked {
      background: white;
    }
  }
}

.noPreAppLabel {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}

.preAppLabel {
  color: #9595A3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}

.card {
  background: white!important;
}

.error {
  color: #FF575F;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
}