@import "styles/vars.scss";

.root {
	padding: 18px 20px;
	overflow: hidden;
}

.base {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.avatarContainer {
	cursor: pointer;

	&:hover .notifications {
		opacity: 1;
	}
}

.info {
	margin-left: 8px;
}

.company,
.name {
	font-weight: bold;
	font-size: 14px;
	line-height: 17px;
	color: black;
	font-family: $default-font;

	white-space: nowrap;

	transition: 0.2s;
}

.company {
	opacity: 0.5;
}
