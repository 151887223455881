.custom-balloon {
	[class*="balloon_layout_normal"] {
		padding: 0;
		background-color: transparent;
		box-shadow: none;
	}

	[class*="balloon__layout"] {
		overflow: visible !important;
		background: transparent;

		[class*="balloon__close"] {
			float: none;
		}

		[class*="balloon__close-button"] {
			position: absolute;
			top: 15px;
			left: -20px;
			width: 40px;
			height: 40px;
			background: var(--main-black);
			background-image: url("../../../../../../assets/icons/cancel.svg");
			background-repeat: no-repeat;
			background-size: contain;
			border-radius: 50%;
			opacity: 1;
		}

		[class*="balloon__content"] {
			margin-right: 0;
			padding: 0;
			font: inherit;
			background: transparent;

			& > ymaps {
				width: auto !important;
				height: auto !important;
				overflow: visible !important;
			}
		}
	}

	[class*="balloon__tail"] {
		display: none;
	}
}
