.dateWrapper {
	display: flex;
}

.dateIcon {
	margin-right: 15px;
	margin-top: 2px;

	path {
		fill: var(--main-blue-secondary);
	}
}
