.root {
	margin: 80px 0;
}

.collapseLabel {
	display: flex;
	align-items: center;

	font-weight: bold;
	font-size: 26px;
	line-height: 36px;

	svg {
		margin-right: 8px;
	}

	path {
		fill: var(--accent-green);
	}
}
