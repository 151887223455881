.block {
	margin-top: 16px;
	& > div {
		padding: 24px;
	}
}

.dateAndTime {
	max-width: 194px;
}

.lastBlock {
	max-width: 310px;
}
