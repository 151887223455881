@import "styles/vars.scss";

.root {
	position: relative;
}

.input {
	padding: 4px 0;

	font-size: 14px;
	line-height: 20px;
	font-family: $default-font;
	font-weight: 500;

	background: transparent;
	border: none;

	width: 100%;

	&::placeholder {
		font-size: 14px;
		line-height: 20px;
		color: var(--main-blue-secondary);
	}

	&:disabled {
		cursor: not-allowed;
	}
}

.withClose {
	padding: 4px 26px 4px 0;
}

.close {
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);

	padding: 4px;
	cursor: pointer;

	svg {
		transition: 0.2s;
		width: 10px;
		height: 10px;
		fill: var(--main-grey-light);
	}

	&:hover {
		svg {
			fill: var(--accent-red);
		}
	}
}

.loading {
	width: 100%;
	height: 28px;

	background: var(--main-blue-inactive);
}

.error {
	margin-top: 8px;
	color: var(--accent-red);
	font-size: 12px;
}

.right {
	text-align: right;
}

.noPadding {
	input {
		padding-top: 0;
		padding-bottom: 0;
	}
}
