.container {
	width: 100%;

	display: flex;
	flex-direction: row;
}

.contentContainer {
	width: 100%;
	position: relative;
}

.content {
	padding: 30px 40px;
	width: 100%;
}
